import React from 'react';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import DialogContentText from '@mui/material/DialogContentText';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import CheckIcon from '@mui/icons-material/Check';
import { Box } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import CloseIcon from '@mui/icons-material/Close';

const StoreModal = ({ onCancel }) => {
  const [open, setOpen] = React.useState(true); // Set initial state to true to keep it open
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const isDarkMode = theme.palette.mode === 'dark';
  const location = useLocation();
  const navigate = useNavigate();

  const handleRemoveStatus = () => {
    navigate(location.pathname, { replace: true });
  };

  const handleClose = () => {
    setOpen(false);
    handleRemoveStatus();
    if (onCancel) {
      onCancel();
    }
  };

  return (
    <React.Fragment>
      <style>
        {`.css-przs3r-MuiPaper-root-MuiDialog-paper {
                    border-radius: 15px
                    }`}
        {`.css-rsdpij-MuiPaper-root-MuiDialog-paper {border-radius: 15px}`}
      </style>
      <Dialog className="new" open={open} onClose={handleClose} aria-labelledby="responsive-dialog-title" sx={{ width: '90%' }}>
        <Box className="d-flex  m-2 pt-2" sx={{ justifyContent: 'space-between' }}>
          <div></div>
          <DialogTitle
            sx={{
              paddingBottom: '0px',
              paddingTop: '0px',
            }}
          >
            Hey there!
          </DialogTitle>
          <CloseIcon onClick={handleClose} style={{ cursor: 'pointer' }} />
        </Box>
        <DialogContent sx={{ textAlign: '-webkit-center' }}>
          <DialogContentText sx={{ padding: '7px 24px' }}>
            Announce your arrival by downloading the ReachAMY mobile app from the App Store or Google Play Store
          </DialogContentText>
        </DialogContent>
        <DialogActions className="justify-content-center" sx={{ pb: 2 }}>
          <a href="https://apps.apple.com/us/app/reachamy/id6737806860" target="_blank" rel="noopener noreferrer" style={{ textAlign: 'right' }}>
            <img src="content/images/app-apple-store.webp" alt="ad1" width="65%" />
          </a>
          <a href="https://play.google.com/store/apps/details?id=com.reachamyofficial.app" target="_blank" rel="noopener noreferrer">
            <img src="content/images/app-google-play.webp" alt="ad2" width="65%" />
          </a>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};

export default StoreModal;
