import { AddCircleOutline } from '@mui/icons-material';
import {
  Autocomplete,
  Box,
  Chip,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  OutlinedInput,
  TextField,
  Typography,
} from '@mui/material';
import React, { useState } from 'react';
import toast from 'react-hot-toast';
import countryCode from '../../../../../../utils/data/json/country-code.json';

const linkRegex = /^https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/;
interface CountryCode {
  name: string;
  web_code: string;
  country_code: string;
  flag: string;
  phone_code: string;
  phone_number_length: number;
}
type Props = {
  phoneNumber: any;
  phoneCode: any;
  handleDetailsChange: (fieldName: any, newValue: any) => void;
  setPhoneParentError: (error: string | null) => void;
  hasPermission: boolean;
};
const SettingsOrgMobile = ({ phoneNumber, phoneCode, handleDetailsChange, hasPermission,setPhoneParentError }: Props) => {
  const countryCodes = countryCode as CountryCode[];
  const [phoneError, setPhoneError] = useState<string | null>(null);
  const validatePhoneNumber = (phone: string, code: string) => {
    const selectedCountry = countryCodes.find(cc => cc.phone_code === code);
    if (!selectedCountry) return true;

    const allowedLengths = Array.isArray(selectedCountry.phone_number_length)
    ? selectedCountry.phone_number_length
    : [selectedCountry.phone_number_length];

  if (!allowedLengths.includes(phone.length)) {
    setPhoneError(`Phone number must be valid.`);
    setPhoneParentError('Phone number must be valid.')
    return false;
  }
  setPhoneError(null);
  setPhoneParentError(null)
  return true;
  };
  return (
    <Grid container spacing={2} sx={{ alignItems: 'center' }}>
      <style>
        {`.css-1h51icj-MuiAutocomplete-root .MuiOutlinedInput-root.MuiInputBase-sizeSmall .MuiAutocomplete-input {
                      padding: 5.5px 14px !important;
                      font-size: 0.75rem !important;
          }`}
      </style>
      <Grid item xs={12} md={4}>
        <Typography variant="h5" sx={{ fontSize: '14px' }}>
          Phone number
        </Typography>
      </Grid>
      <Grid item xs={12} md={8}>
        <div className="row mt-1 mb-2">
          <div className="col-5 ">
            <Autocomplete
              disabled={!hasPermission}
              options={countryCodes}
              getOptionLabel={option => `${option.phone_code}`}
              filterOptions={(options, { inputValue }) => {
                // Use inputValue to filter the options and get the exact match
                return options.filter(option => option.phone_code.toLowerCase().includes(inputValue.toLowerCase()));
              }}
              value={countryCodes.find(option => option.phone_code === phoneCode) || null}
              onChange={(e, option) =>{
                 handleDetailsChange('phoneCode', option?.phone_code || '')
                if (!option) {
                  setPhoneError(null);
                  setPhoneParentError(null);
                }
              }}
              renderInput={params => <TextField name={`countryCode`} {...params} label="" size="small" placeholder="Country code" />}
              renderOption={(props, option) => (
                 <Box component="li"sx={{ '& > img': { mr: 1, flexShrink: 0 } }} {...props}>
                          {option.phone_code} {option.name}
                  </Box>
              )}
            />
          </div>
          <div className="col-7">
            <TextField
              disabled={!hasPermission}
              sx={{ height: '40px' }}
              className="w-100"
              name="phoneNumber"
              value={phoneNumber}
              onChange={e => {
                let numericValue = e.target.value.replace(/\D/g, '');
                if (numericValue.startsWith('-')) {
                  numericValue = numericValue.slice(1);
                }

                handleDetailsChange('phoneNumber', numericValue);
                validatePhoneNumber(numericValue, phoneCode);
              }}
              error={!!phoneError} 
              helperText={phoneError} 
              size="small"
              placeholder="Phone number"
              inputProps={{
                style: {
                  fontSize: 12,
                },
              }}
              type="text"
            />
          </div>
        </div>
      </Grid>
    </Grid>
  );
};

export default SettingsOrgMobile;
