import React, { useEffect, useState } from 'react';
import { Button, Grid, Typography, useTheme } from '@mui/material';
import SettingsOrgname from './settings-org-name';
import SettingsOrgbio from './settings-org-bio';
import SettingsOrgaddress from './settings-org-address';
import SettingsOrgdoc from './settings-org-doc';
import SettingsOrgwebsite from './settings-org-website';
import SettingsOrgsocial from './settings-org-social';
import SettingsOrgfunding from './settings-org-funding';
import SettingsOrgrightcard from './settings-org-right/settings-org-rightcard';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { useLocation, useNavigate } from 'react-router-dom';
import { deleteOrganization, setSelectedOrgId, updateOrganization } from 'app/shared/reducers/settings/settings-organization';
import { produce } from 'immer';
import { fetchOrganization, fetchUserGrantedOrganizations } from 'app/shared/reducers/organization';
import { apiEndPoints } from 'app/utils/data/constants/api-end-points';
import axios from 'axios';
import SettingsOrgSelectedName from './settings-org-right/settings-ord-selected-name';
import AlertDialog from 'app/components/common/alert-dialog/alert-dialog';
import toast from 'react-hot-toast';
import SettingsOrgMobile from './settings-org-mobile';
import _ from 'lodash';

export const trimLeadingSpaces = value => {
  return value.replace(/^\s+/, '');
};

const SettingsOrganisation = () => {
  const [alertOpen, setAlertOpen] = useState(false);
  const [selectedImageFile, setSelectedImageFile] = useState(null);
  const [docIds, setDocIds] = useState([]);
  const [showSocialError, setShowSocialError] = useState<boolean>(false);
  const [showWebError, setShowWebError] = useState<boolean>(false);
  const [isWebLinkValid, setIsWebLinkValid] = useState<boolean>(true);
  const [phoneError, setPhoneParentError] = useState<string | null>(null);

  const { userOwnedOrganization } = useAppSelector(state => state.organizationReducer);

  const [details, setDetails] = useState<any>({
    organizationName: '',
    bio: '',
    logo: null,
    address: '',
    website: '',
    socialLinks: [],
    documents: [],
    fundingLinks: '',
    countryCode: '',
    phoneNumber: '',
    phoneCode: '',
  });
  const [initialDetails, setInitialDetails] = useState(null);
  const [isUpdateEnabled, setIsUpdateEnabled] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();
  const { organizationId } = location.state || {};
  const dispatch = useAppDispatch();

  const organisationData = useAppSelector(state => state.organizationReducer.organization);
  const { selectedOrgId, updateOrganizationLoading, deleteOrganizationLoading, updateOrganizationSuccess } = useAppSelector(
    state => state.settingsOrganization
  );

  const storedOrgId = parseInt(localStorage.getItem('organizationId'));

  const handleDelete = async () => {
    const actionResult = await dispatch(deleteOrganization({ orgId: selectedOrgId }));

    if (deleteOrganization.fulfilled.match(actionResult)) {
      dispatch(setSelectedOrgId(null));
      localStorage.removeItem('organizationId');
      navigate(`/profile`, { state: { isBackHome: true } });
      location.state = { ...location.state, organizationId: null };
      setDetails({
        organizationName: '',
        bio: '',
        logo: null,
        address: '',
        documents: [],
        website: '',
        socialLinks: [],
        fundingLinks: '',
        countryCode: '',
        phoneNumber: '',
        phoneCode: '',
      });
      dispatch(fetchUserGrantedOrganizations());
    }
    setAlertOpen(false);
  };

  useEffect(() => {
    if (storedOrgId) {
      dispatch(setSelectedOrgId(storedOrgId));
    } else if (organizationId) {
      dispatch(setSelectedOrgId(organizationId));
    }
  }, [dispatch, organizationId, storedOrgId]);

  useEffect(() => {
    const fetchOrganizationData = async id => {
      try {
        await dispatch(fetchOrganization(id));
      } catch (error) {
        console.log(error);
      }
    };

    if (selectedOrgId) {
      fetchOrganizationData(selectedOrgId);
    }
  }, [selectedOrgId, storedOrgId]);

  useEffect(() => {
    if (organisationData) {
      const { organizationName, bio, logoUrl, address, website, socialLinks, fundingLinks, phoneCode, phoneNumber } = organisationData;
      const initialData = {
        organizationName,
        logo: logoUrl ? logoUrl : '',
        bio,
        address,
        website,
        socialLinks,
        fundingLinks,
        phoneNumber,
        phoneCode,
      };
      setDetails(initialData);
      setInitialDetails(initialData);
    }
  }, [organisationData]);

  useEffect(() => {
    if (initialDetails) {
      const hasChanges = Object.keys(details).some(key => details[key] !== initialDetails[key]);
      setIsUpdateEnabled(hasChanges);
    }
  }, [details, initialDetails]);

  const handleUpdateOrganization = details => {
    dispatch(
      updateOrganization({
        orgId: selectedOrgId,
        updatedData: details,
      })
    );
  };
  const [checkName, setName] = useState(true);
  const [checkBio, setBio] = useState(true);
  const handleDetailsChange = (fieldName, newValue) => {
    let trimmedValue;
    if (fieldName === 'socialLinks') {
      trimmedValue = newValue;
    } else {
      trimmedValue = trimLeadingSpaces(newValue);
    }
    if (fieldName === 'organizationName') {
      if (trimmedValue.length < 2 || trimmedValue.length > 50) {
        setName(false);
        console.error('Organization name must be between 2 and 50 characters.');
      } else {
        setName(true);
      }
    }
    if (fieldName === 'bio') {
      if (trimmedValue.length < 2 || trimmedValue.length > 200) {
        setBio(false);
        console.error('Bio must be between 2 and 200 characters.');
      } else {
        setBio(true);
      }
    }
    setDetails(
      produce(details, draft => {
        draft[fieldName] = trimmedValue;
      })
    );
  };

  const updateButtonClick = imageFiles => {
    const trimmedOrganizationName = details?.organizationName?.trim();
    const trimmedBio = details?.bio?.trim();
    if (details?.phoneCode && !details?.phoneNumber) {
      toast.error('You cannot update without a phone number or remove the country code');
      return;
    }

    if (!trimmedOrganizationName) {
      toast.error('Organization name cannot be empty or contain only spaces');
      return;
    }

    if (!trimmedBio) {
      toast.error('Bio cannot be empty or contain only spaces');
      return;
    }

    if (imageFiles && imageFiles.length > 0) {
      sendFile(imageFiles);
    } else {
      handleUpdateOrganization({ ...details, documents: docIds, logo: 0 });
      setIsUpdateEnabled(false);
    }
  };

  const sendFile = async (files: FileList) => {
    try {
      let endpoint = '';
      const formData = new FormData();

      endpoint = apiEndPoints.imageUpload;

      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        formData.append('file', file);
      }

      const response = await axios.post<any>(endpoint, formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });
      if (response.status === 201) {
        handleUpdateOrganization({ ...details, documents: docIds, logo: response.data?.id });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const updateDocIds = newDocIds => {
    setDocIds(newDocIds);
  };
  const theme = useTheme();

  const renderOrgSelecetionBox = () => {
    if (organisationData) {
      if (organisationData?.hasPermission) {
        return (
          <Grid item xs={12}>
            <SettingsOrgname />
          </Grid>
        );
      }
    } else {
      return (
        <Grid item xs={12}>
          <SettingsOrgname />
        </Grid>
      );
    }
  };
  return (
    <>
      <AlertDialog
        agreeAction={handleDelete}
        title="Are you sure?"
        description="Deleting the organization is irreversible and cannot be undone. Please confirm your decision, as this action is permanent and
            irreversible."
        open={alertOpen}
        setAlertOpen={setAlertOpen}
        closeButtonText="Disagree"
      />

      <Grid container spacing={2}>
        {userOwnedOrganization?.length > 0 ? (
          <>
            <Grid item xs={12} md={6}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <SettingsOrgname />
                </Grid>
                <Grid item xs={12}>
                  <SettingsOrgSelectedName
                    hasPermission={organisationData?.hasPermission}
                    organizationName={details?.organizationName}
                    handleDetailsChange={handleDetailsChange}
                    check={checkName}
                  />
                </Grid>
                <Grid item xs={12}>
                  <SettingsOrgbio
                    hasPermission={organisationData?.hasPermission}
                    sendFile={sendFile}
                    setSelectedImageFile={setSelectedImageFile}
                    bio={details?.bio}
                    logo={details?.logo}
                    handleDetailsChange={handleDetailsChange}
                    setIsUpdateEnabled={setIsUpdateEnabled}
                    checkStatus={checkBio}
                  />
                </Grid>
                <Grid item xs={12}>
                  <SettingsOrgaddress
                    hasPermission={organisationData?.hasPermission}
                    address={details?.address}
                    handleDetailsChange={handleDetailsChange}
                  />
                </Grid>
                <Grid item xs={12}>
                  <SettingsOrgdoc
                    hasPermission={organisationData?.hasPermission}
                    documents={organisationData?.documents}
                    handleDetailsChange={handleDetailsChange}
                    updateDocIds={updateDocIds}
                    setIsUpdateEnabled={setIsUpdateEnabled}
                  />
                </Grid>
                <Grid item xs={12}>
                  <SettingsOrgwebsite
                    hasPermission={organisationData?.hasPermission}
                    website={details?.website}
                    handleDetailsChange={handleDetailsChange}
                    setShowWebError={setShowWebError}
                    showWebError={showWebError}
                    isWebLinkValid={isWebLinkValid}
                    setIsWebLinkValid={setIsWebLinkValid}
                  />
                </Grid>
                <Grid item xs={12}>
                  <SettingsOrgsocial
                    hasPermission={organisationData?.hasPermission}
                    socialLinks={details?.socialLinks}
                    handleDetailsChange={handleDetailsChange}
                    showSocialError={showSocialError}
                    setShowSocialError={setShowSocialError}
                  />
                </Grid>
                <Grid item xs={12}>
                  <SettingsOrgMobile
                    hasPermission={organisationData?.hasPermission}
                    phoneNumber={details?.phoneNumber}
                    phoneCode={details?.phoneCode}
                    handleDetailsChange={handleDetailsChange}
                    setPhoneParentError={setPhoneParentError}
                  />
                </Grid>
                {/* <Grid item xs={12}>
              <SettingsOrgfunding fundingLinks={details?.fundingLinks} handleDetailsChange={handleDetailsChange} />
            </Grid> */}
                {selectedOrgId && organisationData?.hasPermission && (
                  <Grid item xs={12} textAlign={'end'}>
                    <Button
                      className="org-invitation-btn"
                      disableElevation
                      disableRipple
                      size="small"
                      onClick={() => setAlertOpen(true)}
                      sx={{
                        width: 'fit-content',
                        color: 'primary.light',
                        borderRadius: 1.875,
                        border: '1px solid #674BFF',
                        '&.MuiButtonBase-root:hover': {
                          bgcolor: 'transparent',
                          border: '1px solid #674BFF  ',
                        },
                      }}
                      variant="outlined"
                      disabled={deleteOrganizationLoading}
                    >
                      Remove Organization
                    </Button>

                    <Button
                      variant="text"
                      disableRipple
                      sx={{
                        borderRadius: 1.875,
                        py: 0.66,
                        px: 3.25,
                        bgcolor: 'primary.light',
                        color: '#F6F7F8',
                        textTransform: 'none',
                        marginLeft: 1,
                        gap: 1,
                        '&.MuiButtonBase-root:hover': {
                          bgcolor: 'primary.light',
                        },
                        '&.MuiButton-root.Mui-disabled': {
                          bgcolor: '#BFBFBF',
                        },
                      }}
                      onClick={() => updateButtonClick(selectedImageFile)}
                      disabled={
                        !checkBio ||
                        !checkName ||
                        !isWebLinkValid ||
                        showSocialError ||
                        showWebError ||
                        updateOrganizationLoading ||
                        !isUpdateEnabled ||
                        (!!details?.phoneCode && !details?.phoneNumber) || 
                        (!!details?.phoneNumber && !details?.phoneCode)
                        || !!phoneError
                      }
                    >
                      <Typography
                        sx={{
                          fontWeight: theme.infoText.regular.fontWeight,
                          fontSize: theme.infoText.regular.fontSize,
                          fontStyle: theme.infoText.regular.fontStyle,
                        }}
                      >
                        Update
                      </Typography>
                    </Button>
                  </Grid>
                )}
              </Grid>
            </Grid>
            <Grid item xs={12} md={6}>
              <SettingsOrgrightcard hasPermission={organisationData?.hasPermission} />
            </Grid>
          </>
        ) : (
          <Grid item>
            <Typography>You have no organizations</Typography>
          </Grid>
        )}
      </Grid>
    </>
  );
};

export default SettingsOrganisation;
