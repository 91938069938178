import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Box, Dialog, Typography, useMediaQuery, useTheme } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import { Button, Checkbox, FormControlLabel, OutlinedInput, Stack } from '@mui/material';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import { useAppSelector, useAppDispatch } from 'app/config/store';
import { fetchCategories } from 'app/shared/reducers/categories';
import { useNavigate, useParams } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import { Carousel } from 'react-responsive-carousel';
import { singleImageType } from 'app/shared/reducers/image-source';
import DeleteIcon from '@mui/icons-material/Delete';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import { useDropzone } from 'react-dropzone';
import axios from 'axios';
import { apiEndPoints } from 'app/utils/data/constants/api-end-points';
import MapBoxMapView from 'app/components/common/map-box/map-view-mapbox';
import { apiKeys } from 'app/utils/data/constants/api-keys';
import { StyledTextarea } from 'app/components/common/text-area/styled-textare';
import { sideBaricons } from 'app/utils/data/side-bar-nav/side-nav-icon-list';
import imageCompression from 'browser-image-compression';

const EditPetitionMain = ({ formik, petition }: any) => {
  const theme = useTheme();
  const { id } = useParams();
  const isDarkMode = useAppSelector(state => state.darkMode);
  const [localMultimedia, setLocalMultimedia] = useState<singleImageType[]>([]);
  const navigate = useNavigate();
  const [categories, setCategories] = useState<any>([]);
  const [files, setFiles] = useState<File[]>();
  const [multiMedia, setMultiMedia] = useState<{ id: number }[]>([]);
  const [formikMultimedia, setFormikMultimedia] = useState([]);
  const [formikPetition, setFormikPetition] = useState([]);
  const [totalSize, setTotalSize] = useState(0);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [totalImages, setTotalImages] = useState(0);
  const [hasFormErrors, setHasFormErrors] = useState(false);
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [selectedPostAs, setSelectedPostAs] = useState('Myself');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [postAsSelectedValue, setPostAsSelectedValue] = useState('');

  const [selectedLocation, setSelectedLocation] = useState<{ lat: number; lng: number }>(null);
  const [locationFomSearch, setLocationFromSearch] = useState<string>('');
  const userGrantedOrganizations = useAppSelector(state => state.organizationReducer.userGrantedOrganizations);
  console.log("formik.errors",formik.errors)
  const acceptingFiles = {
    'image/jpeg': [],
    'image/png': [],
    'image/jpg': [],
  };

  const onDrop = useCallback(
    async (accFiles: File[], FileRejection: any) => {
      const acceptedFiles = accFiles.map(file => ({ file, errors: [] }));
      if (totalImages + acceptedFiles.length <= 10) {
        let valid = true;
  
        const compressedFiles: File[] = [];
        for (const entry of acceptedFiles) {
          if (entry.file.size > 5 * 1024 * 1024) {
            valid = false;
            setErrorMessage('File size must be less than 5MB.');
            break;
          }
          const originalSize = entry.file.size;
          const originalFileType = entry.file.type;
          try {
            const originalSizeInMB = originalSize / 1024 / 1024;
            if(originalSizeInMB > 3){
              const compressedBlob = await imageCompression(entry.file, {
                maxSizeMB: 1, 
                maxWidthOrHeight: 800, 
                useWebWorker: true, 
                fileType: originalFileType,
              });
              const compressedFile = new File([compressedBlob], entry.file.name, { type: originalFileType });
            const compressedSize = compressedFile.size; 
              compressedFiles.push(compressedFile);
            }
            else {
              compressedFiles.push(entry.file);
            }
          
          } catch (err) {
            valid = false;
            setErrorMessage(`Failed to compress image '${entry.file.name}'.`);
            break;
          }
        }
  
        if (valid) {
          setErrorMessage('');
          setFiles(compressedFiles);
          setTotalImages(totalImages + compressedFiles.length);
          setTotalSize(existingSize =>
            compressedFiles.reduce((acc, file) => acc + file.size, existingSize)
          );
        } else {
          setErrorMessage('Some files failed to meet requirements.');
        }
      } else {
        setErrorMessage('You can upload a maximum of 10 images.');
      }
    },
    [totalImages]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: acceptingFiles,
    multiple: true,
  });

  const handleCloseModal = () => {
    setIsModalOpen(false);
    if (formik.values.latitude === '' || formik.values.longitude === '') {
      formik.setFieldValue('latitude', 12.97912);
      formik.setFieldValue('longitude', 77.5913);
    }
  };

  const handleSelectLocation = location => {
    const { lat, lng } = location;

    formik.setFieldValue('latitude', lat);
    formik.setFieldValue('longitude', lng);
    setSelectedLocation(location);
  };

  const handleLocationFromSearchBox = (location: string) => {
    setLocationFromSearch(location);
    formik.setFieldValue('place', location);
  };

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCancelButtonClick = () => {
    navigate('/');
  };

  const dispatch = useAppDispatch();
  const [currentImageId, setCurrentImageId] = useState<number | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await dispatch(fetchCategories());
        setCategories(response.payload);
      } catch (error) {
        console.log(error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    if (files !== undefined) {
      const sendFiles = async () => {
        try {
          const updatedFormikMultimedia = [];
          for (const file of files) {
            const formData = new FormData();
            formData.append('file', file);
            const response = await axios.post<any>(apiEndPoints.imageUpload, formData, {
              headers: { 'Content-Type': 'multipart/form-data' },
            });
            if (response.status === 201) {
              setLocalMultimedia(state => [...state, response.data]);
              updatedFormikMultimedia.push({ id: response.data.id });
            }
          }
          setFormikMultimedia(updatedFormikMultimedia);
        } catch (error) {
          console.error(error);
        }
      };
      sendFiles();
    }
  }, [files]);
  

  useEffect(() => {
    if (formik.values.multimedia?.length > 0) {
      setMultiMedia(formik.values?.multimedia);
      setLocalMultimedia(formik.values?.multimedia);
    }
  }, [formik.values.multimedia]);

  useEffect(() => {
    if (formik.values.multimedia?.length <= 0) {
      formik.setFieldError('multimedia', 'Multimedia is required');
    }
  }, [formik.values.multimedia]);

  useEffect(() => {
    formik.setFieldValue('multimedia', localMultimedia);
  }, [localMultimedia]);

  // useEffect(() => {
  //   formik.setFieldValue('multimedia', formikMultimedia);
  // }, [formikMultimedia]);
  // useEffect(() => {
  //   if (formik.values.startDate) {
  //     formik.setFieldValue('startDate', dayjs(formik.values.startDate));
  //   }
  //   if (formik.values.endDate) {
  //     formik.setFieldValue('endDate', dayjs(formik.values.endDate));
  //   }
  // }, [formik.values.startDate, formik.values.endDate]);
  useEffect(() => {
    setFormikPetition(petition);
    if (id && formik.values?.multimedia) {
      setLocalMultimedia(formik.values?.multimedia);
    }
  }, [id, formik.values.multimedia]);

  useEffect(() => {
    const apiKey = apiKeys.GMAP_API_Key;
    const latitude = formik.values.latitude;
    const longitude = formik.values.longitude;
    const url = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&location_type=ROOFTOP&result_type=street_address&key=${apiKey}`;
    axios
      .get(url)
      .then(response => {
        const results = response.data.results;
        if (results.length > 0) {
          const address = results[0].formatted_address;
          formik.setFieldValue('place', address);
          console.log('Reverse Geocoded Address:', address);
        } else {
          console.log('No results found');
        }
      })
      .catch(error => {
        console.error('Error:', error);
      });
  }, [formik.values.latitude]);

  const handleImageClear = id => {
    const newMultiMedia = multiMedia.filter(media => media.id !== id);
    const newLocalMultiMedia = localMultimedia.filter(media => media.id !== id);
    setLocalMultimedia(newLocalMultiMedia);
    const idExistsInMultimedia = formik.values.multimedia?.some(media => media.id === id);
    const idExistsInFormikMultimedia = formikMultimedia?.some(media => media.id === id);
    if (idExistsInMultimedia) {
      const newMultimedia = formik.values.multimedia.filter(media => media.id !== id);
      formik.setFieldValue('multimedia', newMultimedia);
    }
    if (idExistsInFormikMultimedia) {
      const newFormikMultimedia = formikMultimedia.filter(media => media.id !== id);
      setFormikMultimedia(newFormikMultimedia);
    }
    if (newMultiMedia?.length > 0) {
      setMultiMedia(newMultiMedia);
    }
  };

  useEffect(() => {
    if (localMultimedia?.length > 0) {
      if (currentImageId === null || !localMultimedia.some(item => item.id === currentImageId)) {
        setCurrentImageId(localMultimedia[0].id);
      }
    } else {
      setCurrentImageId(null);
    }
  }, [localMultimedia]);

  const handleClick = () => {
    const combinedMultimedia = [...formik.values.multimedia, ...formikMultimedia];
    formik.setFieldValue('multimedia', combinedMultimedia);
  };
  const handleSelectCategory = (event: SelectChangeEvent) => {
    formik.setFieldValue('categoryID', event.target.value);
  };

  const checkFormErrors = () => {
    const hasErrors =
      (formik.touched.categoryID && formik.errors.categoryID) ||
      (formik.touched.title && formik.errors.title) ||
      (formik.touched.targetSignature && formik.errors.targetSignature) ||
      (formik.touched.details && formik.errors.details) ||
      (formik.touched.petitionTo && formik.errors.petitionTo) ||
      (formik.touched.location && formik.errors.location) ||
      (formik.touched.multimedia && formik.errors.multimedia) ||
      (formik.touched.startDate && formik.errors.startDate) ||
      (formik.touched.endDate && formik.errors.endDate);
    setHasFormErrors(hasErrors);
  };

  useEffect(() => {
    checkFormErrors();
  }, [formik.values, formik.errors]);

  const validateStartDate = (date: dayjs.Dayjs) => {
    const dates = dayjs(date);
    const startOfDay = dates.startOf('day');
    formik.setFieldValue('startDate', startOfDay);
    if (date.isBefore(dayjs(), 'day')) {
      console.error('Invalid startDate: Start date cannot be before the current date.');
      formik.setFieldValue('startDate', '');
    }
  };

  const validateEndDate = (date: dayjs.Dayjs) => {
    const endDateWithTime = date.hour(23).minute(58);
    const dates = dayjs(date);
    const endOfDay = dates.endOf('day');
    formik.setFieldValue('endDate', endOfDay);
    if (formik.values.startDate && endDateWithTime.isBefore(formik.values.startDate, 'minute')) {
      console.error('Invalid endDate: End date cannot be before the start date.');
      formik.setFieldValue('endDate', '');
    }
  };
  const input = document.querySelector('input[name="targetSignature"]');

  if (input) {
    input.addEventListener('wheel', function (event) {
      event.preventDefault();
    });
  }

  useEffect(() => {
    console.log('mine petiton', formik?.values?.petitionTo);
    if (formik.values?.petitionTo?.length === 1 && formik?.values?.petitionTo[0] === '') {
      formik.setFieldValue('petitionTo', []);
    }
  }, [formik.values?.petitionTo]);

  return (
    <Stack direction="column" gap={3}>
      <style>
        {`.css-92qcvy:focus{
        border: 2px solid #ffc107; 
        outline: none;      }
        `}
        {`.css-10ekoyo:focus{
        border: 2px solid #ffc107; 
        outline: none;      }
        `}{
          `.css-1ayo7ul-MuiStack-root{
          border:none;}`
        }
      </style>
      <Grid container direction="row" gap={{ xs: 2, md: 2 }} alignItems={'center'} justifyContent={'space-between'}>
        <Grid item xs={12} md={12} lg={12}>
          <FormControl fullWidth sx={{ backgroundColor: 'secondary.dark', borderRadius: '15px' }}>
            <Select
              size="small"
              name="categoryID"
              fullWidth
              displayEmpty
              placeholder="Select Category"
              labelId="select-categories"
              id="select-categories"
              disabled={petition?.petitionCount > 1}
              value={formik.values.categoryID}
              onChange={handleSelectCategory}
              error={formik.touched.categoryID && Boolean(formik.errors.categoryID)}
              MenuProps={{
                PaperProps: {
                  style: {
                    overflowY: 'scroll',
                  },
                },
                MenuListProps: {
                  style: {
                    maxHeight: '200px',
                  },
                },
              }}
              sx={{
                border: 'none',
                '&.MuiMenu-paper': {
                  borderRadius: '18px',
                  border: 'none',
                },
                '&.MuiOutlinedInput-root': {
                  borderRadius: '18px',
                  height: 45,
                  border: 'none',
                  '& fieldset': {
                    border: '1px solid #BFBFBF',
                  },
                },
              }}
            >
              <MenuItem disabled value="">
                <em>
                  <Typography sx={{ fontSize: '.75rem', fontStyle: 'normal', color: theme.textColor.paragraph }}>Select Category</Typography>
                </em>
              </MenuItem>
              {categories?.map((category: any) => {
                const matchingIcon = sideBaricons.find(iconData => iconData.id === category.id);
                return (
                  <MenuItem value={category.id} key={category.id}>
                    <Stack direction={'row'} pl={1} gap={1}>
                      {matchingIcon?.icon ?? null}
                      <Typography sx={{ fontSize: '.75rem', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                        {category.category}
                      </Typography>
                    </Stack>
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
          {formik.touched.categoryID && formik.errors.categoryID && (
            <Typography variant="caption" sx={{ color: 'error.main' }}>
              {formik.touched.categoryID && formik.errors.categoryID}
            </Typography>
          )}
        </Grid>
        <Grid item xs={12} md={12} lg={12}>
          <FormControl
            fullWidth
            sx={{ mt: 2, backgroundColor: 'secondary.dark', color: 'secondary.main', borderRadius: '15px' }}
            variant="outlined"
          >
            <OutlinedInput
              name="title"
              onChange={formik.handleChange}
              value={formik.values.title}
              onBlur={formik.handleBlur}
              error={formik.touched.title && Boolean(formik.errors.title)}
              disabled={petition?.petitionCount > 1}
              sx={{
                height: '45px',
                color: 'secondary.main',
                border: '1px solid #BFBFBF',
                '&.MuiOutlinedInput-input::placeholder': {
                  color: 'secondary.contrastText',
                },
                '&.MuiOutlinedInput-root': {
                  background: 'secondary.main',
                  borderRadius: '15px',
                  border: 'none',
                  fontSize: '.75rem',
                },
                '& fieldset': {
                  border: isDarkMode.darkMode ? '1px solid #BFBFBF' : 'null',
                },
              }}
              size="small"
              type={'text'}
              placeholder="Title of your petition"
            />
          </FormControl>
          {formik.touched.title && formik.errors.title && (
            <Typography variant="caption" sx={{ color: 'error.main' }}>
              {formik.touched.title && formik.errors.title}
            </Typography>
          )}
        </Grid>
        <div className=" col-12">
          <StyledTextarea
            value={formik.values.details}
            onChange={e => {
              const typedText = e.target.value;
              formik.setFieldValue('details', typedText);
              if (typedText === '<p><br></p>') {
                formik.setFieldError('details', 'Petition details is required');
              } else if (typedText.length > 0) {
                formik.setFieldError('details', '');
              }
            }}
            onPaste={e => {
              const pastedText = e.clipboardData.getData('text/plain');
              formik.setFieldValue('details', pastedText);
              if (pastedText === '<p><br></p>') {
                formik.setFieldError('details', 'Petition details is required');
              } else if (pastedText.length > 0) {
                formik.setFieldError('details', '');
              }
            }}
            disabled={petition?.petitionCount > 1}
            placeholder="Details of your petition *"
            style={{ fontSize: '.75rem' }}  // Set the font size here
          />
          {formik.errors.details !== null && (
            <Typography variant="caption" sx={{ color: 'error.main' }}>
              {formik.errors.details ? formik.errors.details.toString() : ''}
            </Typography>
          )}
        </div>

        <Grid item xs={12} md={5}>
          <FormControl
            fullWidth
            sx={{ backgroundColor: 'secondary.dark', color: 'secondary.main', borderRadius: '15px' }}
            variant="outlined"
          >
            <OutlinedInput
              name="targetSignature"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.targetSignature}
              error={formik.touched.targetSignature && Boolean(formik.errors.targetSignature)}
              sx={{
                height: '45px',
                color: 'secondary.main',
                border: '1px solid #BFBFBF',
                '&.MuiOutlinedInput-input::placeholder': {
                  color: 'secondary.contrastText',
                },
                '&.MuiOutlinedInput-root': {
                  background: 'secondary.main',
                  borderRadius: '15px',
                  border: 'none',
                  fontSize: '.75rem',
                },
                '& fieldset': {
                  border: isDarkMode.darkMode ? '1px solid #BFBFBF' : 'null',
                },
              }}
              size="small"
              type={'number'}
              placeholder="Target signature(s) "
              inputProps={{
                min: petition?.petitionCount,
              }}
            />
          </FormControl>
          {formik.touched.targetSignature && formik.errors.targetSignature && (
            <Typography variant="caption" sx={{ color: 'error.main' }}>
              {formik.touched.targetSignature && formik.errors.targetSignature}
            </Typography>
          )}
          {formik.touched.targetSignature && formik.values.targetSignature === petition?.petitionCount && petition?.petitionCount > 1 && (
            <Typography variant="caption" sx={{ color: 'error.main' }}>
              Target signature cannot be set below the current signature count.
            </Typography>
          )}
        </Grid>

        <Grid item xs={12} md={6}>
          <FormControl
            component="fieldset"
            fullWidth
            sx={{
              backgroundColor: 'secondary.dark',
              color: 'secondary.main',
              borderRadius: '15px',
              width: '100%',
            }}
          >
            <Select
              size="small"
              displayEmpty
              name="organizationId"
              fullWidth
              placeholder="Post as"
              labelId="organization-id"
              id="organizationId"
              disabled={petition?.petitionCount > 1}
              value={
                postAsSelectedValue === 'My Self' ? 'My Self' : formik.values.organizationId ? formik.values.organizationId : 'My Self'
              }
              onChange={event => {
                const selectedValue = event.target.value;
                setPostAsSelectedValue(selectedValue);

                formik.handleChange(event);
                const isPostAsOrganisation = selectedValue !== 'My Self';
                formik.setFieldValue('postAsOrganisation', isPostAsOrganisation);
                formik.setFieldValue('organizationId', isPostAsOrganisation ? selectedValue : '');
              }}
              MenuProps={{
                PaperProps: {
                  style: {
                    overflowY: 'scroll',
                  },
                },
                MenuListProps: {
                  style: {
                    maxHeight: '200px',
                  },
                },
              }}
              sx={{
                border: 'none',

                '&.MuiMenu-paper': {
                  backgroundColor: '#FF5500',
                  borderRadius: '15px',
                  border: 'none',
                },
                '&.MuiOutlinedInput-root': {
                  background: 'secondary.main',
                  borderRadius: '15px',
                  height: 45,
                  fontSize: '.75rem',
                  border: 'none',
                  '& fieldset': {
                    border: '1px solid #BFBFBF',
                  },
                },
              }}
            >
              <MenuItem value="" disabled>
                <em>
                  <Typography sx={{ fontSize: '.75rem', fontStyle: 'normal', color: theme.textColor.paragraph }}>Post as</Typography>
                </em>
              </MenuItem>
              <MenuItem value="My Self">Myself</MenuItem>
              {userGrantedOrganizations
                ?.filter(item => !item.deleted)
                ?.map((item: any) => (
                  <MenuItem value={item.id} key={item.id}>
                    {item.label}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
          {formik.touched.postAsOrganisation && formik.errors.postAsOrganisation && (
            <Typography variant="caption" sx={{ color: 'error.main' }}>
              {formik.touched.postAsOrganisation && formik.errors.postAsOrganisation ? formik.errors.postAsOrganisation : ''}
            </Typography>
          )}
        </Grid>

        <Grid item xs={12} md={5}>
          <FormControl
            fullWidth
            sx={{ backgroundColor: 'secondary.dark', color: 'secondary.main', borderRadius: '15px' }}
            variant="outlined"
          >
            <OutlinedInput
              name="petitionTo"
              onChange={event => {
                const petitionToValues = event.target.value.split(',').map(item => item);
                formik.setFieldValue('petitionTo', petitionToValues);
              }}
              onBlur={formik.handleBlur}
              disabled={petition?.petitionCount > 1}
              value={(formik.values.petitionTo || []).join(',')}
              error={formik.touched.petitionTo && Boolean(formik.errors.petitionTo)}
              onFocus={() => {
                formik.setFieldTouched('petitionTo');
                formik.validateField('petitionTo');
              }}
              sx={{
                height: '45px',
                color: 'secondary.main',
                border: '1px solid #BFBFBF',
                '&.MuiOutlinedInput-input::placeholder': {
                  color: 'secondary.contrastText',
                },
                '&.MuiOutlinedInput-root': {
                  background: 'secondary.main',
                  borderRadius: '15px',
                  border: 'none',
                  fontSize: '.75rem',
                },
                '& fieldset': {
                  border: isDarkMode.darkMode ? '1px solid #BFBFBF' : 'null',
                },
              }}
              size="small"
              type={'text'}
              placeholder="Petiton to "
            />
          </FormControl>
          {formik.touched.petitionTo && formik.errors.petitionTo && (
            <Typography variant="caption" sx={{ color: 'error.main' }}>
              {formik.touched.petitionTo && formik.errors.petitionTo}
            </Typography>
          )}
        </Grid>
        <Grid item xs={12} md={6}>
          <FormControl
            fullWidth
            sx={{ backgroundColor: 'secondary.dark', color: 'secondary.main', borderRadius: '15px' }}
            variant="outlined"
          >
            <OutlinedInput
              onChange={formik.handleChange}
              readOnly
              onBlur={formik.handleBlur}
              disabled={petition?.petitionCount > 1}
              error={formik.touched.location && Boolean(formik.errors.location)}
              sx={{
                height: '45px',
                color: 'secondary.main',
                border: '1px solid #BFBFBF',
                '&.MuiOutlinedInput-input::placeholder': {
                  color: 'secondary.contrastText',
                },
                '&.MuiOutlinedInput-root': {
                  background: 'secondary.main',
                  borderRadius: '15px',
                  border: 'none',
                  fontSize: '.75rem'
                },
                '& fieldset': {
                  border: isDarkMode.darkMode ? '1px solid #BFBFBF' : 'null',
                },
              }}
              size="small"
              type={'text'}
              name="latitude"
              value={
                formik.values.place
                  ? formik.values.place
                  : formik.values.latitude && formik.values.longitude
                    ? `Lat: ${formik.values.latitude}, Long: ${formik.values.longitude}`
                    : ''
              }
              placeholder='Location'
              onClick={handleOpenModal}
            />
          </FormControl>
          {formik.touched.location && formik.errors.location && (
            <Typography variant="caption" sx={{ color: 'error.main' }}>
              {formik.touched.location && formik.errors.location}
            </Typography>
          )}
        </Grid>

        <Grid item xs={12} md={5}>
          <FormControl component="fieldset" fullWidth>
            <FormControlLabel
              sx={{ color: 'secondary.main', fontSize: '14px' }}
              control={
                <Checkbox
                  name="urgent"
                  checked={formik.values.urgent}
                  onChange={event => formik.setFieldValue('urgent', event.target.checked)}
                  disableRipple
                  sx={{
                    color: 'transparent',
                    '&.Mui-checked': { color: 'primary.light' },
                    '& .MuiSvgIcon-root': {
                      fontSize: 23,
                      borderRadius: '25%',
                      border: '1px solid #BFBFBF',
                      padding: { xs: 'none', md: '3px' },
                    },
                  }}
                />
              }
              label={
                <Typography
                  sx={{
                    color: theme.textColor.mainHeading,
                    fontSize: theme.p1.semiBold.fontSize,
                    fontWeight: theme.p1.semiBold.fontWeight,
                  }}
                >
                  Mark as urgent
                </Typography>
              }
              labelPlacement="end"
              disabled={petition?.petitionCount > 1}
            />
          </FormControl>
        </Grid>

        <Grid item container xs={12} direction="column" gap={1}>
          <Grid
            item
            xs={12}
            style={{
              outline: formik.touched.multimedia && formik.values.multimedia?.length <= 0 ? '1px solid red' : '1px solid #888A8E',
              borderRadius: '18px',
            }}
          >
            <Stack
              direction="column"
              gap={0.3}
              sx={{
                maxWidth: { xs: '100%' },
                borderRadius: '15px',
                border: formik.touched.multimedia && formik.values.multimedia?.length < 1 ? '0.5px solid red' : '0.5px solid #F6F7F8',
                backgroundColor: 'secondary.dark',
                p: { xs: 2, lg: 3 },
              }}
            >
              {localMultimedia?.length > 0 ? (
                <Stack direction="column" sx={{ width: '100%' }} spacing={1}>
                  <Carousel
                    dynamicHeight={true}
                    showThumbs={false}
                    showStatus={true}
                    showArrows={true}
                    showIndicators={false}
                    useKeyboardArrows={true}
                    selectedItem={currentImageId}
                    autoPlay={true}
                    interval={1000}
                    onChange={newSelectedIndex => setCurrentImageId(localMultimedia[newSelectedIndex].id)} // Update currentImageId when the selected item changes
                  >
                    {localMultimedia?.length > 0 &&
                      localMultimedia?.map((item, index) => (
                        <Box sx={{ position: 'relative', width: '100%' }} key={index}>
                          <DeleteIcon
                            sx={{
                              color: petition?.petitionCount > 1 ? 'gray' : 'error.main',
                              background: '#fafafa',
                              position: 'absolute',
                              top: 5,
                              right: 40,
                              cursor: petition?.petitionCount > 1 ? 'not-allowed' : 'pointer',
                              borderRadius: '40%',
                              pointerEvents: petition?.petitionCount > 1 ? 'none' : 'auto',
                            }}
                            onClick={() => {
                              if (petition?.petitionCount <= 1) {
                                handleImageClear(item.id);
                              }
                            }}
                          />

                          <div>
                            <img
                              src={item.link ? item.link : item.url || 'content/images/no-img.jpg'}
                              alt={`slide${index + 1}`}
                              style={{ height: '130px', objectFit: 'cover' }}
                            />
                          </div>
                        </Box>
                      ))}
                  </Carousel>
                  <Stack direction="row" alignItems={'center'} justifyContent={'flex-end'}>
                    <div {...getRootProps()}>
                      <input {...getInputProps()} name={'multimedia'} />
                      <Button
                        variant="contained"
                        sx={{
                          backgroundColor: 'primary.light',
                          color: 'common.white',
                          '&:hover': {
                            color: 'common.white',
                            backgroundColor: 'primary.light',
                            boxShadow: 'none',
                          },
                        }}
                        disabled={petition?.petitionCount > 1}
                      >
                        + Add more
                      </Button>
                    </div>
                  </Stack>
                </Stack>
              ) : (
                <Box>
                  <div {...getRootProps()}>
                    <input {...getInputProps()} name={'multimedia'} disabled={petition?.petitionCount > 1} />
                    <Grid
                      container
                      direction={'row'}
                      alignItems={'center'}
                      height={130}
                      justifyContent={'center'}
                      sx={{ borderRadius: '15px', backgroundColor: theme.palette.mode === 'dark' ? '' : '#FFFF', cursor: 'pointer', px: 2 }}
                    >
                      <Stack direction={'row'} gap={2}>
                        <FileUploadOutlinedIcon />
                        <Typography
                          sx={{
                            color: theme.textColor.mainHeading,
                            fontSize: theme.p1.semiBold.fontSize,
                            fontWeight: theme.p1.semiBold.fontWeight,
                          }}
                        >
                          Drop your image files here, or <span style={{ color: 'primary.main' }}>browse</span>
                          <br></br>(Accepted file formats: JPG, JPEG, PNG)
                        </Typography>
                      </Stack>
                    </Grid>
                  </div>
                </Box>
              )}
              {errorMessage && (
                <div style={{ margin: '10px 0px', fontFamily: 'Manrope', fontWeight: '400', fontSize: '12px', color: ' #E23A44' }}>
                  {errorMessage}
                </div>
              )}
            </Stack>
          </Grid>
          <Grid item xs={12}>
            {formik.touched.multimedia && formik.values.multimedia?.length <= 0 ? (
              <Typography variant="caption" sx={{ color: 'error.main' }}>
                Image is required
              </Typography>
            ) : null}
          </Grid>
        </Grid>

        <Grid container direction={'row'} gap={{ xs: 2, md: 0.5 }} alignItems={'center'} justifyContent={'space-between'}>
          <Grid item xs={12} md sx={{ marginRight: { md: '18px' } }}>
            <div style={{ height: '60px' }}>
              <DatePicker
                value={dayjs(formik.values.startDate)}
                minDate={dayjs(formik.values.startDate)}
                defaultValue={formik.values.startDate}
                disabled={petition?.petitionCount > 1}
                label="Start date"
                sx={{
                  width: '100%',

                  '& .MuiInputBase-root': {
                    borderRadius: '18px',
                    height: '45px',
                    fontSize: '.75rem',
                    border: 'none',
                    '& fieldset': {
                      border: '1px solid #BFBFBF',
                    },
                  },

                  '& .MuiInputAdornment-root': {},
                }}
                onChange={newValue => {
                  const enteredDate = dayjs(newValue);
                  validateStartDate(enteredDate);
                }}
              />

              {formik.touched.startDate && formik.errors.startDate && Boolean(formik.errors.startDate) && (
                <Typography variant="caption" sx={{ color: 'error.main', mb: { xs: 1, lg: 0 } }}>
                  {formik.touched.startDate && formik.errors.startDate ? formik.errors.startDate.toString() : ''}
                </Typography>
              )}
            </div>
          </Grid>

          <Grid item xs={12} md>
            <div style={{ height: '60px' }} onClick={() => { }}>
              <DatePicker
                value={dayjs(formik.values.endDate)}
                minDate={dayjs(formik.values.startDate)}
                defaultValue={dayjs(formik.values.endDate)}
                label="End date"
                sx={{
                  width: '100%',
                  '& .MuiInputBase-root': {
                    borderRadius: '15px',
                    height: '45px',
                    fontSize: '.75rem',
                    border: 'none',
                    '& fieldset': {
                      border: '1px solid #BFBFBF',
                    },
                  },
                  '& .MuiInputAdornment-root': {
                    padding: 0,
                  },
                }}
                onChange={newValue => {
                  const enteredDate = dayjs(newValue);
                  validateEndDate(enteredDate);
                }}
              />
              {formik.touched.endDate && formik.errors.endDate && (
                <Typography variant="caption" sx={{ color: 'error.main' }}>
                  {formik.errors.endDate}
                </Typography>
              )}
            </div>
          </Grid>
        </Grid>

        <Grid item xs={2} md={2} lg={2}>
          <Button
            variant="contained"
            fullWidth
            sx={{
              backgroundColor: 'primary.light',
              textTransform: 'none',
              borderRadius: '15px',
              color: 'secondary.dark',
              height: '40px',
            }}
            onClick={handleCancelButtonClick}
          >
            Cancel
          </Button>
        </Grid>

        <Grid item xs={2} md={2} lg={2}>
          <Button
            type="submit"
            variant="contained"
            fullWidth
            sx={{
              backgroundColor: 'primary.main',
              textTransform: 'none',
              borderRadius: '15px',
              color: 'secondary.dark',
              height: '40px',
              pointerEvents: hasFormErrors ? 'none' : 'auto',
              opacity: hasFormErrors ? 0.6 : 1,
            }}
            onClick={() => handleClick()}
            disabled={!formik.isValid}
          >
            Submit
          </Button>
        </Grid>
      </Grid>
      <Dialog fullScreen={fullScreen} open={isModalOpen} onClose={handleCloseModal}>
        <MapBoxMapView
          handleCloseModal={handleCloseModal}
          formik={formik}
          handleSelectLocation={handleSelectLocation}
          handleLocationFromSearchBox={handleLocationFromSearchBox}
        />
      </Dialog>
    </Stack>
  );
};

export default EditPetitionMain;
