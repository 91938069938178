import React from 'react';
import { Grid } from '@mui/material';
import ChangeVisibility from './change-visibility/change-visibility';
import BlockedAccounts from './blocked-accounts/blocked-accounts';

const SettingsPrivacy = () => {
  return (
    <Grid container direction="column" gap={3}>
      <Grid item xs={12} md={6}>
        <Grid container direction="column"  >
          <ChangeVisibility />
          <BlockedAccounts />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default SettingsPrivacy;
