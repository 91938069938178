import ReactGA from 'react-ga4';

const initializeGA = () => {
  let gaTrackingId = '';
  const currentURL = window.location.href;
  if (currentURL.includes('localhost')) {
    gaTrackingId = 'G-D6WJMDY8M8';
  } else if (currentURL.includes('qa')) {
    gaTrackingId = 'G-D6WJMDY8M8';
  } else if (currentURL.includes('stage')) {
    gaTrackingId = 'G-D6WJMDY8M8';
  } else {
    // production
    gaTrackingId = 'G-0CLTTBY4XJ';
  }
  console.log(gaTrackingId, 'gaTrackingId')
  ReactGA.initialize(gaTrackingId);
};


export default initializeGA;
