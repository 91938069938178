import React, { useEffect, useState } from 'react';
import '../dashboard.scss';
import AppBar from '@mui/material/AppBar';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import PrimaryHeader from 'app/components/layout/header/primary-header';
import SideNavigation from 'app/components/layout/side-navigaion/side-navigation';

import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import Cookies from 'js-cookie';
import { Grid, Typography } from '@mui/material';
import SectionHead from '../general/section-head';
import SubHead from '../general/sub-head';
import RankingCard from './ranking-card';
import MultiBarChart from '../general/multi-bar-graph';
import ActualGoalCard from '../general/actual-goal-card';
import BottomBar from '../general/bottom-bar';
import {
  fetchActivityCountForCategoryByDates,
  fetchGoalCountsForCategory,
  fetchHighestDetailsForCategory,
} from 'app/shared/reducers/dashboard/dashboard';
import { formatDate } from 'app/utils/common-utils/common-utils';
import ColoredBox from '../general/graph/colored-box';
import { fetchAllCategoriesSubcategories, fetchAllCategoriesSubcategoriesUnauthorized } from 'app/shared/reducers/categories-subcategories';
import toast from 'react-hot-toast';
import DoughnutCard from '../home/doughnut-card';
import AmyIcon from 'app/components/common/cards/chat-card/amy-icons';

const drawerWidth = 315;
const drawerWidthSm = 248;

interface Props {
  window?: () => Window;
}

const DashboardCategory = (props: Props) => {
  const { window } = props;
  const [mobileOpen, setMobileOpen] = useState(false);
  const [searchKeyword, setSearchKeyword] = useState<string>('');
  const [category, setCategory] = useState('');
  const [subCategory, setSubCategory] = useState('');
  const [toggleValue, setToggleValue] = useState(false);
  const [toggleMapValue, setToggleMapValue] = useState(false);
  const [categoryData, setCategoryData] = useState([]);
  const [title, setTitle] = useState('');

  const myCookieValue = Cookies.get('jhi-authenticationToken');
  const authenticationToken = myCookieValue;
  const isAuthenticated = useAppSelector(state => state.authentication.isAuthenticated);

  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const isDarkMode = theme.palette.mode === 'dark';
  const container = window !== undefined ? () => window().document.body : undefined;

  const goalCountsForCategory = useAppSelector(state => state.dashboardReducer.data.goalCountsForCategory.successResponse);
  const activityCountForCategoryByDates = useAppSelector(
    state => state.dashboardReducer.data.activityCountForCategoryByDates.successResponse
  );
  const categoryHighestDetails = useAppSelector(state => state.dashboardReducer.data.categoryHighestDetails.successResponse);

  useEffect(() => {
    const fetchCategoryItem = async () => {
      let response;
      try {
        if (isAuthenticated) {
          response = await dispatch(fetchAllCategoriesSubcategories());
        } else {
          response = await dispatch(fetchAllCategoriesSubcategoriesUnauthorized());
        }
        if (response.meta.requestStatus === 'fulfilled') {
          setCategoryData(response.payload);
        } else {
          toast.error('Error fetching category items', { id: 'menu-item-error' });
        }
      } catch (error) {
        console.error('Error fetching category items', error);
      }
    };

    fetchCategoryItem();
  }, []);

  useEffect(() => {
    const currentCategory = categoryData.find(category => category.id === parseInt(id, 10));
    if (currentCategory) {
      setTitle(currentCategory.category);
    }
  }, [categoryData, id]);

  useEffect(() => {
    if (id) {
      dispatch(fetchGoalCountsForCategory(id));
      dispatch(fetchActivityCountForCategoryByDates(id));
      dispatch(fetchHighestDetailsForCategory(id));
    }
  }, [id]);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleSubCategoryClick = (category: string, subCategory: string) => {
    setCategory(category);
    setSubCategory(subCategory);
  };
  const handleAccordionClick = (category: any, currentState: any) => {
    setCategory(category);
  };

  const handleButtonClicked = () => {
    const newToggleValue = !toggleValue;
    if (toggleMapValue) {
      setToggleMapValue(false);
    }
    setToggleValue(newToggleValue);
  };

  const handleButtonMapClicked = () => {
    const newToggleMapValue = !toggleMapValue;
    if (toggleValue) {
      setToggleValue(false);
    }
    setToggleMapValue(newToggleMapValue);
  };

  const handleButtonFeatureClicked = () => {};

  const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
  }));

  const latestStartDate = formatDate(activityCountForCategoryByDates?.latestStartDate, 'DD-MMM-YYYY');
  const previousStartDate = formatDate(activityCountForCategoryByDates?.previousStartDate, 'DD-MMM-YYYY');

  const drawer = (
    <Box
      sx={{
        border: 'none',
        maxHeight: '100vh',
        overflowY: 'scroll',
        overflowX: 'hidden',

        background:
          theme.palette.mode === 'dark'
            ? '#1F1F26'
            : 'transparent linear-gradient(182deg, #F7F5EF 0%, #F2F2FA 49%, #f1f1f8 100%) 0% 0% no-repeat padding-box;',
        maxWidth: { sm: drawerWidthSm, lg: drawerWidth, md: drawerWidthSm },
        backdropFilter: 'blur(10px)',
        minHeight: '100%',
        '&::-webkit-scrollbar': {
          display: 'none',
        },
        '&::-webkit-scrollbar-thumb': { backgroundColor: 'secondary.contrastText', height: '10px' },
        '&::-webkit-scrollbar-track': {
          backgroundColor: 'transparent',
        },
      }}
    >
      {/* <DrawerHeader sx={{ justifyContent: 'center', height: '79px', border: 'none' }}>
        <Box style={{ paddingLeft: '0px', paddingRight: '50px', cursor: 'pointer' }} onClick={() => navigate('/')}>
          <img src="content/images/logos/reachamy-logo.png" alt="reach amy logo" />
        </Box>
      </DrawerHeader> */}
      <DrawerHeader sx={{ justifyContent: 'center', height: '79px', border: 'none' }}>
        <Link to={'/'} style={{ paddingLeft: '0px', paddingRight: '50px' }}>
          <AmyIcon sx={{ width: '159px', height: '100px' }} />
        </Link>
      </DrawerHeader>

      <SideNavigation
        category={category}
        subCategory={subCategory}
        onSubCategoryClick={handleSubCategoryClick}
        onhandleAccordionClick={handleAccordionClick}
        onButtonClicked={handleButtonClicked}
        toggleMapValue={toggleMapValue}
        setToggleMapValue={setToggleMapValue}
        onMapClicked={handleButtonMapClicked}
        onFeatureClicked={handleButtonFeatureClicked}
      />
      <Divider />
    </Box>
  );

  return (
    <Box
      sx={{
        display: 'flex',
        background: isDarkMode ? '#1F1F26' : 'transparent linear-gradient(118deg, #F7F5EF 0%, #F2F2FA 49%, #EFEFF5 100%) 0% 0% no-repeat',
        p: 0,
        border: 'none',
        backdropFilter: 'blur(10px)',
        height: '100vh',
        overflow: 'hidden',
      }}
    >
      <CssBaseline />

      <AppBar
        elevation={0}
        position="fixed"
        sx={{
          width: { sm: `calc(100% - ${drawerWidthSm}px)`, md: `calc(100% - ${drawerWidthSm}px)`, lg: `calc(100% - ${drawerWidth}px)` },
          ml: { sm: `${drawerWidthSm}px`, md: `${drawerWidthSm}px`, lg: `${drawerWidth}px` },
          background: isDarkMode ? '#1F1F26' : '#f6f4f1',
        }}
      >
        <Toolbar
          className="amy-top-nav"
          sx={{
            backgroundColor: ' transparent linear-gradient(118deg, #F7F5EF 0%, #F2F2FA 49%, #EFEFF5 100%) 0% 0% no-repeat',
            border: 'none',
            bakground: isDarkMode ? '#1F1F26' : '',
            backdropFilter: 'blur(10px)',
          }}
        >
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: 'none' }, top: '10px' }}
          >
            <MenuIcon />
          </IconButton>
          <PrimaryHeader searchKeyword={searchKeyword} setSearchKeyword={setSearchKeyword} />
        </Toolbar>
      </AppBar>
      {/* Side Nav */}
      <Box
        component="nav"
        sx={{
          width: { sm: drawerWidthSm, md: drawerWidthSm, lg: drawerWidth },
          flexShrink: { sm: 0 },
          minHeight: '100vh',
          border: 'none',
          background: isDarkMode ? '#1F1F26' : 'transparent linear-gradient(118deg, #F7F5EF 0%, #F2F2FA 49%, #EFEFF5 100%) 0% 0% no-repeat',
        }}
        aria-label="mailbox folders"
      >
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true,
          }}
          sx={{
            display: { xs: 'block', sm: 'none' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidthSm },
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: 'none', sm: 'block' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: { sm: drawerWidthSm, md: drawerWidthSm, lg: drawerWidth } },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>
      {/* End side Nav */}

      {/* main body */}
      <Box
        className="inside-bg"
        component="main"
        sx={{
          flexGrow: 1,
          p: 3,
          width: '0px',
          background: isDarkMode ? '#1F1F26' : ' #f6f4f1',
          border: 'none',

          overflowY: 'scroll',
        }}
      >
        <Toolbar />
        <div className="container-fluid p-0" style={{ background: isDarkMode ? '#1F1F26' : '' }}>
          <Grid py={2}>
            <SectionHead title={title} icon="-" />
            <SubHead title="Top 10 users/orgs" />
            <Grid container spacing={1.8}>
              <Grid display="flex" item xs={12} sm={6} md={3}>
                <RankingCard
                  title="Highest no.of Volunteers"
                  description="Top 10 users/orgs with highest volunteers"
                  data={categoryHighestDetails?.highVolunteerDetails}
                  type="volunteers"
                />
              </Grid>
              <Grid display="flex" item xs={12} sm={6} md={3}>
                <RankingCard
                  title="Highest Upvotes"
                  description="Top 10 users/orgs with highest upvotes"
                  data={categoryHighestDetails?.highestUpvoteDetails}
                  type="upvotes"
                />
              </Grid>
              <Grid display="flex" item xs={12} sm={6} md={3}>
                <RankingCard
                  title="Highest Shares"
                  description="Top 10 users/orgs with highest shares"
                  data={categoryHighestDetails?.highestSharesDetails}
                  type="shares"
                />
              </Grid>
              <Grid display="flex" item xs={12} sm={6} md={3}>
                <RankingCard
                  title="Highest views"
                  description="Top 10 users/orgs with the highest views"
                  data={categoryHighestDetails?.highestViewDetails}
                  type="views"
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={8}>
            <SubHead title="Activity" />
            <Box position="relative" overflow="hidden" bgcolor={isDarkMode ? '#2c2c38' : '#fff'} p={2.9} flex="1" borderRadius={1}>
              <MultiBarChart
                latestStartDate={activityCountForCategoryByDates?.latestStartDate}
                previousStartDate={activityCountForCategoryByDates?.previousStartDate}
                latestData={activityCountForCategoryByDates?.latestActivityCounts}
                prevData={activityCountForCategoryByDates?.previousActivityCounts}
              />
              <Box display="flex" alignItems="center" justifyContent="center" gap={2}>
                <ColoredBox
                  color="#674BFF"
                  label="30 days"
                  startDate={latestStartDate}
                  count={activityCountForCategoryByDates?.latestActivityTotalCount}
                />
                <ColoredBox
                  color="#EC6666"
                  label="30 days"
                  startDate={previousStartDate}
                  count={activityCountForCategoryByDates?.previousActivityTotalCount}
                />
              </Box>
              <BottomBar />
            </Box>
          </Grid>
          <Grid container spacing={1.7} py={4}>
            {goalCountsForCategory?.length > 0 &&
              goalCountsForCategory.map((goal, index) => (
                <Grid display="flex" item xs={12} md={6} lg={3}  key={index}>
                  <DoughnutCard                  
                    name={goal?.goalName}
                    achieved={goal?.achievedGoal}
                    target={goal?.targetGoal}
                    startDate={goal?.startDate}
                    endDate={goal?.endDate}
                    currencyCode={goal?.currencyCode}
                  />
                </Grid>
              ))}
            {/* 
            {goalCountsForCategory?.length > 0 &&
              goalCountsForCategory.map((goal, index) => (
                <Grid item xs={12} sm={6} md={4}>
                  <ActualGoalCard goal={goal} />
                </Grid>
              ))} */}
          </Grid>
        </div>
      </Box>
    </Box>
  );
};
export default DashboardCategory;
