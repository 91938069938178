import React, { useEffect, useMemo, useRef, useState, useContext } from 'react';
import { useNavigate, Link } from 'react-router-dom';

import {
  AppBar,
  Box,
  CssBaseline,
  Drawer,
  IconButton,
  Toolbar,
  Typography,
  useTheme,
  ListItemIcon,
  styled,
  Avatar,
  Paper,
  Divider,
  Skeleton,
} from '@mui/material';

import ChatCardleftnav from './chat-cardleftnav';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import MenuIcon from '@mui/icons-material/Menu';
import { darkModeToggle, setTheme } from 'app/shared/reducers/dark-mode';
import LightModeIcon from '@mui/icons-material/LightMode';
import LoginIcon from '@mui/icons-material/Login';
import LogoutIcon from '@mui/icons-material/Logout';
import Badge from '@mui/material/Badge';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import SquareChatIcon from 'app/components/icons/header-icons/square-chat-icon';
import QuestionAnswerRoundedIcon from '@mui/icons-material/QuestionAnswerRounded';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Logout } from '@mui/icons-material';
import AuthorisationModal from 'app/components/common/authorization-modal/authorization-modal';
import DarkModeToggleIcon from 'app/components/icons/header-icons/dark-mode-toggle-icon';
import List from '@mui/material/List';
import './chat-card.scss';
import PinnedCommentDialog from './pinned-chat';
import toast from 'react-hot-toast';
import { getAllConversationsById } from 'app/shared/reducers/chat-messages/chat-Panel';
import ActivityChatSideBar from 'app/components/ui/activity-chat-sidebar/acitivity-chat-sidebar';
import { fetchActivityDetails, fetchActivityDetailsUnauthorized, fetchPetitionDetails } from 'app/shared/reducers/activity-home';
import { getReportingReasons } from 'app/shared/reducers/activity/activity-report';
import ReportChatDialog from 'app/components/common/cards/chat-card/modals/chat-report';
import PetitionDetailSideBar from 'app/components/ui/petition/petition-details-sidebar';
import { Storage } from 'react-jhipster';
import { onForegroundMessage } from 'app/firebase';
import { getAllEvents, getAllEventsById } from 'app/shared/reducers/chat-messages/left-panel';
import ChatEdit from './modals/chat-edit';
import PrimaryHeader from 'app/components/layout/header/primary-header';
import ChatFeed from './chat-feed';
import AmyIcon from './amy-icons';
import { toggleModalLogin } from 'app/shared/reducers/authentication';
import SideNavigation from 'app/components/layout/side-navigaion/side-navigation';
import ChatEmpty from './chat-empty';
import { WebSocketProvider } from './WebSocketContext';
import WebSocketContext from './WebSocketContext';
import ActivityReccuringChatSideBar from 'app/components/ui/activity-chat-sidebar/activity-reccuring-chat-sidebar';

export const windowHeight = window.innerHeight;
interface Activity {
  id: number;
  recurringId?: number; // optional
}
const ChatCard = () => {
  const [category, setCategory] = useState('');
  const [event, setEvent] = useState({});
  const [subCategory, setSubCategory] = useState('');
  const [toggleMapValue, setToggleMapValue] = useState(false);
  const [isSearchBarVisible, setIsSearchBarVisible] = useState(true);
  const [isPinnedCommentDialogOpen, setIsPinnedCommentDialogOpen] = useState(false);
  const [chatData, setChatData] = useState(null);
  const [sendPinnedData, setSendPinnedData] = useState(null);
  const [activityDetails, setActivityDetails] = useState(null);
  const [message, setMessage] = useState('');
  const [activity, setActivity] = useState<Activity | null>(null);
  const [reportDialogOpen, setReportDialogOpen] = useState(false);
  const [chat, setChat] = useState(null);
  const [petition, setPetition] = useState({});
  const [volunteerButtonClick, setvolunteerButtonClick] = useState(false);
  const [isSubmitClicked, setIsSubmitClicked] = useState(false);
  const [isPetitionClicked, setIsPetitionClicked] = useState(false);
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [editedComment, setEditedComment] = useState({});
  const [currentComponent, setCurrentComponent] = useState(' ');
  const [authModalOpen, setAuthModalOpen] = useState(false);
  const [mobileOpen, setMobileOpen] = useState(false);
  const reduxDarkModeValue = useAppSelector(state => state.darkMode.darkMode);

  const theme = useTheme();
  const dispatch = useAppDispatch();
  const isDarkMode = theme.palette.mode === 'dark';
  const navigate = useNavigate();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const isMobile = useMediaQuery(theme.breakpoints.down(575));
  const logoSrc = 'content/images/logos/reachamy-logo.png';
  const drawerWidth = 315;
  const getAllConversation = useAppSelector(state => state.chatPanelReducer.chatResponse);
  const isAuthenticated = useAppSelector(state => state.authentication.isAuthenticated);
  const targetChatList = useAppSelector(state => state?.chatList?.targetResult);
  const getChatloading = useAppSelector(state => state.leftPanelReducer.loading);
  const getAllChatResponse = useAppSelector(state => state.leftPanelReducer.chatResponse);
  const handleSubCategoryClick = (category: string, subCategory: string) => {
    setCategory(category);
    setSubCategory(subCategory);
  };

  const handleAccordionClick = (categoryId: any, currentState: any) => {
    setCategory(categoryId);
  };

  const isInfoPage = location.pathname === '/chat/info';
  const isListPage = location.pathname === '/chat/list';

  const handleVolunteerButtonClick = value => {
    setvolunteerButtonClick(value);
  };

  const handleIsSubmitClicked = value => {
    setIsSubmitClicked(value);
  };

  const fetchConversations = async item => {
    const myDetailsAccount = localStorage.getItem('MyDetailsAccount'); // Safe retrieval
    const userId = myDetailsAccount ? JSON.parse(myDetailsAccount)?.id : null;
    const payload = {
      eventId: item?.id ? item?.id : 0,
      eventType: item?.eventType ? item?.eventType : '',
      userId: userId,
    };

    try {
      const response = await dispatch(getAllConversationsById(payload));
    } catch (error) {
      console.error('An error occurred while fetching data:', error);
    }
  };
  const [loadingData, setLoadingData] = useState<boolean>(false);
  const [changeChat, setChangeChat] = useState<any>(false);
  const [changePrimary, setChangePrimary] = useState<any>(false);
  const handleActivity = data => {
    console.log('handle activitydata', data);
    setActivityDetails(data);
    setActivity(data);
    setLoadingData(true);
  };

  useEffect(() => {
    if (activityDetails) fetchEventInfo();
  }, [activityDetails]);
  const [signPetiton, setSignPetition] = useState<any>();

  const fetchEventInfo = async () => {
    let response;
    if (isAuthenticated) {
      if (activityDetails?.eventType === 'ACTIVITY') {
        response = await dispatch(fetchActivityDetails(activityDetails?.id));

        if (response) {
          setActivity(response.payload);
        }

        if (response.payload) localStorage.setItem('chatFeedDetails', JSON.stringify(response.payload));
      } else if (activityDetails?.eventType === 'PETITION') {
        response = await dispatch(fetchPetitionDetails(activityDetails?.id));
        if (response.payload) localStorage.setItem('chatFeedDetails', JSON.stringify(response.payload));

        setSignPetition(true);
      }
    } else if (activityDetails?.id) {
      response = await dispatch(fetchActivityDetailsUnauthorized(activityDetails?.id));
    }

    if (response?.meta?.requestStatus === 'fulfilled' && activityDetails?.eventType === 'ACTIVITY') {
      setActivity(response.payload);
      setIsSubmitClicked(false);
    } else if (response?.meta?.requestStatus === 'fulfilled' && activityDetails?.eventType === 'PETITION') {
      setPetition(response.payload);
    }
  };

  const fetchPetitionInfo = async () => {
    if (activityDetails?.eventType === 'PETITION') {
      const response = await dispatch(fetchPetitionDetails(activityDetails?.id));
      setPetition(response.payload);
    }
  };

  /**
   * auto scroll to bottom of the chatfeed on updation of the chat wall
   */

  const fetchActivity = async () => {
    let payload = {
      eventId: 0,
      eventType: '',
    };

    if (localStorage.getItem('dataKey')) {
      const keysJSON = await localStorage.getItem('dataKey');
      const keys = JSON.parse(keysJSON);
      payload = {
        eventId: keys.id,
        eventType: keys.eventType?.toUpperCase(),
      };
    }

    try {
      let response;
      if (payload.eventId === 0) {
        response = await dispatch(getAllEvents());
      } else {
        response = await dispatch(getAllEventsById(payload));
      }
      setEvent(response);
    } catch (error) {
      console.error('An error occurred while fetching data:', error);
    }
  };

  useEffect(() => {
    fetchActivity();
  }, []);

  useEffect(() => {
    const data = targetChatList?.[0];
    console.log('click volunteer', data);
    // fetchEventInfo();
    // handleActivity(data);
  }, [isSubmitClicked, volunteerButtonClick]);

  const handlePetitionClicked = value => {
    fetchEventInfo();
  };

  useEffect(() => {
    if (Array.isArray(getAllConversation)) {
      setChatData(getAllConversation);
    }

    setMessage('');
  }, [getAllConversation]);

  useEffect(() => {
    const handleResize = () => {
      setIsSearchBarVisible(window.innerWidth >= 900);
    };

    // Check the window width and set the initial visibility on component mount
    window.addEventListener('resize', handleResize);
    handleResize();

    // Cleanup the event listener on component unmount
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // useEffect(() => {
  //   const data = targetChatList;
  //   handleActivity(data?.[0]);
  // }, [targetChatList]);

  const logoComponent = useMemo(() => {
    return <img src={logoSrc} alt="reach amy logo" />;
  }, [logoSrc]);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const toggleThemeHandler = () => {
    dispatch(darkModeToggle(!reduxDarkModeValue));
    Storage.local.set('dark-mode', !reduxDarkModeValue);
    if (isAuthenticated) {
      dispatch(setTheme({ darkMode: !reduxDarkModeValue }));
    }
  };

  const handleReport = data => {
    setChat(data);
    setReportDialogOpen(true);
  };

  const handleEdit = data => {
    setEditedComment(data);
    setEditDialogOpen(true);
  };

  const handleChatIconClick = () => {
    if (localStorage.getItem('dataKey')) {
      localStorage.removeItem('dataKey');
    }
    if (isAuthenticated) {
      navigate(-1);
    } else {
      setCurrentComponent('login');
      dispatch(toggleModalLogin());

      setAuthModalOpen(true);
    }
  };

  const handlePinnedCommentClick = data => {
    setIsPinnedCommentDialogOpen(true);
    setSendPinnedData(data);
  };

  const handleClosePinnedCommentDialog = () => {
    setIsPinnedCommentDialogOpen(false);
  };

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);

    handleMobileMenuClose();
  };

  const handleAuthModalOpen = () => {
    dispatch(toggleModalLogin());
    setAuthModalOpen(true);
  };

  //close authentication modal dialog

  const handleAuthModalClose = () => {
    setAuthModalOpen(false);
  };

  const handleLogout = () => {
    setCurrentComponent('logout');
    handleAuthModalOpen();
    handleMenuClose();
  };

  const handleLogin = () => {
    setCurrentComponent('login');
    handleAuthModalOpen();
    handleMenuClose();
  };
  const [editPass, setEditPass] = useState<any>();
  const handleCloseEditDialog = dialog => {
    setEditDialogOpen(false);
  };

  const handleSave = newMessage => {
    setEditPass(newMessage);
  };

  const navigateToEvent = event => {
    if (event?.eventType === 'ACTIVITY') navigate(`/activity/${event?.id}`);
    else if (event?.eventType === 'PETITION') navigate(`/petition/${event?.id}`);
  };

  const handleReportClose = data => {
    setReportDialogOpen(false);
    fetchConversations(activityDetails);
    if (data === 'Reported Successfully') toast.success('Reported Successfully');
  };

  const selectChatMethod = data => {
    setChangeChat(true);
    setChangePrimary(prevState => !prevState);
    handleActivity(data);
  };

  const handleButtonFeatureClicked = () => { };
  const memoizedChatCard = useMemo(() => <ChatCardleftnav selectChat={selectChatMethod} />, []);

  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = useState<null | HTMLElement>(null);
  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);
  const menuId = 'primary-search-menu';
  const drawerWidthSm = 248;
  const handleButtonClicked = () => { };
  const renderMenu = (
    <Menu
      sx={{ top: '37px' }}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      PaperProps={{
        elevation: 0,
        sx: {
          overflow: 'visible',
          filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
          mt: 1.5,
          '& .MuiAvatar-root': {
            width: 32,
            height: 32,
            ml: -0.5,
            mr: 1,
          },
          '&:before': {
            content: '""',
            display: 'block',
            position: 'absolute',
            top: 0,
            right: 14,
            width: 10,
            height: 10,
            bgcolor: 'background.paper',
            transform: 'translateY(-50%) rotate(45deg)',
            zIndex: 0,
          },
        },
      }}
      id={menuId}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      {isAuthenticated
        ? [
            <MenuItem key="logout" onClick={handleLogout}>
              <ListItemIcon>
                <Logout />
              </ListItemIcon>
              Logout
            </MenuItem>,
          ]
        : [
            <MenuItem key="login" onClick={handleLogin}>
              <ListItemIcon>
                <LoginIcon />
              </ListItemIcon>
              Login
            </MenuItem>,
          ]}
    </Menu>
  );
  

  const allConversation = useAppSelector(state => state.leftPanelReducer.chatResponse);
  const loading = useAppSelector(state => state.leftPanelReducer.loading);

  const handleButtonMapClicked = () => { };

  const mobileMenuId = 'menu-mobile';

  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      {[
        <MenuItem key="theme-toggler">
          <IconButton onClick={toggleThemeHandler} size="large" aria-label="dark-mode-toggler" color="inherit">
            <Badge>{theme.palette.mode === 'dark' ? <LightModeIcon /> : <DarkModeToggleIcon />}</Badge>
          </IconButton>
        </MenuItem>,
  
        <MenuItem key="chat">
          <IconButton size="large" aria-label="chat" color="inherit" onClick={handleChatIconClick}>
            <Badge>{theme.palette.mode === 'dark' ? <QuestionAnswerRoundedIcon /> : <SquareChatIcon />}</Badge>
          </IconButton>
        </MenuItem>,
  
        isAuthenticated ? (
          <MenuItem key="logout" onClick={handleLogout}>
            <IconButton size="large" color="inherit">
              <LogoutIcon />
            </IconButton>
          </MenuItem>
        ) : (
          <MenuItem key="login" onClick={handleLogin}>
            <IconButton size="large" color="inherit">
              <LoginIcon />
            </IconButton>
          </MenuItem>
        ),
      ]}
    </Menu>
  );
  

  const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',

    alignItems: 'center',

    padding: theme.spacing(0, 1),

    ...theme.mixins.toolbar,

    justifyContent: 'flex-end',
  }));

  const handleSearchClear = () => { };
  const { connectWebSocket, disconnect } = useContext(WebSocketContext);

  const handleDrawerHeaderClick = () => {
    disconnect();
  };

  useEffect(() => {
    connectWebSocket();

    const handleVisibilityChange = () => {
      if (document.visibilityState === 'visible') {
        connectWebSocket();
      }
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);
    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, [connectWebSocket, disconnect]);

  const drawer = (
    <Box
      sx={{
        border: 'none',
        maxHeight: windowHeight,
        overflowY: 'scroll',
        overflowX: 'hidden',
        background:
          theme.palette.mode === 'dark'
            ? '#1F1F26'
            : 'transparent linear-gradient(182deg, #F7F5EF 0%, #F2F2FA 49%, #f1f1f8 100%) 0% 0% no-repeat padding-box;',

        maxWidth: { sm: drawerWidthSm, lg: drawerWidth, md: drawerWidthSm },
        backdropFilter: 'blur(10px)',
        minHeight: '100%',
        '&::-webkit-scrollbar': {
          display: 'none',
        },
        '&::-webkit-scrollbar-thumb': { backgroundColor: 'secondary.contrastText', height: '10px' },
        '&::-webkit-scrollbar-track': {
          backgroundColor: 'transparent',
        },
      }}
    >
      <DrawerHeader sx={{ justifyContent: 'center', height: '79px', border: 'none' }}>
        <Link to={'/'} style={{ paddingLeft: '0px', paddingRight: '50px' }} onClick={handleDrawerHeaderClick}>
          <AmyIcon sx={{ width: '159px', height: '100px' }} />
        </Link>
      </DrawerHeader>
      {memoizedChatCard}
    </Box>
  );

  const mobileDrawer = (
    <Box
      sx={{
        border: 'none',
        height: windowHeight,
        overflowY: 'scroll',
        overflowX: 'hidden',
        background:
          theme.palette.mode === 'dark'
            ? theme.palette.background.default
            : 'transparent linear-gradient(133deg, #F7F5EF 35%, #F2F2FA 58%, #EFEFF5 100%) 0% 0% no-repeat padding-box',

        maxWidth: { sm: drawerWidthSm, lg: drawerWidth, md: drawerWidthSm },
        backdropFilter: 'blur(10px)',

        '&::-webkit-scrollbar': {
          display: 'none',
        },
        '&::-webkit-scrollbar-thumb': { backgroundColor: 'secondary.contrastText', height: '10px' },
        '&::-webkit-scrollbar-track': {
          backgroundColor: 'transparent',
        },
      }}
    >
      <DrawerHeader sx={{ justifyContent: 'center', height: '79px', border: 'none' }}>
        <Link to={'/'} style={{ paddingLeft: '0px', paddingRight: '50px' }} onClick={handleDrawerHeaderClick}>
          <AmyIcon sx={{ width: '159px', height: '100px' }} />
        </Link>
      </DrawerHeader>

      <SideNavigation
        category={category}
        subCategory={subCategory}
        onSubCategoryClick={handleSubCategoryClick}
        onhandleAccordionClick={handleAccordionClick}
        onButtonClicked={handleButtonClicked}
        onMapClicked={handleButtonMapClicked}
        toggleMapValue={toggleMapValue}
        setToggleMapValue={setToggleMapValue}
        onFeatureClicked={handleButtonFeatureClicked}
      />
      <Divider />
    </Box>
  );

  return (
    <Box
      sx={{
        display: 'flex',
        overflow: 'hidden',
      }}
    >
      <CssBaseline />

      <AppBar
        position="fixed"
        sx={{
          width: { sm: `calc(100% - ${drawerWidthSm}px)`, md: `calc(100% - ${drawerWidthSm}px)`, lg: `calc(100% - ${drawerWidth}px)` },

          ml: { sm: `${drawerWidthSm}px`, md: `${drawerWidthSm}px`, lg: `${drawerWidth}px` },
          boxShadow: 'none',
          background: isDarkMode ? '#1F1F26' : 'none',
        }}
      >
        <Toolbar
          className="amy-top-nav"
          sx={{
            backgroundColor: ' transparent linear-gradient(118deg, #F7F5EF 0%, #F2F2FA 49%, #EFEFF5 100%) 0% 0% no-repeat',

            border: 'none',
            justifyContent: 'space-between',

            backdropFilter: 'blur(10px)',
          }}
        >
          {/* <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: 'none' } }}
          >
            <MenuIcon /> */}
          {/* </IconButton> */}
          <>
            <PrimaryHeader changePrimary={changePrimary} />
          </>
        </Toolbar>
      </AppBar>

      {renderMobileMenu}

      {renderMenu}

      <Box
        component="nav"
        sx={{ width: { sm: drawerWidthSm, md: drawerWidthSm, lg: drawerWidth }, flexShrink: { sm: 0 } }}
        aria-label="mailbox folders"
      >
        <Drawer
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true,
          }}
          sx={{
            display: { xs: 'block', sm: 'none' },

            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidthSm },
          }}
        >
          {mobileDrawer}
        </Drawer>

        <Drawer
          variant="permanent"
          sx={{
            display: { xs: 'none', sm: 'block' },

            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: { sm: drawerWidthSm, md: drawerWidthSm, lg: drawerWidth } },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>

      <Box
        component="main"
        sx={{
          height: windowHeight,
          flexGrow: 1,
          p: 3,
          width: '0px',
          background: isDarkMode
            ? '#1F1F26'
            : ' transparent linear-gradient(172deg, #F7F5EF 0%, #F2F2FA 49%, #EFEFF5 100%) 0% 0% no-repeat padding-box;',

          border: 'none',
          '@media (max-width: 575px)': {
            p: 1.8,
          },
        }}
      >
        <Toolbar />

        {isListPage && memoizedChatCard}

        <div
          style={{
            display: (isInfoPage && isMobile) || isListPage ? 'none' : 'block',
            overflowY: getChatloading ? 'hidden' : 'auto',
            overflowX: 'hidden',
            height: windowHeight,
          }}
        >
          <div className="row">
            <div>
              <Typography variant="h6" noWrap component="div" gutterBottom fontWeight={600}>
                
                <Typography component="span">Conversations</Typography>
              </Typography>
            </div>

            <div
              style={{ display: isInfoPage || isListPage ? 'none' : 'block' }}
              className="col-sm-8  col-xs-8 col-md-8 col-lg-8 mob-chat "
            >
              <div
                className="col-12"
                style={{
                  display: 'flex',
                  flexDirection: window.innerWidth < 768 ? 'column' : 'row',
                  justifyContent: 'space-between',
                }}
              ></div>
              {getAllChatResponse?.chatDetails?.length === 0 && getAllChatResponse?.chatConversations?.length === 0 && !getChatloading && (
                <>
                  <div
                    className="col-12 chat-feed-wrapper d-flex justify-content-center align-items-center "
                    style={{
                      height: isMobile ? `calc(82vh)` : `calc(${windowHeight}px - 139px)`,
                      borderRadius: '15px',
                    }}
                  >
                    <img src={'https://images.reachamy.com/20241014072244_noChats.png'} width={300} />
                    <small className="mt-3" style={{ color: '#717375' }}>
                      {' '}
                      Join an event to be part of discussions!
                    </small>
                  </div>
                </>
              )}
              {allConversation?.chatDetails?.length > 0 && (
                <div
                  className="col-12 chat-feed-wrapper"
                  style={{
                    height: isMobile ? `calc(82vh)` : `calc(${windowHeight}px - 139px)`,
                    borderRadius: '15px',
                  }}
                >
                  <style>
                    {`::-webkit-scrollbar {width: 0; background: transparent; }
              ::-webkit-scrollbar-thumb { background: transparent;}`}
                  </style>

                  <WebSocketProvider>
                    {!getChatloading && (
                      <>
                        <ChatFeed
                          event={event}
                          openPinnedData={handlePinnedCommentClick}
                          reportBoxOpen={handleReport}
                          editBoxOpen={handleEdit}
                          activityData={activityDetails}
                          volunteerButtonClick={volunteerButtonClick}
                          editPass={editPass}
                          signPetiton={signPetiton}
                          changeChat={changeChat}
                        />
                      </>
                    )}
                    {getChatloading && <Skeleton variant="rounded" width={210} height={300} />}
                  </WebSocketProvider>
                </div>
              )}
            </div>

            {allConversation?.chatDetails?.length > 0 && (
              <div
                style={{ display: isMobile || isListPage ? 'none' : 'block' }}
                className="col-sm-4  col-xs-4 col-md-4 col-lg-4 side-bar-chat"
              >
                <div
                  className="col-12"
                  style={{
                    display: 'flex',
                    flexDirection: window.innerWidth < 768 ? 'column' : 'row',
                    justifyContent: 'space-between',
                    // marginTop: '40px',
                  }}
                ></div>
                <div
                  className="col-12"
                  style={{
                    height: `calc(${windowHeight}px - 140px)`,
                    overflow: activityDetails?.eventType === 'PETITION' ? 'hidden' : 'hidden scroll',
                    borderRadius: '15px',
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                >
                  <style>
                    {`::-webkit-scrollbar {width: 0; background: transparent; }
              ::-webkit-scrollbar-thumb { background: transparent;}`}
                  </style>
                  {!activityDetails && <Skeleton variant="rectangular" width={250} height={550} />}
                  <List
                    sx={{
                      flex: 1,
                      width: '100%',
                      maxWidth: '100%',
                      backgroundColor: 'secondary.dark',
                      paddingTop: '0',
                      paddingBottom: '0',
                    }}
                  >
                    {activityDetails?.eventType === 'ACTIVITY' &&
                      activityDetails?.id != undefined &&
                      activity != null &&
                      activity.recurringId === null && (
                        <ActivityChatSideBar
                          activityResponseParent={activity}
                          volunteerButtonClick={volunteerButtonClick}
                          isSubmitClicked={isSubmitClicked}
                          onVolunteerButtonClick={handleVolunteerButtonClick}
                          onIsSubmitClicked={handleIsSubmitClicked}
                        />
                      )}

                    {activityDetails?.eventType === 'ACTIVITY' &&
                      activityDetails?.id != undefined &&
                      activity != null &&
                      activity.recurringId !== null && (
                        <ActivityReccuringChatSideBar
                          activityResponseParent={activity}
                          volunteerButtonClick={volunteerButtonClick}
                          isSubmitClicked={isSubmitClicked}
                          onVolunteerButtonClick={handleVolunteerButtonClick}
                          onIsSubmitClicked={handleIsSubmitClicked}
                        />
                      )}

                    {activityDetails?.eventType === 'PETITION' && (
                      <PetitionDetailSideBar petitionResponse={petition} onPetitionClicked={handlePetitionClicked} />
                    )}
                  </List>
                </div>
              </div>
            )}
          </div>
        </div>

        <div style={{ display: !isInfoPage || isListPage ? 'none' : 'flex', height: `calc(${windowHeight}px - 87px)` }}>
          <List sx={{ flex: 1, width: '100%', maxWidth: '100%', backgroundColor: 'secondary.dark', px: 3, py: 4 }}>
            <Paper
              elevation={0}
              style={{ borderBottom: '1px solid #e0e0e0', paddingBottom: '16px', marginBottom: '16px', cursor: 'pointer' }}
              onClick={() => {
                navigateToEvent(activityDetails);
              }}
            >
              <Box display="flex" flexDirection="column" alignItems="center" p={2}>
                <Avatar
                  alt={activityDetails?.title}
                  src={activityDetails?.eventProfileLink}
                  sx={{ width: 80, height: 80, marginBottom: 1 }}
                />
                <Typography variant="h6" component="div" fontWeight="bold" fontSize="18px" textAlign="center">
                    <Typography component="span"> {activityDetails?.title}</Typography>         
                </Typography>
              </Box>
            </Paper>
            {!activity && <Skeleton variant="rectangular" width={50} height={300} />}
            {activityDetails?.eventType === 'ACTIVITY' && activityDetails?.id != undefined && activity && (
              <ActivityChatSideBar
                activityResponseParent={activity}
                volunteerButtonClick={volunteerButtonClick}
                isSubmitClicked={isSubmitClicked}
                onVolunteerButtonClick={handleVolunteerButtonClick}
                onIsSubmitClicked={handleIsSubmitClicked}
              />
            )}

            {activityDetails?.eventType === 'PETITION' && (
              <PetitionDetailSideBar petitionResponse={petition} onPetitionClicked={handlePetitionClicked} />
            )}
          </List>
        </div>
      </Box>

      <AuthorisationModal
        onClose={handleAuthModalClose}
        authModalOpen={authModalOpen}
        fullScreen={fullScreen}
        handleAuthModalClose={handleAuthModalClose}
        currentComponent={currentComponent}
        setCurrentComponent={setCurrentComponent}
        handleSearchClear={handleSearchClear}
      />

      <PinnedCommentDialog
        open={isPinnedCommentDialogOpen}
        onClose={handleClosePinnedCommentDialog}
        pinnedData={sendPinnedData}
        activity={activityDetails}
      />

      <ReportChatDialog open={reportDialogOpen} onClose={handleReportClose} handleData={chat} />

      <ChatEdit open={editDialogOpen} onClose={handleCloseEditDialog} handleChat={editedComment} onSave={handleSave} />
    </Box>
  );
};

export default ChatCard;
