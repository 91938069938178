import * as React from 'react';
import Paper from '@mui/material/Paper';
import InputBase from '@mui/material/InputBase';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';

interface CommonSearchBarProps {
  placeholder?: string;
  value?: any;
  onChange?: (event: any) => void;
  clear?: () => void;
}

const CommonSearchBar = ({ placeholder = 'Search Google Maps', value, onChange, clear }: any) => {
  return (
    <Paper component="form" sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', width: '100%', boxShadow: 'none' }}>
      <InputBase
        value={value}
        sx={{ ml: 1, flex: 1 }}
        placeholder={placeholder}
        inputProps={{ 'aria-label': 'search google maps' }}
        onChange={e => onChange(e.target.value)}
      />
      <IconButton type="button" sx={{ p: '10px' }} aria-label="search" onClick={() => clear()} >
        <CloseIcon />
      </IconButton>
    </Paper>
  );
};
export default CommonSearchBar;
