import SideNavigation from 'app/components/layout/side-navigaion/side-navigation';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import AppBar from '@mui/material/AppBar';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import PrimaryHeader from 'app/components/layout/header/primary-header';
import EventFilterTabs from 'app/components/layout/event-filter-tabs/event-filter-tabs';
import { fetchProfile } from 'app/shared/reducers/profile';
import Card from '@mui/material/Card';
import {
  Autocomplete,
  Button,
  Chip,
  FormControl,
  Grid,
  InputAdornment,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { StyledTextarea } from 'app/components/common/text-area/styled-textare';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import { Carousel } from 'react-responsive-carousel';
import { singleImageType } from 'app/shared/reducers/image-source';
import DeleteIcon from '@mui/icons-material/Delete';
import { useDropzone } from 'react-dropzone';
import axios from 'axios';
import { apiEndPoints } from 'app/utils/data/constants/api-end-points';
import * as yup from 'yup';
import { useFormik } from 'formik';
import countryCode from 'app/utils/data/json/country-code.json';
import PostSuccessModal from 'app/components/common/post-success-modal/post-success-modal';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import ArrowCircleLeftIcon from '@mui/icons-material/ArrowCircleLeft';
const drawerWidth = 315;
const drawerWidthSm = 248;
interface Props {
  window?: () => Window;
}

interface CountryCode {
  name: string;
  web_code: string;
  country_code: string;
  flag: string;
  phone_code: string;
  phone_number_length: number;
}
const validationSchema = yup.object().shape({
  organizationName: yup
    .string()
    .trim()
    .required('Organization name is required')
    .min(2, 'Organization name must be between 2 and 50 characters.')
    .max(50, 'Organization name must be between 2 and 50 characters.'),
  bio: yup
    .string()
    .trim()
    .required('Bio is required')
    .min(2, 'Bio must be between 2 and 200 characters.')
    .max(200, 'Bio must be between 2 and 200 characters.'),
  address: yup.string(),
  website: yup
    .string()
    .trim()
    .matches(/^(https:\/\/|www\.)[^\s/$.?#].[^\s]*$/i, 'Website must start with "https://" or "www." and be a valid URL'),
  fundingLinks: yup.string(),
  countryCode: yup.string().test({
    name: 'countryCode',
    test: function (value) {
      const phoneNumber = this.resolve(yup.ref('phoneNumber')) as string;
      return !phoneNumber || phoneNumber.trim() === '' || (!!value && value.trim() !== '');
    },
    message: 'Country code is required ',
  }),
  phoneCode: yup.string(),
  phoneNumber: yup.string().test({
    name: 'phoneNumber',
    test: function (value) {
      const countryCodeValue = this.resolve(yup.ref('countryCode')) as string;
  
      if (countryCodeValue) {
        const countryCodesArray = countryCode as CountryCode[]; 
        const selectedCountry = countryCodesArray.find(cc => cc.phone_code === countryCodeValue);
  
        if (!selectedCountry) {
          return this.createError({
            path: 'countryCode',
            message: 'Invalid country code.',
          });
        }
        const allowedLengths = Array.isArray(selectedCountry.phone_number_length)
          ? selectedCountry.phone_number_length
          : [selectedCountry.phone_number_length];
  
        if (value && !allowedLengths.includes(value.length)) {
          return this.createError({
            path: 'phoneNumber',
            message: `Phone number must be valid.`,
          });
        }
  
        if (!value) {
          return this.createError({
            path: 'phoneNumber',
            message: 'Phone number is required when country code is specified.',
          });
        }
      }
  
      return true; 
    },
  }),
    
  logo: yup.number().required('logo is required').min(1, 'logo is required.'),
});
const CreateOrganization = (props: Props) => {
  const countryCodes = countryCode as CountryCode[];
  const account = useAppSelector(state => state.authentication.account);
  const formik = useFormik({
    initialValues: {
      organizationName: '',
      bio: '',
      address: '',
      website: '',
      fundingLinks: '',
      countryCode: '',
      phoneNumber: '',
      phoneCode: '',
      logo: 0,
    },
    validationSchema: validationSchema,
    onSubmit: values => {
      submitOrganization(values);
    },
  });
  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const { id } = useParams();
  const navigate = useNavigate();
  const memoizedImage = useMemo(
    () => (
      <Box style={{ paddingLeft: '0px', paddingRight: '50px', cursor: 'pointer' }} onClick={() => navigate(-1)}>
        <img src="content/images/logos/reachamy-logo.png" alt="reach amy logo" />
      </Box>
    ),
    [navigate]
  );
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleCancelClick = () => {
    // Call the resetForm method to reset Formik values
    formik.resetForm();
    setLocalMultimedia([]);
    setMultiMedia([]);
    setTotalImages(0);
    setTotalSize(0);
    setUploadedPdfFiles([]);
    setSubmitPdfFiles([]);
    setFiles([]);
    setLinks([]);
  };
  const [category, setCategory] = useState('');
  const [subCategory, setSubCategory] = useState('');
  const eventType = useAppSelector(state => state.homeActivity.eventType);
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const isDarkMode = theme.palette.mode === 'dark';
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [toggleValue, setToggleValue] = useState(false);
  const [toggleMapValue, setToggleMapValue] = useState(false);
  const myProfileId = useAppSelector(state => state.authentication.account.id);
  const [searchKeyword, setSearchKeyword] = React.useState<string>('');
  const profileData = useAppSelector(state => state.profileReducer.profile);
  const profileDetails = useAppSelector(state => state.profileReducer.profileDetails);
  const [localMultimedia, setLocalMultimedia] = useState<singleImageType[]>([]);
  const [files, setFiles] = useState<File[]>([]);
  const [multiMedia, setMultiMedia] = useState<{ id: number }[]>([]);
  const [totalImages, setTotalImages] = useState(0);
  const [totalSize, setTotalSize] = useState(0);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [selectedImageIndex, setSelectedImageIndex] = useState(0);
  const [responseUrl, setResponseUrl] = useState<string>('');
  const [open, setOpen] = useState(false);

  const acceptingFiles = {
    'image/jpeg': [],
    'image/png': [],
    'image/jpg': [],
  };
  const onDrop = useCallback(
    (acceptedFiles, fileRejections) => {
      setFiles(acceptedFiles);
    },
    [setFiles]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: acceptingFiles,
    multiple: false,
  });

  useEffect(() => {
    if (files !== undefined) {
      const sendFiles = async () => {
        try {
          for (const file of files) {
            const formData = new FormData();
            formData.append('file', file);
            const response = await axios.post<any>(apiEndPoints.imageUpload, formData, {
              headers: { 'Content-Type': 'multipart/form-data' },
            });
            if (response.status === 201) {
              setMultiMedia(state => {
                const newState = [...state, { id: response.data?.id }];
                return newState;
              });
              setTimeout(() => {
                setLocalMultimedia(state => [...state, response.data]);
              }, 200);
            }
          }
        } catch (error) {
          console.log(error);
        }
      };
      sendFiles();
    }
  }, [files]);

  const handleImageClear = id => {
    const newLocalMultiMedia = [...localMultimedia];
    const deletedImageIndex = newLocalMultiMedia.findIndex(item => item.id === id);
    if (deletedImageIndex !== -1) {
      newLocalMultiMedia.splice(deletedImageIndex, 1);
      setLocalMultimedia(newLocalMultiMedia);
      const newMultiMedia = multiMedia.filter(media => media.id !== id);
      setMultiMedia(newMultiMedia);
      setTotalImages(newMultiMedia.length);
      if (newMultiMedia.length > 0) {
        setSelectedImageIndex(0);
      }
    }
  };

  useEffect(() => {
    if (multiMedia?.length > 0) {
      formik.setFieldValue('logo', multiMedia[0]?.id);
    } else {
      formik.setFieldValue('logo', 0);
    }
  }, [multiMedia]);
  useEffect(() => {
    const fetchData = async () => {
      if (id !== undefined) {
        try {
          await dispatch(fetchProfile(id));
        } catch (error) {
          console.log(error);
        }
      } else {
        if (myProfileId) {
          try {
            await dispatch(fetchProfile(myProfileId));
          } catch (error) {
            console.log(error);
          }
        }
      }
    };
    fetchData();
  }, [id, myProfileId]);

  const container = window !== undefined ? () => window().document.body : undefined;
  const [uploadedPdfFiles, setUploadedPdfFiles] = useState([]);
  const [submitPdfFiles, setSubmitPdfFiles] = useState([]);
  const [PdfFiles, setPdfFiles] = useState<File[]>([]);
  const hiddenFileInput = useRef(null);
  const handleClick = event => {
    hiddenFileInput.current.click();
  };
  const handleFileInputChange = e => {
    const selectedFiles = e.target.files;
    setPdfFiles(selectedFiles);
    const newFiles = Array.from(selectedFiles);
    const updatedFiles = new DataTransfer();

    for (let i = 0; i < selectedFiles.length; i++) {
      if (!uploadedPdfFiles.some(file => file.name === selectedFiles[i].name)) {
        updatedFiles.items.add(selectedFiles[i]);
      }
    }

    setUploadedPdfFiles([...uploadedPdfFiles, ...newFiles]);
    e.target.files = null;
  };
  const handleDeleteFile = file => {
    const updatedFiles = uploadedPdfFiles.filter(existingFile => existingFile !== file);
    const updatedFileList = new DataTransfer();
    for (let i = 0; i < updatedFiles.length; i++) {
      updatedFileList.items.add(updatedFiles[i]);
    }
    setUploadedPdfFiles(updatedFiles);
  };
  useEffect(() => {
    console.log(uploadedPdfFiles, 'PdfFiles');
    if (uploadedPdfFiles !== undefined) {
      const sendFiles = async () => {
        try {
          for (const file of uploadedPdfFiles) {
            const formData = new FormData();
            formData.append('file', file);
            const response = await axios.post<any>(apiEndPoints.imageUpload, formData, {
              headers: { 'Content-Type': 'multipart/form-data' },
            });
            if (response.status === 201) {
              setSubmitPdfFiles(state => {
                const newState = [...state, { id: response.data?.id }];
                return newState;
              });
            }
          }
        } catch (error) {
          console.log(error);
        }
      };

      sendFiles();
    }
  }, [uploadedPdfFiles]);
  const [links, setLinks] = useState([]);
  const [newLink, setNewLink] = useState('');
  const [isLinkValid, setIsLinkValid] = useState(true);
  const [linkErrorMessage, setLinkErrorMessage] = useState('');
  const handleLinkChange = event => {
    const newLinkValue = event.target.value;
    setNewLink(newLinkValue);

    // Validate the link and update state accordingly
    const isValid = isValidSocialLink(newLinkValue);
    setIsLinkValid(isValid);

    // Set error message if the link is invalid
    if (!isValid) {
      setLinkErrorMessage('Invalid social link');
    } else {
      setLinkErrorMessage('');
    }
  };

  const isValidSocialLink = link => {
    const regex = /^https?:\/\/.+/i;
    return regex.test(link);
  };
  const handleLinkAdd = () => {
    if (newLink.trim() !== '') {
      setLinks([...links, newLink]);
      setNewLink('');
    }
  };
  const handleLinkDelete = index => {
    const updatedLinks = [...links];
    updatedLinks.splice(index, 1);
    setLinks(updatedLinks);
  };
  const handleInputKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter' || event.key === 'Tab') {
      if (isValidSocialLink(newLink)) {
        setLinks([...links, newLink]);
        setNewLink('');
      }
      event.preventDefault();
    }
  };

  const handleLinkBlur = () => {
    if (isValidSocialLink(newLink)) {
      setLinks([...links, newLink]);
      setNewLink('');
    }
  };

  const [formValues, setFormValues] = useState({
    organizationName: '',
  });
  const [errors, setErrors] = useState({});

  const handleSubmit = event => {
    event.preventDefault();
  };

  const submitOrganization = async values => {
    const mappedLinks = links.map(link => ({
      socialMediaLink: link,
    }));
    let payload = {
      userId: account?.id,
      organizationName: values?.organizationName,
      bio: values?.bio,
      logo: multiMedia.length > 0 ? multiMedia[0]?.id : 0,
      address: values?.address,
      documents: submitPdfFiles,
      website: values?.website,
      socialLinks: mappedLinks,
      fundingLinks: values?.fundingLinks,
      countryCode: values?.countryCode,
      phoneNumber: values?.phoneNumber,
      phoneCode: values?.countryCode,
    };

    try {
      const response = await axios.post<any>('api/create/organization', payload);
      if (response.status === 201 || response.status === 200) {
        setResponseUrl(response.data.link);
        setOpen(true);
        setLocalMultimedia([]);
        setMultiMedia([]);
        setTotalImages(0);
        setTotalSize(0);
        setUploadedPdfFiles([]);
        setSubmitPdfFiles([]);
        setFiles([]);
        setLinks([]);
        formik.resetForm();
      }
    } catch (error) {
      console.log(error);
    }
  };
  const handleModalClose = () => {
    setOpen(false);
    navigate('/profile');
  };
  const handleGoBack = () => {
    navigate(-1);
  };

  return (
    <Box
      sx={{
        display: 'flex',
        background: isDarkMode ? '#1F1F26' : 'transparent linear-gradient(118deg, #F7F5EF 0%, #F2F2FA 49%, #EFEFF5 100%) 0% 0% no-repeat',
        p: 0,
        border: 'none',
        backdropFilter: 'blur(10px)',
        height: '100vh',
        overflow: 'hidden ',
      }}
    >
      <CssBaseline />

      <Box
        className="inside-bg"
        component="main"
        sx={{
          flexGrow: 1,
          p: 3,
          width: {
            sm: `calc(100% - ${drawerWidthSm} px)`,
            md: `calc(100% - ${drawerWidthSm} px)`,
            lg: `calc(100% - ${drawerWidth} px)`,
          },
          background: isDarkMode
            ? '#1F1F26'
            : ' transparent linear-gradient(172deg, #F7F5EF 0%, #F2F2FA 49%, #EFEFF5 100%) 0% 0% no-repeat padding-box',
          border: 'none',
          backdropFilter: 'blur(10px)',
        }}
      >
        <div className="col-3">
          <ArrowCircleLeftIcon
            onClick={() => handleGoBack()}
            sx={{
              cursor: 'pointer',
              color: isDarkMode ? '#FFCE00' : '#775fff',
              borderRadius: '50%',
              fontSize: '25px',
              marginLeft: '10px',
            }}
          />
        </div>
        <div className="container-fluid  pt-0 d-flex  justify-content-center " style={{ background: isDarkMode ? '#1F1F26' : '' }}>
          <div className="col-sm-7 col-12" style={{ overflow: 'hidden scroll', height: '92vh' }}>
            <style>
              {`::-webkit-scrollbar {width: 0; background: transparent; }
                              ::-webkit-scrollbar-thumb { background: transparent;}`}
            </style>
            <form onSubmit={formik.handleSubmit}>
              <style>
                {`.css-92qcvy{
                       height:155px 
                    };
               `}
              </style>

              <div className="col-9">
                <Typography variant="h4" sx={{ color: 'secondary.main' }}>
                  Create an organization
                </Typography>
              </div>
              <div className="col-12 mt-2 mb-4">
                <TextField
                  sx={{
                    height: '40px',
                    '& .MuiInputBase-input::placeholder': {
                      fontSize: '0.75rem',
                      color: isDarkMode ? '#dddddd' : '#676E7B',
                    },
                    '& .MuiInputBase-input': {
                      fontSize: '0.75rem',
                    },
                    '&:focus-within,&:hover': {
                      border: 'none',
                    },
                    '& fieldset': {
                      border: isDarkMode ? '1px solid #BFBFBF' : 'null',
                    },
                  }}
                  className="w-100 "
                  size="small"
                  name="organizationName"
                  value={formik.values.organizationName}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  placeholder="Name of organization *"
                  autoComplete="off"
                />
                {formik.touched.organizationName && formik.errors.organizationName && (
                  <Typography variant="caption" className="ms-2" sx={{ color: 'error.main' }}>
                    {String(formik.errors.organizationName)}
                  </Typography>
                )}
              </div>
              <div className="row mt-2 mb-3">
                <div className="col-12 col-md-7">
                  <StyledTextarea
                    sx={{
                      fontSize: '0.75rem',
                      padding: '15px 15px 15px 15px',
                      '::placeholder': {
                        color: isDarkMode ? 'grey' : '#dadada',
                      },
                    }}
                    name="bio"
                    value={formik.values.bio}
                    onBlur={formik.handleBlur}
                    onChange={e => {
                      const typedText = e.target.value;
                      formik.setFieldValue('bio', typedText);
                      if (typedText === '<p><br></p>') {
                      } else if (typedText.length > 0) {
                      }
                    }}
                    onPaste={e => {
                      e.preventDefault();

                      const pastedText = e.clipboardData.getData('text/plain');

                      const currentDetails = formik.values.bio || '';
                      const newDetails = currentDetails + pastedText;
                      formik.setFieldValue('bio', newDetails);
                      if (newDetails.trim() === '') {
                        formik.setFieldError('bio', 'Bio is required');
                      } else {
                        formik.setFieldError('bio', '');
                      }
                    }}
                    placeholder="Bio *"
                    autoComplete="off"
                  />
                  {formik.touched.bio && formik.errors.bio && (
                    <Typography variant="caption" className="ms-2" sx={{ color: 'error.main' }}>
                      {String(formik.errors.bio)}
                    </Typography>
                  )}
                </div>
                <div className="col-12 col-md-5">
                  <div
                    style={{
                      height: '150px',

                      backgroundColor: `${({ theme }) => (theme.palette.mode === 'dark' ? theme.palette.secondary.dark : '#fff')}`,
                    }}
                  >
                    {localMultimedia?.length > 0 ? (
                      <div
                        style={{
                          height: '150px',
                          borderRadius: '15px',
                          backgroundColor: theme.palette.mode === 'dark' ? '' : '#FFFF',
                          cursor: 'pointer',
                          padding: '2px',
                        }}
                      >
                        {localMultimedia?.length > 0 &&
                          localMultimedia?.map((item, index) => (
                            <Box sx={{ position: 'relative', width: '100%', height: '100%' }} key={index}>
                              <DeleteIcon
                                sx={{
                                  color: 'error.main',
                                  background: '#fafafa',
                                  position: 'absolute',
                                  top: 5,
                                  right: 40,
                                  cursor: 'pointer',
                                  borderRadius: '40%',
                                }}
                                onClick={() => handleImageClear(item.id)}
                              />
                              <div>
                                <img
                                  src={item.link ? item.link : item.url || 'content/images/no-img.jpg'}
                                  alt={`slide${index + 1}`}
                                  style={{ height: '130px', objectFit: 'cover' }}
                                />
                              </div>
                            </Box>
                          ))}
                      </div>
                    ) : (
                      <Box>
                        <div {...getRootProps()}>
                          <input {...getInputProps()} name={'logo'} />
                          <div
                            style={{
                              height: '150px',
                              borderRadius: '15px',
                              border: '1px solid #bfbfbf',
                              backgroundColor: theme.palette.mode === 'dark' ? theme.palette.secondary.dark : '#FFFF',
                              cursor: 'pointer',
                            }}
                          >
                            <div style={{ padding: '14%' }}>
                              <div style={{ textAlign: 'center' }}>
                                <FileUploadOutlinedIcon />
                              </div>

                              <Typography
                                sx={{
                                  color: theme.textColor.mainHeading,
                                  fontSize: '14px',
                                  // fontWeight: theme.p1.semiBold.fontWeight,
                                }}
                              >
                                Drop your image files here, or <span style={{ color: 'primary.main' }}>browse</span>
                                (Accepted file formats: JPG, JPEG, PNG)
                              </Typography>
                            </div>
                          </div>
                        </div>
                      </Box>
                    )}
                    {formik.touched.logo && formik.errors.logo && (
                      <Typography variant="caption" className="ms-2 mb-3" sx={{ color: 'error.main' }}>
                        {String(formik.errors.logo)}
                      </Typography>
                    )}
                  </div>
                </div>
              </div>
              <div className="col-12 mt-1 mb-3">
                <StyledTextarea
                  sx={{
                    fontSize: '.75rem',
                    padding: '15px 15px 15px 15px',
                    '::placeholder': {
                      color: isDarkMode ? 'grey' : '#dadada',
                    },
                  }}
                  name="address"
                  value={formik.values.address}
                  onChange={e => {
                    const typedText = e.target.value;
                    formik.setFieldValue('address', typedText);
                    if (typedText === '<p><br></p>') {
                    } else if (typedText.length > 0) {
                    }
                  }}
                  onPaste={e => {
                    e.preventDefault();
                    const pastedText = e.clipboardData.getData('text/plain');

                    const currentDetails = formik.values.address || '';
                    const newDetails = currentDetails + pastedText;
                    formik.setFieldValue('address', newDetails);
                  }}
                  placeholder="Address"
                  autoComplete="off"
                />
              </div>

              <div className="col-12 mt-1 mb-3">
                <TextField
                  className="w-100"
                  placeholder="Upload a file"
                  onClick={handleClick}
                  size="small"
                  sx={{
                    height: '40px',
                    '& .MuiInputBase-input::placeholder': {
                      fontSize: '0.75rem',
                    },
                    '& .MuiInputBase-input': {
                      fontSize: '0.75rem',
                      height: 'fit-content',
                    },
                    '& fieldset': {
                      border: isDarkMode ? '1px solid #BFBFBF' : 'null',
                    },
                  }}
                  InputProps={{
                    readOnly: true,
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton aria-label="add" onClick={e => handleClick}>
                          <AddCircleOutlineOutlinedIcon sx={{ color: isDarkMode ? '#FFCE00' : '#674BFF' }} />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />

                <input
                  type="file"
                  multiple
                  onChange={handleFileInputChange}
                  ref={hiddenFileInput}
                  accept=".pdf"
                  style={{ display: 'none' }}
                />
                {/* <div >
                  {uploadedPdfFiles.map((file, index) => (
                    <div
                      key={file.name}
                      className="uploaded-file"

                    >
                      <span>{file.name}</span>
                      <span className="delete-icon" role="button" onClick={() => handleDeleteFile(file)}>
                        <DeleteIcon />
                      </span>
                    </div>
                  ))}
                </div> */}
                <div style={{ display: 'flex', justifyContent: 'start', gap: '5px', flexWrap: 'wrap', marginTop: '10px' }}>
                  {uploadedPdfFiles.map((file, index) => (
                    <Chip key={index} label={file.name} onDelete={() => handleDeleteFile(file)} />
                  ))}
                </div>
              </div>
              <div className="col-12 mt-2 mb-3">
                <TextField
                  name="website"
                  value={formik.values.website}
                  onChange={formik.handleChange}
                  onKeyPress={e => {
                    if (e.key === 'Enter') {
                      e.preventDefault();
                    }
                  }}
                  sx={{
                    height: '40px',
                    '& .MuiInputBase-input::placeholder': {
                      fontSize: '0.75rem',
                    },
                    '& .MuiInputBase-input': {
                      fontSize: '0.75rem',
                      height: 'fit-content',
                    },
                    '& fieldset': {
                      border: isDarkMode ? '1px solid #BFBFBF' : 'null',
                    },
                  }}
                  size="small"
                  className="w-100"
                  placeholder="Website"
                />
                {formik.touched.website && formik.errors.website && (
                  <Typography variant="caption" className="ms-2" sx={{ color: 'error.main' }}>
                    {formik.errors.website}
                  </Typography>
                )}
              </div>
              <div className="col-12 mt-2 mb-3">
                <TextField
                  className="w-100"
                  placeholder="Social links"
                  size="small"
                  sx={{
                    height: '40px',
                    '& .MuiInputBase-input::placeholder': {
                      fontSize: '0.75rem',
                    },
                    '& .MuiInputBase-input': {
                      fontSize: '0.75rem',
                      height: 'fit-content',
                    },
                    '& fieldset': {
                      border: isDarkMode ? '1px solid #BFBFBF' : 'null',
                    },
                  }}
                  value={newLink}
                  onChange={handleLinkChange}
                  onBlur={handleLinkBlur}
                  onKeyDown={handleInputKeyDown}
                  error={!isLinkValid} // Set error prop based on validation
                  helperText={linkErrorMessage}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton aria-label="add" onClick={e => handleLinkChange}>
                          <AddCircleOutlineOutlinedIcon sx={{ color: isDarkMode ? '#FFCE00' : '#674BFF' }} />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                <div style={{ display: 'flex', justifyContent: 'start', gap: '5px', flexWrap: 'wrap', marginTop: '10px' }}>
                  {links.map((link, index) => (
                    <Chip key={index} label={link} onDelete={() => handleLinkDelete(index)} />
                  ))}
                </div>
              </div>
              <div className="row mt-2 mb-3">
                <div className="col-3 ">
                  <Autocomplete
                    options={countryCodes}
                    sx={{
                      width: '180px',
                      paddingRight: '10px',
                      '& .MuiAutocomplete-popupIndicator': {
                        // marginRight: '80px',
                      },
                      '& .MuiAutocomplete-listbox': {
                        maxHeight: 200,
                        overflow: 'auto',
                      },
                      '& .MuiAutocomplete-option': {
                        padding: '10px',
                      },
                      '& .MuiOutlinedInput-root': {
                        fontSize: '.75rem',
                        height: '40px',
                      },
                      '& fieldset': {
                        border: isDarkMode ? '1px solid #BFBFBF' : 'null',
                      },
                    }}
                    getOptionLabel={option => `${option.phone_code}`}
                    filterOptions={(options, { inputValue }) => {
                      // Use inputValue to filter the options and get the exact match
                      return options.filter(option => option.phone_code.toLowerCase().includes(inputValue.toLowerCase()));
                    }}
                    onChange={(e, value) => {
                      formik.setFieldValue('countryCode', value?.phone_code || '');
                      if(!value?.phone_code){
                      formik.setFieldValue('phoneNumber', ''); 
                      
                      }
                      
                    }}
                    value={countryCodes.find(option => option.phone_code === formik.values.countryCode) || null}
                    renderInput={params => <TextField name={`countryCode`} {...params} label="" size="small" placeholder="Country code" />}
                    renderOption={(props, option) => (
                      <Box component="li" {...props}>
                        {option.phone_code} {option.name}
                      </Box>
                    )}
                  />

                  {formik.touched.countryCode && formik.errors.countryCode && (
                    <Typography variant="caption" className="ms-2" sx={{ color: 'error.main' }}>
                      {String(formik.errors.countryCode)}
                    </Typography>
                  )}
                </div>
                <div className="col-9 ">
                  <TextField
                    sx={{
                      height: '40px',
                      '& .MuiInputBase-input::placeholder': {
                        fontSize: '0.75rem',
                      },
                      '& .MuiInputBase-input': {
                        fontSize: '0.75rem',
                        height: 'fit-content',
                      },
                      '& fieldset': {
                        border: isDarkMode ? '1px solid #BFBFBF' : 'null',
                      },
                    }}
                    className="w-100"
                    name="phoneNumber"
                    value={formik.values.phoneNumber}
                    onChange={e => {
                      const numericValue = e.target.value.replace(/\D/g, ''); // Remove non-numeric characters
                      formik.handleChange({
                        target: {
                          name: 'phoneNumber',
                          value: numericValue,
                        },
                      });
                    }}
                    onBlur={formik.handleBlur}
                    size="small"
                    placeholder="Phone number"
                    inputProps={{
                      maxLength: 15,
                      inputMode: 'numeric',
                      pattern: '[0-9]{1,15}',
                    }}
                    type="text"
                  />
                  {formik.touched.phoneNumber && formik.errors.phoneNumber && (
                    <Typography variant="caption" className="ms-2" sx={{ color: 'error.main' }}>
                      {formik.errors.phoneNumber}
                    </Typography>
                  )}
                </div>
              </div>

              <div className="col-12 me-2 mb-3 d-flex justify-content-end">
                <div className="col-4 col-sm-2 me-3">
                  <Button
                    variant="contained"
                    fullWidth
                    className="me-2 "
                    onClick={handleCancelClick}
                    sx={{
                      backgroundColor: 'secondary.contrastText',
                      textTransform: 'none',
                      borderRadius: '15px',
                      color: 'secondary.dark',
                      height: '40px',
                      '&:hover': {
                        backgroundColor: 'secondary.contrastText',
                        color: 'secondary.dark',
                      },
                    }}
                  >
                    Cancel
                  </Button>
                </div>
                <div className="col-4 col-sm-2">
                  {' '}
                  <Button
                    type="submit"
                    variant="contained"
                    fullWidth
                    sx={{
                      backgroundColor: 'primary.light',
                      textTransform: 'none',
                      borderRadius: '15px',
                      color: '#ffffff',
                      height: '40px',
                      '&:hover': {
                        backgroundColor: 'primary.light',
                        color: 'secondary.dark',
                      },
                    }}
                    disabled={formik.isSubmitting || !formik.isValid}
                  >
                    Submit
                  </Button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </Box>

      <PostSuccessModal
        handleModalClose={handleModalClose}
        fullScreen={fullScreen}
        open={open}
        onClose={handleModalClose}
        responseUrl={responseUrl}
        message="Your organization has been created succesfully"
      />
    </Box>
  );
};
export default CreateOrganization;
