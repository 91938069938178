import { Box, Grid, Typography } from '@mui/material';
import React from 'react';
import SectionHead from '../general/section-head';
import BarChartIcon from '@mui/icons-material/BarChart';
import DoughnutCard from './doughnut-card';
import { convertDateToDesiredFormat } from 'app/utils/common-utils/common-utils';

type Props = {
  item: any;
  dateStarts: string | number;
  dateEnds: string | number;
};
export default function HomeSection({ item, dateStarts, dateEnds }: Props) {
  return (
    <Box py={2}>
      <SectionHead title={item?.categoryName} moreLink={item?.categoryId} icon="-" />
      <Grid container spacing={2}>
        <Grid display="flex" flexDirection="column" item xs={12} md={3.5}>
          <Box bgcolor="#a291ff" flex="1" borderRadius={2} overflow="hidden" borderBottom={2.5} borderColor="#b7aafd">
            <Box gap={1} display="flex" alignItems="center" p={1.7} bgcolor="#674BFF">
              <Box display="flex" alignItems="center" justifyContent="center" height={35} width={35} bgcolor="#a291ff" borderRadius={1}>
                <BarChartIcon sx={{ color: '#fff' }} />
              </Box>
              <Box>
                <Typography color="#fff" fontSize="0.9rem" fontWeight="bold">
                  Activity Count
                </Typography>
                <Typography variant="body2" color="#fff" fontSize="0.8rem">
                  Total count of activity conducted
                </Typography>
              </Box>
            </Box>
            <Box px={1.7} py={2.2}>
              <Typography variant="body2" fontWeight="600" color="#fff">
                {convertDateToDesiredFormat(dateStarts)} - {convertDateToDesiredFormat(dateEnds)}
              </Typography>
              <Typography mt={1} variant="h1" component="h5" fontWeight="bold" color="#fff">
                {item.totalActivityCount}
              </Typography>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} md={8.5} display="flex">
          <Grid container spacing={1.5} direction="row">
            {item.acheievedGoalDetails?.length > 0 &&
              item.acheievedGoalDetails?.map((goal, index) => (
                <Grid display="flex" item xs={12} md={6} lg={3}  key={goal.goalId ?? index} >
                  <DoughnutCard                  
                    name={goal?.goalName}
                    achieved={goal?.achievedGoalCount}
                    target={goal?.targetGoalCount}
                    currencyCode={goal?.currencyCode}
                  />
                </Grid>
              ))}
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
}
