import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import {
  Avatar,
  Badge,
  Box,
  Card,
  CardActions,
  CardContent,
  Dialog,
  Grid,
  IconButton,
  ListItem,
  ListItemText,
  Popover,
  Stack,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
  AvatarGroup,
} from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import UpVoteOutlined from 'app/components/icons/activity-card/up-vote-outlined';
import SquareChatIcon from 'app/components/icons/header-icons/square-chat-icon';
import GenericModal from 'app/components/common/modals/generic-modal';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import HTMLReactParser from 'html-react-parser';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import MoreHorizOutlinedIcon from '@mui/icons-material/MoreHorizOutlined';
import { Carousel } from 'react-responsive-carousel';
import BookmarkBorderIcon from '@mui/icons-material/BookmarkBorder';
import OrganiserCrownIcon from 'app/components/icons/activity-card/organiser-crown-icon';
import ModeratorStarIcon from 'app/components/icons/activity-card/moderator-star-icon';
import {
  convertDateToDay,
  convertDateToYear,
  generateGMapsLinkCords,
  generateGMapsLinkPlace,
  getFormattedTime,
  getInitials,
  shareOnSocialMedia,
  timestampToHoursAgo,
  truncateString,
} from 'app/utils/common-utils/common-utils';
import RemoteEventIcon from 'app/components/icons/activity-card/remote-event';
import ShareIcon from 'app/components/icons/activity-card/share-icon';
import CloseIcon from '@mui/icons-material/Close';
import BookmarkIcon from '@mui/icons-material/Bookmark';
import AuthorisationModal from 'app/components/common/authorization-modal/authorization-modal';
import { fetchBookmarkedActivity, isRemoteChildActivity } from 'app/shared/reducers/bookmark-activity';
import UpVoteFilled from 'app/components/icons/activity-card/up-vote-filled';
import { fetchLikes } from 'app/shared/reducers/activity-like';
import LeaveActivityDialog from 'app/components/ui/activity-details-section/activity-resource/activity-leave';
import ReportActivityDialog from 'app/components/ui/activity-details-section/activity-resource/activity-report';
import FacebookIcon from '@mui/icons-material/Facebook';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import { appConstants } from 'app/utils/data/constants/constants';
import ParticipantList from 'app/components/ui/participants-list/participants-list';
import GrayTwitterIcon from 'app/components/icons/login-page-icons/gray-twitter-icon';
import BlueTwitterIcon from 'app/components/icons/login-page-icons/blue-twitter-icon';
import LanguageIcon from '@mui/icons-material/Language';
import './recurring-details-card.scss';
import { toggleModalLogin } from 'app/shared/reducers/authentication';
import DownloadIcon from '@mui/icons-material/Download';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import ActivityDetailsReviewSection from '../../activity-details-section/activity-details-review-section/activity-details-review-section';
import RecurringRangeModal from '../recurring-date-range/recurring-range-modal';
import { fetchActivityShareToken, trackActivityShare } from 'app/shared/reducers/activity-home';
import toast from 'react-hot-toast';
import { updateFromDetailsPage } from 'app/shared/reducers/activity-filter';
import axios from 'axios';
import FeatureDialog from 'app/components/common/alert-dialog/feature-dialog';
import Moment from 'react-moment';
import ImageGrid from '../../grid-layout/ImageGrid';
import { GallerySlide } from '../../activity-details-section/activity-details-card/activity-details-card';
import Lightbox from 'yet-another-react-lightbox';
import Video from 'yet-another-react-lightbox/plugins/video';
import 'yet-another-react-lightbox/styles.css';
import VisibilityIcon from '@mui/icons-material/Visibility';
import EmojiPeopleIcon from '@mui/icons-material/EmojiPeople';
import PeopleOutlineIcon from '@mui/icons-material/PeopleOutline';

interface ActivityCardDetailsProps {
  activityResponse: any;
  childrensResponse: any;
  handleDeleteClick: (id: string) => void;
  onBookmarkSuccess: () => void;
  onLikeSuccess: () => void;
  onParticipantBlocked: (val: any) => void;
  onDataUpdate: any;
  onDataChange: any;
  dateResponse: any;
}

const ActivityRecurringDetails = ({
  activityResponse,
  childrensResponse,
  onBookmarkSuccess,
  onLikeSuccess,
  handleDeleteClick,
  onParticipantBlocked,
  onDataUpdate,
  onDataChange,
  dateResponse,
}: ActivityCardDetailsProps) => {
  const theme = useTheme();
  const isDarkMode = theme.palette.mode === 'dark';
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const isAuthenticated = useAppSelector(state => state.authentication.isAuthenticated);
  const { filter, eventType } = useAppSelector(state => state.filters.RequestBody);
  const [authModalOpen, setAuthModalOpen] = React.useState(false);
  const [currentComponent, setCurrentComponent] = React.useState(' ');
  const isSmallerScreen = useMediaQuery(theme.breakpoints.down(400));
  const isMdScreen = useMediaQuery(theme.breakpoints.down(992));
  const isSmallDevice = useMediaQuery(theme.breakpoints.down('sm'));

  const [openRemoteModal, setOpenRemoteModal] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const [confirmDelete, setConfirmDelete] = React.useState<boolean>(false);
  const [isBookmarked, setIsBookmarked] = useState<boolean>(false);
  const [isLiked, setIsLiked] = useState<boolean>(false);
  const [places, setPlaces] = useState<{ place: string; address: string }>({ place: '', address: '' });
  const [leaveDialogOpen, setLeaveDialogOpen] = useState(false);
  const [reportDialogOpen, setReportDialogOpen] = useState(false);
  const [socialAnchorEl, setSocialAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const socialShare = Boolean(socialAnchorEl);
  const socialShareId = socialShare ? 'simple-popover-social' : undefined;
  const [likeCountNumber, setLikeCountNumber] = useState<number>(0);
  const [calDialogOpen, setCalDialogOpen] = useState(false);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  const menuItemId = open ? 'simple-popover' : undefined;
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const handleButtonClick = id => {
    navigate(`/activity/${id}`);
  };

  const handleImageError = event => {
    event.target.src = 'content/images/no-img.jpg';
  };

  const handleRemoteActivityClose = () => {
    setOpenRemoteModal(false);
  };

  const handleYesClick = () => {
    handleClose();
    handleDeleteClick(activityResponse?.id);
  };

  const handleBookmarkClick = async () => {
    if (!isAuthenticated) {
      setCurrentComponent('login');
      dispatch(toggleModalLogin());

      setAuthModalOpen(true);
      return;
    }
    setIsBookmarked(s => !s);

    try {
      const actionResult = await dispatch(fetchBookmarkedActivity(Number(activityResponse?.id)));
      if (fetchBookmarkedActivity.fulfilled.match(actionResult)) {
        onBookmarkSuccess();
        // dispatch(filterActivities({ filter, eventType, page: 0 }));
      }
    } catch (error) {
      console.error('Bookmarking failed:', error);

      setIsBookmarked(s => !s);
    }
  };

  const handleLikeClick = async () => {
    if (!isAuthenticated) {
      setCurrentComponent('login');
      dispatch(toggleModalLogin());

      setAuthModalOpen(true);
      return;
    }
    setIsLiked(s => !s);
    if (isLiked) {
      setLikeCountNumber(likeCountNumber - 1);
    } else {
      setLikeCountNumber(likeCountNumber + 1);
    }

    try {
      const actionResult = await dispatch(fetchLikes(Number(activityResponse?.id)));
      if (fetchLikes.fulfilled.match(actionResult)) {
        onLikeSuccess();
        // dispatch(filterActivities({ filter, eventType, page: 0 }));
      }
    } catch (error) {
      console.error('Liking failed:', error);

      setIsLiked(s => !s);
    }
  };

  const modalActionButtons = [
    { label: 'Yes', onClick: handleYesClick },

    { label: 'No', onClick: () => setConfirmDelete(false) },
  ];

  const postedByStyle = {
    font: 'normal normal 600 16px/22px Manrope',
    color: theme.textColor.mainHeading,
  };

  const postedByXsStyle = {
    font: 'normal normal 600 15px/18px Manrope',
    color: theme.textColor.mainHeading,
  };

  const location = {
    font: 'normal normal normal 14px/23px Manrope',
    color: isDarkMode ? '#BFBFBF' : '#5A5F67',
  };

  const locationXs = {
    font: 'normal normal normal 14px/21px Manrope',
    color: isDarkMode ? '#BFBFBF' : '#5A5F67',
  };
  const [remote, setRemote] = useState<boolean>(false);
  useEffect(() => {
    const fetchData = async () => {
      if (activityResponse?.id) {
        try {
          const res = await dispatch(isRemoteChildActivity({ id: activityResponse?.id, recurringId: 0 }));
          setRemote(res.payload);
        } catch (error) {
          console.error('Error fetching remote activity:', error);
        }
      }
    };

    fetchData();
  }, [activityResponse?.id]);
  const handleRemoteActivityButton = () => {
    if (isAuthenticated) {
      if (activityResponse?.online && activityResponse?.participant && (remote || activityResponse?.hasPermission)) {
        setOpenRemoteModal(s => !s);
      }
    } else {
      setCurrentComponent('login');
      dispatch(toggleModalLogin());

      setAuthModalOpen(true);
    }
  };

  const handleAuthModalClose: (event: {}, reason: 'backdropClick' | 'escapeKeyDown') => void = () => {
    setAuthModalOpen(false);
  };

  useEffect(() => {
    if (activityResponse?.bookMark !== undefined) {
      setIsBookmarked(activityResponse.bookMark);
    }
  }, [activityResponse?.bookMark]);

  useEffect(() => {
    dispatch(updateFromDetailsPage(true));
  }, [dispatch]);

  useEffect(() => {
    if (activityResponse?.liked !== undefined) {
      setIsLiked(activityResponse?.liked);
    }
  }, [activityResponse?.liked]);

  const handleLeave = () => {
    handleClose();
    setLeaveDialogOpen(true);
  };

  const handleReport = () => {
    handleClose();
    setReportDialogOpen(true);
  };

  const handleShareButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setSocialAnchorEl(event.currentTarget);
  };

  const handleSocialShareClose = () => {
    setSocialAnchorEl(null);
  };

  useEffect(() => {
    if (activityResponse?.likeCount !== undefined) {
      setLikeCountNumber(activityResponse?.likeCount);
    }
  }, [activityResponse?.likeCount]);
  const sentParameters = async data => {
    if (!isAuthenticated) {
      setCurrentComponent('login');
      dispatch(toggleModalLogin());

      setAuthModalOpen(true);
    } else {
      localStorage.removeItem('chatnavigation');
      const { id, eventType } = data;
      const storageData = { id: activityResponse?.recurringId, eventType };
      localStorage.setItem('dataKey', JSON.stringify(storageData));
      navigate('/chat');
    }
  };

  useEffect(() => {
    if (isAuthenticated) {
      const params = new URLSearchParams(window.location.search);

      const socialToken = params.get('t');
      if (socialToken) {
        dispatch(trackActivityShare({ activityId: activityResponse?.id, token: socialToken }))
          .then(() => {
            const newSearch = new URLSearchParams(window.location.search);
            newSearch.delete('t');
            navigate(`?${newSearch.toString()}`, { replace: true });
          })
          .catch(() => { });
      }
    }
  }, [isAuthenticated]);

  const [toolTipText, setToolTipText] = useState<string | null>(null);

  useEffect(() => {
    const updatedToolTipText = !isAuthenticated
      ? null
      : isAuthenticated &&
        !activityResponse?.participant &&
        activityResponse?.activityStatus !== 'ENDED' &&
        activityResponse?.activityStatus !== 'COMPLETED'
        ? 'Be a volunteer to access the link'
        : !activityResponse?.hasPermission && activityResponse?.participant && !remote && activityResponse?.activityStatus === 'OPEN'
          ? 'Link will be published 30 mins before the activity starts'
          : activityResponse?.activityStatus === 'ENDED' || activityResponse?.activityStatus === 'COMPLETED'
            ? 'This activity has ended.'
            : null;

    setToolTipText(updatedToolTipText);
  }, [
    activityResponse?.hasPermission,
    activityResponse?.activityStatus,
    isAuthenticated,
    activityResponse?.id,
    remote,
    activityResponse?.participant,
  ]);

  const handleDownloadPDF = () => {
    const link = document.createElement('a');
    link.href = activityResponse?.pdfUrl;
    link.download = 'downloaded_file.pdf';
    link.target = '_blank';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  const handleCalender = () => {
    setCalDialogOpen(true);
  };
  const handleCloseModal = filteredDataId => {
    setCalDialogOpen(false);
    if (filteredDataId !== 0) onDataUpdate(filteredDataId);
  };
  const [isVisible, setisVisible] = useState(false);
  useEffect(() => {
    if (activityResponse?.hasPermission) {
      setisVisible(true);
    }
    if (activityResponse?.participant && !activityResponse?.hasPermission) {
      setisVisible(true);
    }
    if (!activityResponse?.hasPermission && !activityResponse?.reported) {
      setisVisible(true);
    }
    if (!activityResponse?.hasPermission && activityResponse?.reported) {
      setisVisible(true);
    }
  }, [activityResponse?.hasPermission, activityResponse?.activityStatus, activityResponse?.reported]);

  const getShareToken = (socialName, content, activityId, type, URL, shareMessage) => {
    console.log(socialName, content, activityId, type, URL, shareMessage, 'socialName, URL, shareMessage');

    dispatch(fetchActivityShareToken({ activityId, type }))
      .then(result => {
        const token = result.payload.token;

        if (token) {
          const utmSource = socialName;
          const utmCampaign = 'Reachamy';
          const utmContent = content.replace(/\s/g, '');

          const newURL = `${URL}?utm_source=${utmSource}&utm_campaign=${utmCampaign}&utm_content=${utmContent}&t=${token}`;

          shareOnSocialMedia(socialName, newURL, shareMessage);
        }
      })
      .catch(err => {
        console.error('Error fetching data:', err);
        toast.error('Something went wrong');
      });
  };
  const [confirmFeature, setConfirmFeature] = React.useState<boolean>(false);
  const [payload, setPayload] = React.useState<any>();
  const [passId, setPassId] = React.useState<any>();

  const handleFeatureClick = (event: React.MouseEvent<HTMLButtonElement>, data: any) => {
    setPassId(data?.id);
    handleGetCatalogue(1);
  };

  const [paymentLoading, setPaymentLoading] = useState(false);
  const handlePayment = async () => {
    setPaymentLoading(true);
    let passData = {
      eventId: passId,
      eventType: 1,
      quantity: 1,
      productId: payload?.productId,
    };

    localStorage.setItem('passEventId', passData?.eventId);
    localStorage.setItem('passEventType', JSON.stringify(passData?.eventType));

    try {
      const response = await axios.post<any>('api/payment-service/checkout', passData);
      if (response.status === 201 || response.status === 200) {
        setPaymentLoading(false);
        const checkoutUrl = response?.data?.checkoutUrl;
        if (checkoutUrl) {
          window.location.href = checkoutUrl; // Open checkout URL in current tab
        } else {
          console.error('Checkout URL not found.');
        }
      }
    } catch (error) {
      setPaymentLoading(false);
      console.log(error);
    }
  };
  const handleGetCatalogue = async id => {
    try {
      const response = await axios.get<any>(`api/payment-service/product-catalogue?eventType=${id}`);
      if (response.status === 201 || response.status === 200) {
        setPayload(response.data);
        setConfirmFeature(true);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const handleDialogClose = id => {
    setLeaveDialogOpen(false);
    onDataChange(id);
  };
  const [index, setIndex] = useState(-1);
  const handleLightBoxClick = (index: number) => setIndex(index);
  const handleLightBoxClose = () => setIndex(-1);

  const slides: GallerySlide[] = activityResponse?.eventGallery?.map(img => {
    if (img.type === 'videos') {
      return {
        type: 'video',
        src: img?.link,
        thumbnail: img?.thumbnail,
        title: '',
        sources: [{ src: img?.link, type: 'video/mp4' }],
      };
    } else {
      return {
        type: 'image',
        src: img?.link,
        thumbnail: img?.link,
        title: '',
      };
    }
  });
  const handleRemote = async () => {
    const body = {
      activityId: activityResponse?.id,
    };
    const response = await axios.post<any>(`/api/activity/announce-remote-arrival`, body);
  };
  return (
    <div>
      {activityResponse && Object.keys(activityResponse).length > 0 ? (
        <Card
          sx={{ minWidth: '100% ', backgroundColor: 'secondary.dark', boxShadow: 'none', borderRadius: '20px' }}
          onError={handleImageError}
        >
          <>
            <div style={{ height: '430px', position: 'relative', borderRadius: '15px', overflow: 'hidden' }}>
              {activityResponse?.featured && (
                <div
                  style={{
                    position: 'absolute',
                    top: '0',
                    left: '0',
                    background: '#674BFF',
                    color: '#FFFF',
                    padding: '5px 10px',
                    borderRadius: '0 0 15px 0',
                    zIndex: '999',
                    fontSize: '12px',
                  }}
                >
                  <span style={{ fontWeight: 'bold' }}>Featured</span>
                </div>
              )}
              <ImageGrid onImageClick={handleLightBoxClick}>
                {activityResponse?.eventGallery?.map((item, index) => (
                  <div
                    key={item.id || index}
                    className={`img_container ${item.type === 'videos' ? 'video-grid' : ''}`}
                  >
                    <img
                      alt="ig"
                      src={item.type === 'videos' ? item?.thumbnail : item?.link}
                    />
                  </div>
                ))}
              </ImageGrid>

              <div
                className="legend d-flex justify-content-between align-items-center paddingLeft: '23px'"
                style={{
                  position: 'absolute',
                  background: 'rgba(0, 0, 0, 0.3)',
                  right: 0,
                  left: 0,
                  bottom: 0,
                  height: '40px',
                  paddingLeft: '23px',
                }}
              >
                <div>
                  <span className="d-flex  align-items-center " style={{ position: 'relative', left: '15px' }}>
                    {activityResponse?.eventParticipantCount > 0 && (
                      <>
                        <AvatarGroup max={3} className="av-size" style={{ height: '20px', width: '20px', fontSize: 12 }}>
                          {activityResponse?.participants.slice(0, 3).map((item): any => (
                            <Avatar
                              key={item?.id}
                              src={item?.profilePic}
                              alt={`${item?.profilePic}` ? '' : getInitials(item?.name)}
                              style={{
                                height: '20px',
                                width: '20px',
                                zIndex: '2',
                                fontSize: item?.profilePic ? 'default' : '12px',
                              }}
                            >
                              {getInitials(item?.name)}
                            </Avatar>
                          ))}
                        </AvatarGroup>

                        <p
                          style={{
                            color: '#ffff',
                            marginRight: '10px',
                            marginLeft: '10px',
                            fontSize: '12px',
                            marginBottom: '0px',
                          }}
                        >
                          {activityResponse?.eventParticipantCount}
                        </p>
                        <Box
                          sx={{
                            width: '5px',
                            height: '5px',
                            borderRadius: '50%',
                            backgroundColor: '#ffff',
                          }}
                        />
                      </>
                    )}
                    {activityResponse?.ageLimit !== null && !activityResponse?.lostPet && (
                      <p
                        style={{ color: '#ffff', marginLeft: '10px', fontSize: '12px', marginBottom: '0px' }}
                      >{`age limit ${activityResponse?.ageLimit}+`}</p>
                    )}
                  </span>
                </div>

                <div>
                  {isBookmarked ? (
                    <BookmarkIcon
                      sx={{
                        color: '#674BFF',
                        fontSize: '24px',
                        marginRight: '16px',
                        cursor: 'pointer',
                      }}
                      onClick={handleBookmarkClick}
                    />
                  ) : (
                    <BookmarkBorderIcon
                      sx={{
                        color: '#ffff',
                        fontSize: '24px',
                        marginRight: '16px',
                        cursor: 'pointer',
                      }}
                      onClick={handleBookmarkClick}
                    />
                  )}
                </div>
              </div>
            </div>
            <Lightbox
              carousel={{ finite: true }}
              open={index >= 0}
              close={handleLightBoxClose}
              slides={slides}
              plugins={[Video]}
              index={index}
            />
          </>

          <div
            style={{
              paddingLeft: isSmallerScreen ? '8px' : '16px',
              paddingRight: isSmallerScreen ? '8px' : '16px',
              paddingTop: isSmallerScreen ? '8px' : '1px',
              paddingBottom: isSmallerScreen ? '8px' : '1px',
            }}
          >
            <div className="row d-flex justify-content-between ">
              <Box className="mob-loc" display="flex" justifyContent="space-between" alignItems="flex-start" pt={1.6} flexDirection="row">
                <Box display="flex" justifyContent="space-between" alignItems="center" gap={1} marginBottom={1}>
                  <Avatar
                    onClick={() =>
                      activityResponse?.postAsOrganisation
                        ? navigate(`/organization/${activityResponse.organizationId}`)
                        : navigate(`/profile/${activityResponse?.createdBy}`)
                    }
                    alt={`${activityResponse?.postedByProfileImage}` ? '' : getInitials(activityResponse?.postedBy)}
                    src={activityResponse?.postedByProfileImage}
                    sx={{
                      width: '30px',
                      height: '29px',
                      cursor: 'pointer',
                      fontSize: activityResponse?.postedByProfileImage ? 'default' : '12px',
                    }}
                  >
                    {' '}
                    {getInitials(activityResponse?.postedBy)}
                  </Avatar>

                  <Box display="flex" gap={1.6} alignItems="baseline">
                    <Link
                      id="RouterNavLink"
                      style={{ fontWeight: 600, width: '100%', wordBreak: 'break-word' }}
                      to={
                        activityResponse?.postAsOrganisation
                          ? `/organization/${activityResponse.organizationId}`
                          : `/profile/${activityResponse?.createdBy}`
                      }
                    >
                      {isSmallDevice && activityResponse?.postedBy.length > 36
                        ? truncateString(activityResponse?.postedBy, 15)
                        : activityResponse?.postedBy}

                      <Box ml={0.6} display="inline-flex" component="span" gap={0.5} alignItems="center">
                        <Typography
                          component="span"
                          className="text-lowercase"
                          sx={{ font: 'normal normal normal 13px/21px Manrope', color: '#9FA1A4' }}
                        >
                          <Moment fromNow>{activityResponse?.createdAt}</Moment>
                        </Typography>
                        {!activityResponse?.makeThisPrivateActivity && (
                          <Tooltip title="This post is visible to anyone on Reach AMY">
                            <span style={{ cursor: 'default', color: 'rgb(159, 161, 164)', display: 'inline-flex', alignItems: 'center' }}>
                              <LanguageIcon />
                            </span>
                          </Tooltip>
                        )}

                      </Box>
                    </Link>
                  </Box>
                </Box>
                <Box className="mob-date" display="flex" flexDirection="column" alignItems="flex-end">
                  <Box
                    aria-label="notifications"
                    sx={{
                      padding: 'unset',
                      display: 'block',
                      cursor: 'pointer',
                      '&:hover': {
                        backgroundColor: 'inherit',
                      },
                    }}
                    onClick={handleRemoteActivityButton}
                  >
                    {activityResponse?.online ? (
                      <Tooltip title={toolTipText}>
                        <Box display="flex" alignItems="center">
                          <RemoteEventIcon sx={{ fontSize: '16px' }} />
                          <small
                            style={{
                              overflow: 'hidden',
                              whiteSpace: 'nowrap',
                              // textOverflow: 'ellipsis',
                              width: '90px',
                              marginLeft: '3px',
                              ...(isMdScreen ? locationXs : location),
                            }}
                          >
                            Remote event
                          </small>
                        </Box>
                      </Tooltip>
                    ) : (
                      <Link
                        id="RouterNavLink"
                        style={{ display: 'flex', alignItems: 'center' }}
                        className="text-decoration-none"
                        target="_blank"
                        rel="noopener noreferrer"
                        to={
                          activityResponse?.eventPlace
                            ? generateGMapsLinkPlace(activityResponse?.eventPlace)
                            : activityResponse.latitude && activityResponse?.longitude
                              ? generateGMapsLinkCords(activityResponse?.latitude, activityResponse?.longitude)
                              : '#'
                        }
                      >
                        <LocationOnOutlinedIcon sx={{ color: isDarkMode ? '#BFBFBF' : '#5A5F67' }} />

                        {(places?.place || activityResponse?.eventPlace) && (
                          <Tooltip title={(places?.place || activityResponse?.eventPlace) ?? null}>
                            <small
                              style={{
                                overflow: 'hidden',
                                whiteSpace: 'nowrap',
                                textOverflow: 'ellipsis',
                                width: '79px',
                                ...(isMdScreen ? locationXs : location),
                              }}
                            >
                              {/* {activityResponse?.eventPlace?.length > 13
                                ? activityResponse?.eventPlace?.split(',')[0]?.length > 12
                                  ? `${activityResponse?.eventPlace?.split(',')[0]?.substring(0, 10)}...`
                                  : activityResponse?.eventPlace?.split(',')[0]
                                : activityResponse?.eventPlace} */}
                              {activityResponse?.eventPlace?.length > 13
                                ? activityResponse?.eventPlace?.split(',')[0]?.length > 12
                                  ? `${activityResponse?.city},${activityResponse?.country}`
                                  : `${activityResponse?.city},${activityResponse?.country}`
                                : activityResponse?.eventPlace}
                            </small>
                          </Tooltip>
                        )}
                      </Link>
                    )}
                  </Box>
                  <Box
                    component="span"
                    sx={{ font: '14px / 21px Manrope', color: isDarkMode ? '#FFCE00' : '#674BFF', width: 'max-content' }}
                  >
                    {!activityResponse?.lostPet ? (
                      <>
                        <CalendarMonthIcon
                          onClick={handleCalender}
                          sx={{ height: '24px', width: '25px', color: isDarkMode ? '#FFCE00' : '#674BFF', cursor: 'pointer' }}
                        />
                        <span className="mt-2">
                          {' '}
                          {`${convertDateToYear(activityResponse?.startDate)} ${getFormattedTime(
                            activityResponse?.activityStartTime
                          )} - ${getFormattedTime(activityResponse?.activityEndTime)}`}
                        </span>
                      </>
                    ) : (
                      'Lost on' + ` ${convertDateToYear(activityResponse.lostPet?.dateLostOn)} `
                    )}
                  </Box>
                </Box>
              </Box>

              <Box display="flex" alignItems="center" gap="6px" flexWrap="wrap">
                {activityResponse?.category?.id !== 19 && (
                  <Button
                    sx={{
                      fontSize: '13px',
                      borderRadius: '20px',
                      height: '28px',
                      whiteSpace: 'nowrap',
                      backgroundColor: theme.palette.mode === 'dark' ? '#3D3C52' : '#F6F7F8',
                      color: theme.palette.mode === 'dark' ? 'secondary.contrastText' : '#362691',
                      '&:hover': {
                        backgroundColor: theme.palette.mode === 'dark' ? '#3D3C52' : '#F6F7F8',
                        color: theme.palette.mode === 'dark' ? 'secondary.contrastText' : '#362691',
                      },
                    }}
                  >
                    {activityResponse.category?.category}
                  </Button>
                )}

                <Button
                  sx={{
                    fontSize: '13px',
                    borderRadius: '20px',
                    height: '28px',
                    whiteSpace: 'nowrap',
                    cursor: 'auto',
                    backgroundColor: theme.palette.mode === 'dark' ? '#3D3C52' : '#F6F7F8',
                    transition: 'background-color 0s ease',
                    color: theme.palette.mode === 'dark' ? 'secondary.contrastText' : '#362691',
                  }}
                >
                  Recurring
                </Button>
                {activityResponse?.lostPet != null && (
                  <Button
                    className="ms-2"
                    sx={{
                      fontSize: '13px',
                      borderRadius: '20px',
                      height: '28px',
                      whiteSpace: 'nowrap',
                      cursor: 'auto',
                      backgroundColor: theme.palette.mode === 'dark' ? '#3D3C52' : '#F6F7F8',
                      transition: 'background-color 0s ease',
                      color: theme.palette.mode === 'dark' ? 'secondary.contrastText' : '#362691',
                    }}
                  >
                    {activityResponse?.lostPet?.animal}
                  </Button>
                )}

                {activityResponse?.category?.id === 19 && activityResponse?.lostPet != null && (
                  <Button
                    className="btn-urg"
                    variant="contained"
                    sx={{
                      fontSize: '13px',
                      borderRadius: '20px',
                      height: '28px',
                      backgroundColor: theme.palette.mode === 'dark' ? '#3D3C52' : '#FF9494',
                      color: theme.palette.mode === 'dark' ? '#FF93A9' : '#710017',
                      boxShadow: 'none',
                      cursor: 'auto',
                      '&:hover': {
                        color: theme.palette.mode === 'dark' ? '#FF93A9' : '#710017',
                        backgroundColor: theme.palette.mode === 'dark' ? '#3D3C52' : '#FF9494',
                      },
                    }}
                  >
                    Urgent
                  </Button>
                )}
                {activityResponse?.makeAsUrgentNeed && !activityResponse?.lostPet && (
                  <Button
                    className="btn-urg"
                    variant="contained"
                    sx={{
                      fontSize: '13px',
                      borderRadius: '20px',
                      height: '28px',
                      backgroundColor: theme.palette.mode === 'dark' ? '#3D3C52' : '#FF9494',
                      color: theme.palette.mode === 'dark' ? '#FF93A9' : '#710017',
                      '&:hover': {
                        color: theme.palette.mode === 'dark' ? '#FF93A9' : '#710017',
                        backgroundColor: theme.palette.mode === 'dark' ? '#3D3C52' : '#FF9494',
                      },
                      boxShadow: 'none',
                    }}
                  >
                    Urgent
                  </Button>
                )}
              </Box>

              <div className="col-6"></div>

              <CardContent className="pb-0">
                <div
                  style={{
                    font: ' normal normal 600 24px/37px Manrope',
                    textAlign: 'left',
                    letterSpacing: 0,
                    cursor: 'pointer',
                  }}
                  onClick={() => {
                    handleButtonClick(activityResponse?.id);
                  }}
                >
                  {activityResponse?.title}
                </div>
                <div className="pt-4 row">
                  {activityResponse?.lostPet != null && (
                    <>
                      <div className="col-6">
                        <span className="d-inline-flex">
                          <p style={{ font: ' normal normal 600 14px/21px Manrope', marginBottom: '4px' }}>Name of the pet:&nbsp;</p>
                          <p style={{ color: 'rgb(90, 95, 103);', font: ' normal normal 300 14px/21px Manrope' }}>
                            {activityResponse?.lostPet?.nameOfThePet}
                          </p>
                        </span>
                      </div>
                      <div className="col-6 ">
                        <span className="d-inline-flex">
                          <p style={{ font: ' normal normal 600 14px/21px Manrope', marginBottom: '4px' }}>Color:&nbsp;</p>
                          <p style={{ color: 'rgb(90, 95, 103);', font: ' normal normal 300 14px/21px Manrope' }}>
                            {activityResponse?.lostPet?.color}
                          </p>
                        </span>
                      </div>
                      <div className="col-6">
                        <span className="d-inline-flex">
                          <p style={{ font: ' normal normal 600 14px/21px Manrope', marginBottom: '4px' }}>Phone number:&nbsp;</p>
                          <p style={{ color: 'rgb(90, 95, 103);', font: ' normal normal 300 14px/21px Manrope' }}>
                            {activityResponse?.lostPet?.countryCode}
                            {activityResponse?.lostPet?.phoneNumber}
                          </p>
                        </span>
                      </div>
                      {activityResponse?.lostPet?.breed && (
                        <div className="col-6 ">
                          <span className="d-inline-flex">
                            <p style={{ font: 'normal normal 600 14px/21px Manrope', marginBottom: '4px' }}>Breed: &nbsp;</p>
                            <p style={{ color: 'rgb(90, 95, 103);', font: 'normal normal 300 14px/21px Manrope' }}>
                              {activityResponse.lostPet.breed}
                            </p>
                          </span>
                        </div>
                      )}
                      {activityResponse?.lostPet?.currencyCode && (
                        <div className="col-6">
                          <span className="d-inline-flex">
                            <p style={{ font: 'normal normal 600 14px/21px Manrope', marginBottom: '4px' }}>Reward:&nbsp;</p>
                            <p style={{ color: 'rgb(90, 95, 103);', font: 'normal normal 300 14px/21px Manrope' }}>
                              {activityResponse.lostPet.currencyCode}&nbsp; {activityResponse.lostPet.reward}
                            </p>
                          </span>
                        </div>
                      )}
                      {activityResponse?.lostPet?.keyIdentifier && (
                        <div className="col-6">
                          <span className="d-inline-flex">
                            <p style={{ font: 'normal normal 600 14px/21px Manrope', marginBottom: '4px' }}>Key indentifier:&nbsp;</p>
                            <p style={{ color: 'rgb(90, 95, 103);', font: 'normal normal 300 14px/21px Manrope' }}>
                              {activityResponse.lostPet.keyIdentifier}
                            </p>
                          </span>
                        </div>
                      )}
                      <div className="col-6 ">
                        <span
                          onClick={handleDownloadPDF}
                          style={{ textDecoration: 'none', cursor: 'pointer', color: '#674BFF', display: 'inline-flex', fontSize: '14px' }}
                        >
                          <DownloadIcon />
                          <p> Download Poster</p>
                        </span>
                      </div>
                    </>
                  )}
                </div>
                <div
                  className="ellipsis-container"
                  onClick={() => handleButtonClick(activityResponse?.id)}
                  style={{ color: isDarkMode ? '#BFBFBF' : '#5A5F67', fontWeight: 'normal' }}
                >
                  {typeof activityResponse?.caption === 'string' && <span>{HTMLReactParser(activityResponse?.caption)}</span>}
                </div>
              </CardContent>

              <CardActions sx={{ marginBottom: '-23px' }}>
                <div className="row w-100">
                  <div className="col-7">
                    <div className=" d-flex">
                      <span className=" d-flex mt-3 mb-3 ms-2 me-2">
                        {isLiked ? (
                          <UpVoteFilled sx={{ cursor: 'pointer', fontSize: '19px' }} onClick={handleLikeClick} />
                        ) : (
                          <UpVoteOutlined sx={{ cursor: 'pointer', fontSize: '19px' }} onClick={handleLikeClick} />
                        )}
                        <p className="px-2 " style={{ fontSize: '14px' }}>
                          {likeCountNumber ?? 0}
                        </p>
                      </span>

                      <span className=" d-flex mt-3 mb-3 ms-2 me-2">
                        <SquareChatIcon
                          sx={{ cursor: 'pointer', fontSize: '19px', color: isDarkMode ? '#BFBFBF' : '#5A5F67' }}
                          onClick={() => sentParameters(activityResponse)}
                        />

                        <p className="px-1 px-md-2" style={{ fontSize: '14px' }}>
                          {activityResponse?.msgCount}
                        </p>
                      </span>

                      <span className=" d-flex mt-3 mb-3 ms-2 me-2">
                        <VisibilityIcon style={{ cursor: 'pointer', fontSize: '19px', color: '#676E7B' }} />
                        <p className="px-md-2 px-1" style={{ fontSize: '14px' }}>
                          {activityResponse?.activityViewCount}
                        </p>
                      </span>

                      {!activityResponse?.makeThisPrivateActivity && (
                        <span className=" d-flex mt-3 mb-3 ms-2 me-2" onClick={handleShareButtonClick}>
                          <ShareIcon sx={{ cursor: 'pointer', fontSize: '19px', color: isDarkMode ? '#BFBFBF' : '#5A5F67' }} />
                          <p className="px-md-2 px-1"></p>
                        </span>
                      )}
                      <Popover
                        id={socialShareId}
                        open={Boolean(socialAnchorEl)}
                        anchorEl={socialAnchorEl}
                        onClose={handleSocialShareClose}
                        anchorOrigin={{
                          vertical: 'bottom',
                          horizontal: 'center',
                        }}
                      >
                        <ListItem sx={{ display: 'flex', flexDirection: 'column' }}>
                          {theme.palette.mode === 'dark' ? (
                            <BlueTwitterIcon
                              sx={{ cursor: 'pointer' }}
                              onClick={() =>
                                getShareToken(
                                  'twitter',
                                  activityResponse?.title.slice(0, 10),
                                  activityResponse?.id,
                                  1,
                                  activityResponse?.eventURL,
                                  appConstants.SocialShareMessage
                                )
                              }
                            />
                          ) : (
                            <GrayTwitterIcon
                              sx={{ cursor: 'pointer' }}
                              onClick={() =>
                                getShareToken(
                                  'twitter',
                                  activityResponse?.title.slice(0, 10),
                                  activityResponse?.id,
                                  1,
                                  activityResponse?.eventURL,
                                  appConstants.SocialShareMessage
                                )
                              }
                            />
                          )}
                        </ListItem>
                        <ListItem sx={{ display: 'flex', flexDirection: 'column' }}>
                          <FacebookIcon
                            sx={{ color: theme.palette.mode === 'dark' ? '#1D9BF0' : '#676e7b', cursor: 'pointer' }}
                            onClick={() =>
                              getShareToken(
                                'facebook',
                                activityResponse?.title.slice(0, 10),
                                activityResponse?.id,
                                2,
                                activityResponse?.eventURL,
                                appConstants.SocialShareMessage
                              )
                            }
                          />
                        </ListItem>
                        <ListItem sx={{ display: 'flex', flexDirection: 'column' }}>
                          <LinkedInIcon
                            sx={{ color: theme.palette.mode === 'dark' ? '#1D9BF0' : '#676e7b', cursor: 'pointer' }}
                            onClick={() =>
                              getShareToken(
                                'linkedIn',
                                activityResponse?.title.slice(0, 10),
                                activityResponse?.id,
                                3,
                                activityResponse?.eventURL,
                                appConstants.SocialShareMessage
                              )
                            }
                          />
                        </ListItem>
                      </Popover>
                      {isAuthenticated && isVisible && (
                        <span className=" d-flex ms-0 mt-3 mb-3 me-4" onClick={handleClick}>
                          <MoreHorizOutlinedIcon
                            aria-describedby={menuItemId}
                            sx={{ cursor: 'pointer', fontSize: '19px', color: isDarkMode ? '#BFBFBF' : '#5A5F67' }}
                          />
                        </span>
                      )}

                      {activityResponse?.hasPermission === true && (
                        <Popover
                          id={menuItemId}
                          open={open}
                          anchorEl={anchorEl}
                          onClose={handleClose}
                          anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                          }}
                        >
                          <ListItem sx={{ display: 'flex', flexDirection: 'column', textAlign: 'left', alignItems: 'inherit' }}>
                            {activityResponse?.hasPermission === true && (
                              <>
                                {/* view edit activity option */}

                                {/* {activityResponse?.activityStatus === 'OPEN' && (
                                  <ListItemText sx={{ padding: 0 }}>
                                    <Link to={`/edit-activity/${activityResponse?.id}`} className="text-decoration-none">
                                      <Typography sx={{ p: 0, cursor: 'pointer', fontSize: '14px' }}>Edit activity</Typography>
                                    </Link>
                                  </ListItemText>
                                )} */}
                                {
                                  activityResponse?.activityStatus === 'OPEN' &&
                                  (
                                    <ListItemText sx={{ padding: 0 }}>
                                      <Link id="RouterNavLink" to={`/edit-activity/${activityResponse?.id}`} className="text-decoration-none">
                                        <Typography sx={{ p: 0, cursor: 'pointer', fontSize: '14px' }}>Edit activity</Typography>
                                      </Link>
                                    </ListItemText>
                                  )}

                                {/* edit activity option */}
                                {activityResponse?.activityStatus === 'OPEN' && (
                                  <ListItemText
                                    sx={{ padding: 0 }}
                                    onClick={() => {
                                      handleClose();

                                      setConfirmDelete(true);
                                    }}
                                  >
                                    <Typography sx={{ p: 0, cursor: 'pointer', fontSize: '14px' }}>Delete activity</Typography>
                                  </ListItemText>
                                )}
                                {activityResponse?.participants?.length === 1 &&
                                  activityResponse?.activityStatus !== 'ENDED' &&
                                  activityResponse?.activityStatus !== 'COMPLETED' &&
                                  activityResponse?.activityStatus !== 'OPEN' && (
                                    <ListItemText
                                      sx={{ padding: 0 }}
                                      onClick={() => {
                                        handleClose();

                                        setConfirmDelete(true);
                                      }}
                                    >
                                      <Typography sx={{ p: 0, cursor: 'pointer', fontSize: '14px' }}>Delete activity</Typography>
                                    </ListItemText>
                                  )}

                                {activityResponse?.permittedToFeatured === true && (
                                  <button
                                    style={{ padding: 0, background: 'none', border: 'none', cursor: 'pointer' }}
                                    onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                                      handleFeatureClick(event, activityResponse);
                                    }}
                                  >
                                    <ListItemText className="text-start">
                                      <Typography sx={{ p: 0, fontSize: '14px', cursor: 'pointer', color: isDarkMode ? '#fff' : '#000' }}>
                                        Feature activity
                                      </Typography>
                                    </ListItemText>
                                  </button>
                                )}
                              </>
                            )}
                          </ListItem>
                        </Popover>
                      )}
                      {!activityResponse?.hasPermission && (
                        <Popover
                          id={menuItemId}
                          open={open}
                          anchorEl={anchorEl}
                          onClose={handleClose}
                          anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                          }}
                        >
                          <ListItem sx={{ display: 'flex', flexDirection: 'column', textAlign: 'left', alignItems: 'inherit' }}>
                            {activityResponse?.participant &&
                              !activityResponse?.hasPermission &&
                              activityResponse?.activityStatus === 'OPEN' && (
                                <ListItemText sx={{ padding: 0 }} onClick={() => handleLeave()}>
                                  <Typography sx={{ p: 0, cursor: 'pointer', fontSize: '14px' }}>Leave activity</Typography>
                                </ListItemText>
                              )}

                            {/* view report activity option  */}

                            {!activityResponse?.hasPermission && !activityResponse?.reported ? (
                              <ListItemText sx={{ padding: 0 }} onClick={() => handleReport()}>
                                <Typography sx={{ p: 0, cursor: 'pointer', fontSize: '14px' }}>Report activity</Typography>
                              </ListItemText>
                            ) : !activityResponse?.hasPermission && activityResponse?.reported ? (
                              <ListItemText sx={{ padding: 0 }}>
                                <Typography sx={{ p: 0, cursor: 'pointer', fontSize: '14px' }}>Reported</Typography>
                              </ListItemText>
                            ) : null}

                            {activityResponse?.permittedToFeatured === true && (
                              <button
                                style={{ padding: 0, background: 'none', border: 'none', cursor: 'pointer' }}
                                onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                                  handleFeatureClick(event, activityResponse);
                                }}
                              >
                                <ListItemText className="text-start">
                                  <Typography sx={{ p: 0, fontSize: '14px', cursor: 'pointer', color: isDarkMode ? '#fff' : '#000' }}>
                                    Feature activity
                                  </Typography>
                                </ListItemText>
                              </button>
                            )}
                          </ListItem>
                        </Popover>
                      )}
                    </div>
                  </div>
                </div>
              </CardActions>
              <Grid
                container
                padding="16px"
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: 2,
                }}
              >
                <Typography
                  sx={{
                    color: isDarkMode ? '#BFBFBF' : '#676e7b',
                    fontWeight: 'bold',
                    fontSize: '.8rem',
                    textAlign: 'start',
                  }}
                ><EmojiPeopleIcon sx={{
                  color: isDarkMode ? '#BFBFBF' : '#676e7b',
                  fontSize: '1.2rem',
                  marginBottom: '4px'
                }} />
                  Volunteer Headcount:{' '}{activityResponse?.unlimitedVolunteers
                    ? 'Unlimited'
                    : activityResponse?.volunteerCapacity}
                </Typography>
                <Typography
                  sx={{
                    color: isDarkMode ? '#BFBFBF' : '#676e7b',
                    fontWeight: 'bold',
                    fontSize: '.8rem',
                    textAlign: 'start',
                  }}
                > <PeopleOutlineIcon sx={{
                  color: isDarkMode ? '#BFBFBF' : '#676e7b',
                  fontSize: '1.2rem',
                  marginBottom: '4px',
                  paddingRight: '2px'
                }} />
                  Attendee Headcount:{' '}
                  {activityResponse?.unlimitedAttendees
                    ? 'Unlimited'
                    : activityResponse?.attendeeCapacity}
                </Typography>
              </Grid>



              <GenericModal
                handleClose={() => setConfirmDelete(false)}
                fullScreen={fullScreen}
                open={confirmDelete}
                title="Are you sure want to delete?"
                buttons={modalActionButtons}
              />
              <Dialog
                open={openRemoteModal}
                onClose={handleRemoteActivityClose}
                aria-labelledby="remote-activity-modal"
                PaperProps={{
                  sx: {
                    borderRadius: { xs: 0, sm: '14px' },
                    boxShadow: '0px 3px 6px rgba(0, 0, 0, 0.16)',
                    width: { lg: 400 },
                  },
                }}
              >
                <Stack direction="column" sx={{ p: 2 }} spacing={3} minWidth={400} minHeight={200}>
                  <Stack direction="row" alignItems={'center'} justifyContent={'flex-end'}>
                    <CloseIcon onClick={handleRemoteActivityClose} sx={{ cursor: 'pointer' }} />
                  </Stack>
                  <Typography variant="h4" textAlign={'center'}>
                    Meeting Link
                  </Typography>
                  <Button
                    variant="outlined"
                    sx={{ borderColor: 'primary.light', py: 1, borderRadius: '20px', '&:hover': { borderColor: 'primary.light' } }}
                  >
                    <Link id="RouterNavLink" to={activityResponse?.onlineUrl} target="_blank" rel="noopener noreferrer" onClick={handleRemote}>
                      <Stack direction="column" alignItems={'center'}>
                        <Typography
                          sx={{
                            wordBreak: 'break-word',
                            font: 'normal normal normal 14px/23px Manrope',
                            color: isDarkMode ? theme.palette.secondary.contrastText : '#676E7B',
                          }}
                        >
                          {activityResponse?.onlineUrl}
                        </Typography>
                      </Stack>
                    </Link>
                  </Button>
                </Stack>
              </Dialog>
            </div>
          </div>

          {/* Organizers section */}
          <Grid container padding={'16px'}>
            {activityResponse?.participants?.some(participant => participant.organizer || participant.moderator) && (
              <Typography sx={{ color: theme.textColor.mainHeading, fontSize: '20px/32px', paddingBottom: '8px', fontWeight: 'normal' }}>
                Organizers
              </Typography>
            )}
            <Grid container gap={2}>
              {activityResponse?.participants?.map(participant => {
                if (participant.organizer) {
                  return (
                    <Grid key={participant.id} container direction="row" gap={1.5}>
                      <Grid item>
                        <Badge
                          onClick={() =>
                            participant.organizationId
                              ? navigate(`/organizations/${participant.organizationId}`)
                              : navigate(`/profile/${participant.id}`)
                          }
                          overlap="circular"
                          badgeContent={<OrganiserCrownIcon sx={{ height: 'unset', width: 'unset', rotate: '-51deg' }} />}
                          anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
                        >
                          <Box sx={{ border: '2px solid #CCFFCE', borderRadius: '50%' }}>
                            <Avatar
                              alt={participant.profilePic ? '' : activityResponse?.postedBy ? activityResponse?.postedBy : null}
                              src={participant.profilePic}
                              sx={{
                                width: '40px',
                                height: '40px',
                                cursor: 'pointer',
                                fontSize: participant.profilePic ? 'default' : '12px',
                              }}
                            >
                              {activityResponse?.postedBy ? getInitials(activityResponse?.postedBy) : ''}
                            </Avatar>
                          </Box>
                        </Badge>
                      </Grid>
                      <Grid item xs={9} md={10} container direction="column" gap={1}>
                        <Stack className="organizer-mob" sx={{ alignItems: 'center' }} direction="row" gap={2}>
                          <Typography
                            sx={{
                              color: theme.textColor.mainHeading,
                              fontSize: theme.p1.semiBold.fontSize,
                              fontWeight: theme.p1.semiBold.fontWeight,
                              wordBreak: 'break-word',
                            }}
                          >
                            {/* {participant.name} */}
                            {activityResponse?.postedBy}
                          </Typography>

                          <Button
                            className="res-mob"
                            variant="contained"
                            sx={{
                              backgroundColor: '#D7FFEB',
                              color: '#058847',
                              fontSize: '13px',
                              borderRadius: '12px',
                              '&:hover': {
                                backgroundColor: '#D7FFEB',
                              },
                            }}
                          >
                            Organizer
                          </Button>
                        </Stack>
                        <Stack sx={{ overflowWrap: 'break-word', textAlign: 'justify' }} className="bio-phone">
                          <Typography color="text.secondary" sx={{ fontSize: '16px/23px' }}>
                            {participant.bio}
                          </Typography>
                        </Stack>
                      </Grid>
                    </Grid>
                  );
                } else if (participant.moderator) {
                  return (
                    <Grid key={participant.id} container direction="row" gap={1.7}>
                      <Grid item>
                        <Badge
                          overlap="circular"
                          badgeContent={<ModeratorStarIcon sx={{ height: 'unset', width: 'unset' }} />}
                          anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
                        >
                          <Box sx={{ border: '2px solid #9AE3FF', borderRadius: '50%' }}>
                            <Avatar
                              alt={participant.profilePic ? '' : participant.name ? participant.name : null}
                              src={participant.profilePic}
                              sx={{
                                width: '40px',
                                height: '40px',
                                borderColor: '#9AE3FF',
                                fontSize: participant.profilePic ? 'default' : '12px',
                              }}
                            >
                              {participant.name ? getInitials(participant.name) : ''}
                            </Avatar>
                          </Box>
                        </Badge>
                      </Grid>
                      <Grid item xs={9} md={10} container direction="column" gap={1}>
                        <Stack style={{ display: 'flex', alignItems: 'center' }} direction="row" gap={2}>
                          <Typography
                            sx={{
                              color: theme.textColor.mainHeading,
                              fontSize: theme.p1.semiBold.fontSize,
                              fontWeight: theme.p1.semiBold.fontWeight,
                            }}
                          >
                            {participant.name}
                          </Typography>
                          <Button
                            variant="contained"
                            sx={{
                              backgroundColor: '#D7FFEB',
                              color: '#236D8A',
                              fontSize: '13px',
                              borderRadius: '12px',
                              '&:hover': { backgroundColor: '#D7FFEB' },
                            }}
                          >
                            Moderator
                          </Button>
                        </Stack>
                        <Stack sx={{ overflowWrap: 'break-word', width: '350px', textAlign: 'justify' }} className="bio-phone">
                          <Typography color="text.secondary" sx={{ fontSize: '16px/23px' }}>
                            {participant.bio}
                          </Typography>
                          <Typography
                            sx={{
                              color: participant?.reached ? '#058847' : '#FF9494',
                              fontSize: '14px',
                              paddingTop: '10px',
                              paddingBottom: '8px',
                            }}
                          >
                            {participant?.reached && activityResponse?.activityStatus !== 'OPEN'
                              ? 'Arrived'
                              : activityResponse?.activityStatus === 'RUNNING'
                                ? 'Awaiting Arrival'
                                : activityResponse?.activityStatus !== 'OPEN'
                                  ? 'Not Arrived'
                                  : ''}
                          </Typography>
                        </Stack>
                      </Grid>
                    </Grid>
                  );
                }
                return null;
              })}
            </Grid>
          </Grid>

          {/* Participants section */}
          <ParticipantList activityResponse={activityResponse} onParticipantBlocked={onParticipantBlocked} />

          {/* Review Section*/}
          {activityResponse?.activityStatus === 'COMPLETED' && <ActivityDetailsReviewSection activityResponse={activityResponse} />}

          <GenericModal
            handleClose={() => setConfirmDelete(false)}
            fullScreen={fullScreen}
            open={confirmDelete}
            title="Are you sure want to delete?"
            buttons={modalActionButtons}
          />
          <FeatureDialog
            paymentLoading={paymentLoading}
            agreeAction={handlePayment}
            values={payload}
            open={confirmFeature}
            setFeatureOpen={setConfirmFeature}
          />

          <AuthorisationModal
            onClose={handleAuthModalClose}
            authModalOpen={authModalOpen}
            fullScreen={fullScreen}
            handleAuthModalClose={handleAuthModalClose}
            currentComponent={currentComponent}
            setCurrentComponent={setCurrentComponent}
          />
          <RecurringRangeModal open={calDialogOpen} onClose={handleCloseModal} handleData={dateResponse} />
          <LeaveActivityDialog
            open={leaveDialogOpen}
            onClose={() => handleDialogClose(activityResponse?.id)}
            handleData={activityResponse}
          />

          <ReportActivityDialog open={reportDialogOpen} onClose={() => setReportDialogOpen(false)} handleData={activityResponse} />
        </Card>
      ) : (
        <Grid sx={{ display: 'flex', justifyContent: 'center' }}></Grid>
      )}
    </div>
  );
};
export default ActivityRecurringDetails;
