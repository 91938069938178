import React, { useState, useEffect } from 'react';

import {
  Typography,
  Grid,
  Button,
  TextField,
  TableContainer,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Stack,
  Dialog,
  Box,
  useTheme,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import DeleteIcon from '@mui/icons-material/Delete';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import './create-activity-instructions.scss';
import { useLocation, useParams } from 'react-router-dom';
import axios from 'axios';

interface Instruction {
  instruction: string;
  id?: number;
  activityId?: number;
}

const CreateActivityInstructions = ({ formik }) => {
  const [instructions, setInstructions] = useState([]);
  const [instructionsMock, setInstructionsMock] = useState<Instruction[]>(formik.values.instructions || []);
  const [openAddInstructions, setOpenAddInstructions] = useState(false);
  const [isNewInputFilled, setIsNewInputFilled] = useState(false);
  const location = useLocation();
  const theme = useTheme();
  const { id } = useParams();
  const handleAddInstructions = () => {
    setOpenAddInstructions(true);
    setInstructions([...instructions, { instruction: '', id: null, activityId: id, isEditing: true }]);
  };

  const handleInstructionsClose = () => {
    setOpenAddInstructions(false);

    const filteredInstructions = instructions
      .filter(item => item.instruction.trim() !== '') // Filter out empty instructions
      .map(item => ({
        ...item,
        id: item.id, // Retain ID
        isEditing: false, // Ensure editing is toggled off
      }));

    setInstructions(filteredInstructions);
    setInstructionsMock(filteredInstructions);
  };

  const handleInstructionChange = (index, event) => {
    const { name, value } = event.target;

    const newInstructions = instructions.map((instruction, i) => {
      if (i === index) {
        return {
          ...instruction,
          [name]: value,
          id: instruction.id ?? null,
          activityId: instruction.activityId ?? id,
        };
      }
      return instruction;
    });

    setInstructions(newInstructions);
    if (index === newInstructions.length - 1) {
      setIsNewInputFilled(value.trim() !== '');
    }
  };

  const handleToggleEditInstruction = index => {
    const newInstructions = [...instructions];

    // Toggle editing state
    newInstructions[index].isEditing = !newInstructions[index].isEditing;

    setInstructions(newInstructions);

    // Only update instructionsMock with non-editing items
    const updatedInstructionsMock = newInstructions.filter(item => !item.isEditing);
    setInstructionsMock(updatedInstructionsMock);

    // Reset new input flag
    setIsNewInputFilled(false);
  };

  const handleDeleteInstruction = (index, item) => {
    if (item.id) {
      console.log('Deleted item ID:', item.id);
    }

    const newInstructions = [...instructions];
    newInstructions.splice(index, 1); // Remove the item by index
    setInstructions(newInstructions);
    setInstructionsMock(newInstructions);
    // const newInstructions = [...instructions];
    // newInstructions.splice(index, 1);
    // const filterednewInstructions = newInstructions.filter(item => item.instruction.trim() !== '' && !item.isEditing);
    // setInstructions(filterednewInstructions);
    // setInstructionsMock(filterednewInstructions);
    // setInstructions([...filterednewInstructions, { instruction: '', isEditing: true }]);
    // setInstructionsMock([...filterednewInstructions, { instruction: '', isEditing: true }]);
  };

  useEffect(() => {
    // Remove duplicates by using a Set on the instruction values
    const uniqueInstructions = Array.from(new Map(instructionsMock.map(item => [item.instruction, item])).values());

    // Map to the final structure
    const updatedInstructions = uniqueInstructions.map(input => {
      const { instruction, id, activityId } = input;

      return id && activityId ? { instruction, id, activityId } : { instruction };
    });

    formik.setFieldValue('instructions', updatedInstructions);
  }, [instructionsMock]);

  useEffect(() => {
    if (id !== undefined && formik.values.instructions) {
      const initialInstructions = formik.values.instructions.map(input => ({
        ...input,
        id: input.id,
        activityId: input.activityId ?? id,
      }));

      setInstructions(initialInstructions);
      setInstructionsMock(initialInstructions);
    }
  }, []);
  const [hasInitialInstructions, setHasInitialInstructions] = useState(false);
  useEffect(() => {
    if (
      !hasInitialInstructions &&
      id !== undefined &&
      formik.values.instructions &&
      formik.values.instructions?.length > 0 &&
      !arraysEqual(instructionsMock, formik.values.instructions)
    ) {
      setInstructions(formik.values.instructions);
      setInstructionsMock(formik.values.instructions);
      setHasInitialInstructions(true);
      formik.setFieldValue('instructions', formik.values.instructions);
    }
  }, [formik.values.instructions, id]);

  useEffect(() => {
    if (instructionsMock && hasInitialInstructions) {
      formik.setFieldValue('instructions', instructionsMock);
    }
  }, [instructionsMock]);

  function arraysEqual(arr1, arr2) {
    if (arr1?.length !== arr2?.length) return false;

    const arr1Instructions = arr1.map(item => item.instruction).sort();
    const arr2Instructions = arr2.map(item => item.instruction).sort();

    for (let i = 0; i < arr1Instructions?.length; i++) {
      if (arr1Instructions[i] !== arr2Instructions[i]) return false;
    }

    return true; // Return true if arrays are equal
  }
  const isDarkMode1 = theme.palette.mode === 'dark';

  const handleKeyDown = (index, e) => {
    if (e.key === 'Enter') {
      handleToggleEditInstruction(index);
      setInstructions([...instructions, { instruction: '', isEditing: true }]);
    }
  };

  useEffect(() => {
    if (location.pathname === '/create-activity' && formik.values?.subCategoryID === 14) {
      fetchBloodInstructions();
    } else if (location.pathname === '/create-activity' && formik.values?.subCategoryID !== 14) {
      setInstructionsMock([]);
      setInstructions([]);
    }
  }, [location.pathname, formik.values?.subCategoryID]);
  useEffect(() => {
    if (instructions) {
      const updatedInstructions = instructions.map(input => ({
        ...input,
        id: input.id, // Retain ID
        activityId: input.activityId ?? id, // Retain or assign activityId
      }));

      formik.setFieldValue('instructions', updatedInstructions);
    }
  }, [instructions]);

  const fetchBloodInstructions = async () => {
    try {
      const response = await axios.get<any>(`/api/activity/event-instructions`);
      if (response) {
        console.log('events', response);
        setInstructionsMock(response.data);
        setInstructions(response?.data);
      }
    } catch (error) {
      console.log('Error fetching goal:', error);
    }
  };

  console.log('Before update:', formik.values.instructions);
  console.log('After update:', formik.values.instructions);
  return (
    <>
      <Grid item>
        <div
          style={{
            borderRadius: '16px',
            padding: 12,
            height: '190px',
            overflowX: 'hidden',
            overflowY: 'hidden',
            backgroundColor: isDarkMode1 ? '#27272E' : '#ffff',
          }}
        >
          <Typography fontWeight={600} color={'secondary.main'} sx={{ marginBottom: '10px' }}>
            Instructions for participants
          </Typography>

          <div
            className="scrollable-div example1"
            style={{
              maxHeight: '100px',
              overflowY: 'hidden',
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            {instructionsMock?.length > 0 && (
              <TableContainer sx={{ border: 'none' }} className="scrollable-div example1">
                <Table cellSpacing={0}>
                  <TableHead style={{ position: 'sticky', top: 0, backgroundColor: 'transparent', zIndex: 1 }}>
                    <TableRow>
                      <TableCell></TableCell>
                      <TableCell sx={{ padding: 0, textAlign: 'end' }}></TableCell>
                    </TableRow>
                  </TableHead>

                  <TableBody style={{ flex: 1, overflowY: instructions && instructions.length * 20 > 19 ? 'scroll' : 'hidden' }}>
                    {instructionsMock?.map((item, index) => {
                      return (
                        <TableRow key={index}>
                          <TableCell sx={{ p: 1 }}>
                            <Box
                              sx={{
                                width: '9px',
                                height: '9px',
                                borderRadius: '50%',
                                backgroundColor: theme.createActivity.backgroundColor.hover,
                              }}
                            />
                          </TableCell>

                          <TableCell sx={{ p: 1 }}>
                            <Typography
                              sx={{
                                whiteSpace: 'pre-wrap',
                                wordWrap: 'break-word',
                                maxWidth: '200px',
                                fontSize: '14px',
                                lineHeight: '1.3',
                              }}
                            >
                              {item.instruction}
                            </Typography>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            )}
          </div>
          <IconButton aria-label="add" onClick={handleAddInstructions} >
            <AddCircleOutlineOutlinedIcon sx={{ color: theme.palette.mode === 'dark' ? '#FFCE00' : '#674BFF' }} />
          </IconButton>
        </div>
      </Grid>

      <Dialog
        open={openAddInstructions}
        onClose={handleInstructionsClose}
        PaperProps={{
          style: {
            minWidth: '50%',
            maxWidth: '100%',
            height: '50vh',
            overflowX: 'hidden',
            overflowY: 'scroll',
            borderRadius: '16px',
          },
        }}
      >
        <DialogTitle>{'Instructions'}</DialogTitle>
        <CloseIcon sx={{ cursor: 'pointer', position: 'absolute', right: 15, top: 20 }} onClick={handleInstructionsClose} />
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            {instructions?.map((item, index) => (
              <Grid container spacing={1} alignItems="center" justifyContent="space-between" key={index} sx={{ paddingRight: '56px' }}>
                <Grid item xs={1} pb={1}>
                  {index + 1}
                </Grid>
                <Grid item xs={10} pb={1}>
                  <TextField
                    size="small"
                    placeholder="Instructions"
                    name="instruction"
                    fullWidth
                    multiline
                    rows={1}
                    value={item.instruction}
                    onChange={e => handleInstructionChange(index, e)}
                    disabled={!item.isEditing}
                    onKeyDown={e => handleKeyDown(index, e)}
                  />
                </Grid>
                <Grid item xs={1} pb={1}>
                  {item.isEditing ? (
                    <Stack direction="row" gap={1}>
                      <DeleteIcon
                        onClick={() => handleDeleteInstruction(index, item)}
                        sx={{
                          cursor: 'pointer',
                          color: 'primary.light',
                          opacity: item.instruction ? 1 : 0.5,
                          pointerEvents: item.instruction ? 'auto' : 'none',
                        }}
                      />
                    </Stack>
                  ) : (
                    <EditIcon onClick={() => handleToggleEditInstruction(index)} sx={{ cursor: 'pointer', color: 'primary.light' }} />
                  )}
                </Grid>
              </Grid>
            ))}
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default CreateActivityInstructions;
