import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';

interface SEOProps {
    activity: any;
}

const SEO: React.FC<SEOProps> = ({ activity }) => {
    return (
        <Helmet>
            <title>{activity?.title || "ReachAmy"}</title>
            <meta name="description" content={activity?.caption} />
            <meta property="og:title" content={activity?.title} />
            <meta property="og:description" content={activity?.caption} />
        </Helmet>
    );
};

export default SEO;
