import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Grid,
  Stack,
  TextField,
  Typography,
  useTheme,
  Avatar,
  IconButton,
  Popover,
  ListItem,
  ListItemText,
  SpeedDial,
  SpeedDialIcon,
  SpeedDialAction,
  List,
  Collapse,
  Dialog,
  useMediaQuery,
} from '@mui/material';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { fetchBlogById, fetchBlogByIdUnauthorized, postCommentById, editCommentById, deleteCommentById } from 'app/shared/reducers/blogs';
import { useParams, useNavigate, Link } from 'react-router-dom';
import FacebookIcon from 'app/components/icons/login-page-icons/facebook-icon';
import InsertLinkIcon from '@mui/icons-material/InsertLink';
import { Reply } from '@mui/icons-material';
import BlogComment from './blog-comment';
import ArrowCircleLeftIcon from '@mui/icons-material/ArrowCircleLeft';
import EditCommentDialog from './blog-edit';
import MoreHorizOutlinedIcon from '@mui/icons-material/MoreHorizOutlined';
import InstagramIcon from 'app/components/icons/login-page-icons/instagram-icon';
import LinkedInIcon from 'app/components/icons/login-page-icons/linkedin-icon';
import DeleteCommentDialog from './blog-comment-delete';
import ReportCommentDialog from './blog-comment-report';
import {
  generateGMapsLinkCords,
  timestampToHoursAgo,
  convertDate,
  getInitials,
  shareOnSocialMedia,
} from 'app/utils/common-utils/common-utils';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import HTMLReactParser from 'html-react-parser';
import AuthenticationModal from '../auth/authentication-modal';
import { Close } from '@mui/icons-material';
import BlueTwitterIcon from 'app/components/icons/login-page-icons/blue-twitter-icon';
import GrayTwitterIcon from 'app/components/icons/login-page-icons/gray-twitter-icon';
import './blog-single.scss';
import PhoneVerification from '../phone-verification/phone-verification';
import { getPhoneNumber } from 'app/shared/reducers/phone-verification';
import { toast } from 'react-hot-toast';
import { getReportingReasons } from 'app/shared/reducers/activity/activity-report';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { toggleModalLogin } from 'app/shared/reducers/authentication';
import PageNotFound from 'app/shared/error/page-not-found';
import { useItemId } from 'app/utils/hooks/useItemId';
import Lightbox from 'yet-another-react-lightbox';
import { fetchActivityShareToken, fetchBlogShareToken, trackBlogShare } from 'app/shared/reducers/activity-home';
import { appConstants } from 'app/utils/data/constants/constants';
import { GallerySlide } from '../activity-details-section/activity-details-card/activity-details-card';
import ImageGrid from '../grid-layout/ImageGrid';
import Video from 'yet-another-react-lightbox/plugins/video';

const actions = [
  { icon: <BlueTwitterIcon />, name: 'Twitter' },
  { icon: <FacebookIcon />, name: 'Facebook' },
  { icon: <InstagramIcon />, name: 'Instagram' },
  { icon: <LinkedInIcon />, name: 'LinkedIn' },
  { icon: <InsertLinkIcon />, name: 'InsertLink' },
];

const BlogSingle = () => {
  const loadingContainerStyle = {
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  };
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [singleBlogData, setSingleBlogData] = useState<any>([]);
  const isAuthenticated = useAppSelector(state => state.authentication.isAuthenticated);
  const [repliedPersonName, setRepliedPersonName] = useState('');
  const [mainComment, setMainComment] = useState('');
  const [open, setOpen] = useState(false);
  const [auth, setAuth] = useState(false);
  const [selectedComment, setSelectedComment] = useState(null);
  const isPostButtonDisabled = mainComment?.trim() === '';
  // const { id } = useParams();
  // const blogId = Number(id);
  const blogId = useItemId();
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [selectedCommentForDelete, setSelectedCommentForDelete] = useState(null);
  const [reportDialogOpen, setReportDialogOpen] = useState(false);
  const [selectedCommentForReport, setSelectedCommentForReport] = useState(null);
  const [copySuccess, setCopySuccess] = useState(false);
  const openPop = Boolean(anchorEl);
  const menuItemId = openPop ? 'simple-popover' : undefined;
  const [editedComment, setEditedComment] = useState('');
  const [expandedComments, setExpandedComments] = useState([]);
  const [currentComponent, setCurrentComponent] = React.useState(' ');
  const [authModalOpen, setAuthModalOpen] = React.useState(false);
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [phoneVerificationStatus, setphoneVerificationStatus] = React.useState(false);
  const [phoneVerificationModal, setPhoneVerificationModal] = React.useState<boolean>(false);
  const verificationStatusLoading = useAppSelector(state => state.verificationSlice.loading);
  const [otpVerificationCompleted, setotpVerificationCompleted] = useState(false);
  const [errorRes, seterrorRes] = useState(false);
  const [phoneInputCompleted, setPhoneInputCompleted] = useState(false);
  const [viewableComment, setViewableComment] = useState([]);
  const [lightBoxOpen, setLightBoxOpen] = useState(false);

  // below are for the share feature
  const [opener, setOpener] = React.useState(false);
  const handleOpener = () => setOpener(true);
  const handleCloser = () => setOpener(false);
  const openLightbox = image => {
    setLightBoxOpen(true);
  };
  const handleImageError = event => {
    event.target.src = 'content/images/no-img.jpg';
  };

  useEffect(() => {
    const fetchData = async () => {
      let response;
      try {
        setLoading(true);
        if (isAuthenticated) {
          response = await dispatch(fetchBlogById(blogId));
          setAuth(true);
        } else {
          response = await dispatch(fetchBlogByIdUnauthorized(blogId));
          setAuth(false);
        }

        if (response?.error?.message === 'Rejected') {
          console.log('im check', response?.error?.message);
          seterrorRes(true);
          setLoading(false);
        } else {
          seterrorRes(false);
          setSingleBlogData(response.payload);
          setLoading(false);
        }
        console.log(errorRes);
      } catch (error) {
        console.log(error);
        setLoading(false);
        setError(true);
      }
    };

    fetchData();
  }, [isAuthenticated]);

  useEffect(() => {
    const fetchData = async () => {
      let response;
      try {
        // setLoading(true);
        if (isAuthenticated) {
          response = await dispatch(fetchBlogById(blogId));
          setAuth(true);
        } else {
          response = await dispatch(fetchBlogByIdUnauthorized(blogId));
          setAuth(false);
        }
        if (response?.error?.message === 'Rejected') {
          seterrorRes(true);
          setLoading(false);
        } else {
          seterrorRes(false);
          setSingleBlogData(response.payload);
          setLoading(false);
        }
        console.log(errorRes);
      } catch (error) {
        console.log(error);
        setError(true);
        setLoading(false);
      }
    };

    fetchData();
  }, [deleteDialogOpen, editDialogOpen, reportDialogOpen]);

  const handleGoBack = () => {
    navigate('/blog-list');
  };
  const [selectedPerson, setSelectedPerson] = useState<any>();
  const handleReply = repliedPersonName => {
    setSelectedPerson(repliedPersonName?.id);
    setRepliedPersonName(repliedPersonName);
    handleOpen();
  };
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  // below are for three dot option

  const handleClick = (event, comment) => {
    setAnchorEl(event.currentTarget);
    setSelectedComment(comment);
  };

  const handlePopClose = () => {
    setAnchorEl(null);
  };

  // below two are for the edit dialog box
  const handleEdit = comment => {
    handlePopClose();
    setEditedComment(comment); // Set the comment to be edited
    setEditDialogOpen(true); // Open the edit dialog
    // setAnchorEl(null);
  };
  const fetchNewData = async () => {
    let response;
    try {
      // setLoading(true);
      if (isAuthenticated) {
        response = await dispatch(fetchBlogById(blogId));
        setAuth(true);
      } else {
        response = await dispatch(fetchBlogByIdUnauthorized(blogId));
        setAuth(false);
      }
      if (response?.error?.message === 'Rejected') {
        seterrorRes(true);
        setLoading(false);
      } else {
        seterrorRes(false);
        setSingleBlogData(response.payload);
        setLoading(false);
      }
      console.log(errorRes);
    } catch (error) {
      console.log(error);
      setError(true);
      setLoading(false);
    }
  };
  const handleCloseEditDialog = dialog => {
    setEditDialogOpen(false);
    fetchNewData();
  };

  const handleDelete = comment => {
    console.log('set ', comment);
    handlePopClose();
    setSelectedCommentForDelete(comment);
    setDeleteDialogOpen(true);
    console.log('selectedComment', comment, selectedComment);
  };

  const handleDeleteConfirmation = () => {
    setDeleteDialogOpen(false);
  };

  const shareLink = type => {
    let mailText = 'Please reachout to ReachAmy for further assistance';
    if (type == 'Twitter') {
      const title = singleBlogData?.blogTitle + ' - ' + mailText;
      const url = singleBlogData?.eventURL;
      const twitterUrl = `https://twitter.com/intent/tweet?text=${encodeURIComponent(title)}&url=${encodeURIComponent(url)}`;
      window.open(twitterUrl, '_blank');
    } else if (type == 'Facebook') {
      const title = singleBlogData?.blogTitle;
      const url = singleBlogData?.eventURL;
      const facebookUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(url)}`;
      window.open(facebookUrl, '_blank');
    } else if (type == 'LinkedIn') {
      const title = singleBlogData?.blogTitle;
      const url = singleBlogData?.eventURL;
      const linkedInUrl = `https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(url)}&caption=${encodeURIComponent(
        title
      )}`;
      window.open(linkedInUrl, '_blank');
    } else if (type == 'CutPaste') {
      const url = singleBlogData?.eventURL;
      handleShareLink(url);
    }

    handleCloser();
  };

  const handleShareLink = link => {
    navigator.clipboard.writeText(link);
    setCopySuccess(true);
    setTimeout(() => {
      setCopySuccess(false);
    }, 2000);
  };

  const handleAddMainComment = event => {
    event.preventDefault();
    const newComment = {
      blogId: blogId,
      comment: mainComment,
    };

    const fetchPost = async () => {
      let response;
      let sample;
      try {
        // setLoading(true);
        if (isAuthenticated) {
          response = await dispatch(postCommentById(newComment));
          sample = await dispatch(fetchBlogById(blogId));
        }
        setSingleBlogData(sample.payload);
        setLoading(false);
      } catch (error) {
        console.log(error);
        setLoading(false);
        setError(true);
      }
    };
    fetchPost();

    // Clear the main comment input
    setMainComment('');
  };

  const handleDialogClose = dialogState => {
    setSingleBlogData(dialogState);
  };
  // get user phone number
  const fetchPhoneNumber = async () => {
    try {
      const response = await dispatch(getPhoneNumber());
      if (response.meta.requestStatus == 'fulfilled') {
        setphoneVerificationStatus(response.payload?.verified);
      } else {
        toast.error('Unable to fetch verification Status', { id: 'phone-verification-status-error' });
      }
    } catch (error) {
      toast.error('Unable to fetch verification Status', { id: 'verification-status-error' });
    }
  };
  //for report action

  const handleReport = comment => {
    dispatch(getReportingReasons());
    handlePopClose();
    setSelectedCommentForReport(comment);
    setReportDialogOpen(true);
  };

  const handleReportConfirmation = reason => {
    setReportDialogOpen(true);
  };

  const addToOffensiveList = data => {
    setViewableComment(state => [...state, data]);
  };

  //function for collapsable operation
  const toggleCommentVisibility = commentId => {
    setExpandedComments(prevExpanded => {
      const newExpanded = [...prevExpanded];
      const commentIndex = newExpanded.indexOf(commentId);
      if (commentIndex !== -1) {
        newExpanded.splice(commentIndex, 1);
      } else {
        newExpanded.push(commentId);
      }
      return newExpanded;
    });
  };

  const handleCreateActivityClick = (categoryId: any) => {
    if (isAuthenticated) {
      if (phoneVerificationStatus) {
        navigate(`/create-activity/${categoryId}`);
      } else if (!phoneVerificationStatus && !verificationStatusLoading) {
        setPhoneVerificationModal(true);
      }
    } else {
      setCurrentComponent('login');
      dispatch(toggleModalLogin());

      setAuthModalOpen(true);
    }
  };
  //phone verification close
  const handlePhoneVerificationClose = () => {
    setPhoneVerificationModal(false);

    //after completing phone verification open acitivity or petition modal
    if (otpVerificationCompleted) {
      return;
    } else {
      setPhoneInputCompleted(false);
    }
  };
  //close authentication modal dialog
  const handleAuthModalClose = () => {
    setAuthModalOpen(false);
  };
  useEffect(() => {
    if (isAuthenticated) {
      fetchPhoneNumber();
    }
  }, [otpVerificationCompleted]);
  const isDarkMode = theme.palette.mode === 'dark';
  useEffect(() => {
    const params = new URLSearchParams(window.location.search);

    const socialToken = params.get('t');
    if (socialToken) {
      dispatch(trackBlogShare({ blogId: singleBlogData?.id, token: socialToken }))
        .then(() => {
          const newSearch = new URLSearchParams(window.location.search);
          newSearch.delete('t');
          navigate(`?${newSearch.toString()}`, { replace: true });
        })
        .catch(() => { });
    }
  }, []);
  const getShareToken = (socialName, content, activityId, type, URL, shareMessage) => {
    console.log(socialName, content, activityId, type, URL, shareMessage, 'socialName, URL, shareMessage');

    dispatch(fetchBlogShareToken({ blogId, type }))
      .then(result => {
        const token = result.payload.token;

        if (token) {
          const utmSource = socialName;
          const utmCampaign = 'Reachamy';
          const utmContent = content.replace(/\s/g, '');

          const newURL = `${URL}?utm_source=${utmSource}&utm_campaign=${utmCampaign}&utm_content=${utmContent}&t=${token}`;

          shareOnSocialMedia(socialName, newURL, shareMessage);
        }
      })
      .catch(err => {
        console.error('Error fetching data:', err);
        toast.error('Something went wrong');
      });
  };
  const [index, setIndex] = useState(-1);
  const handleLightBoxClick = (index: number) => setIndex(index);
  const handleLightBoxClose = () => setIndex(-1);
  const blogSlides: GallerySlide[] = singleBlogData?.eventGallery?.map((img: any) => {
    if (img.type === 'videos') {
      return {
        type: 'video',
        src: img?.link,
        thumbnail: img?.thumbnail,
        title: '',
        sources: [{ src: img?.link, type: 'video/mp4' }],
      };
    } else {
      return {
        type: 'image',
        src: img?.link,
        thumbnail: img?.link,
        title: '',
      };
    }
  });
  return (
    <Grid
      container
      direction="row"
      sx={{
        height: '100vh',
        overflow: 'hidden scroll',
        pb: 5,
        background: isDarkMode ? '#1F1F26' : 'linear-gradient(180deg, #F7F5EF 0%, #F2F2FA 0%, #EFEFF5 100%) 0% 0% no-repeat padding-box',
      }}
    >
      <Grid item xs={1} md={2} lg={2}>
        <ArrowCircleLeftIcon
          sx={{
            paddingTop: '6%',
            paddingLeft: '6%',
            color: isDarkMode ? '#FFCE00' : '#775fff',
            fontSize: theme.typography.h1.fontSize,
            cursor: 'pointer',
          }}
          onClick={handleGoBack}
        />
      </Grid>
      {loading ? (
        <Grid item xs={12}>
          <div style={loadingContainerStyle}>Loading...</div>
        </Grid>
      ) : error ? (
        <Grid item container direction="column" xs={10} md={8} lg={8} sx={{ flexWrap: 'nowrap' }}>
          {/* <div style={loadingContainerStyle}>Error occurred while fetching data.</div> */}
          <PageNotFound />
        </Grid>
      ) : errorRes ? (
        <Grid item container direction="column" xs={10} md={8} lg={8} sx={{ flexWrap: 'nowrap' }}>
          {/* <div style={loadingContainerStyle}>Error occurred while fetching data.</div> */}
          {/* <PageNotFound /> */}
          <div style={loadingContainerStyle}>
            <Box
              display="flex"
              height="60vh"
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
              sx={{
                padding: '24px',
              }}
            >
              <Box
                component="img"
                sx={{
                  borderRadius: '8px',
                  boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
                  maxHeight: { xs: 300, md: 200 },
                  maxWidth: { xs: 400, md: 300 },
                }}
                alt="No activity found illustration"
                src="content/images/empty.svg"
              />
              <Typography
                fontSize="24px"
                mt={3}
                fontWeight="bold"
                sx={{
                  color: isDarkMode ? '#FFCE00' : '#4A4A4A',
                  textAlign: 'center',
                }}
              >
                Blog Not Found
              </Typography>
              <Typography
                fontSize="16px"
                mt={1}
                color={isDarkMode ? '#BFBFBF' : '#757575'}
                sx={{
                  textAlign: 'center',
                }}
              >
                Sorry, the blog you're looking for doesn't exist or has been removed.
              </Typography>
              <Button
                variant="contained"
                sx={{
                  mt: 4,
                  background: isDarkMode ? '#FFCE00' : '#674BFF',
                  color: '#fff',
                  borderRadius: '20px',
                  padding: '10px 24px',
                  textTransform: 'none',
                  fontWeight: '600',
                  '&:hover': {
                    background: isDarkMode ? '#FFC800' : '#563ECC',
                  },
                }}
                onClick={() => window.location.replace('/')}
              >
                Back to Home
              </Button>
            </Box>

          </div>
        </Grid>
      ) : singleBlogData ? (
        <Grid item container direction="column" xs={10} md={8} lg={8} sx={{ flexWrap: 'nowrap' }}>
          <Grid>
            <Button
              variant="contained"
              sx={{
                background: '#EEEBFF ',
                borderRadius: '16px',
                color: theme.palette.primary.light,
                marginTop: '2%',
                marginLeft: { sm: '6%', md: '1%', xs: '6%' },
                '&:hover': {
                  background: '#EEEBFF',
                },
              }}
            >
              {singleBlogData?.blogType?.category}
            </Button>
            <Typography
              sx={{
                fontSize: '32px',
                color: theme.palette.secondary.main,
                fontWeight: theme.typography.h1.fontWeight,
              }}
            >
              {singleBlogData?.blogTitle}
            </Typography>
            <Stack direction="row">
              <Button
                variant="text"
                sx={{ color: theme.palette.secondary.light, fontSize: theme.infoText.regular.fontSize, textTransform: 'capitalize' }}
              >
                {convertDate(singleBlogData?.postedOn ? singleBlogData?.postedOn : '2024-02-10T18:30:00.000Z')}
              </Button>
              <Button
                variant="text"
                sx={{ color: theme.palette.secondary.light, fontSize: theme.infoText.regular.fontSize, textTransform: 'capitalize' }}
              >
                By {singleBlogData.publisherName}
              </Button>
            </Stack>
          </Grid>
          <Grid>
            <div style={{ position: 'relative', marginTop: '2%' }}>
              <ImageGrid onImageClick={handleLightBoxClick}>
                {singleBlogData?.eventGallery?.map((item, index) => (
                  <div
                    key={item.id || index} 
                    className={`img_container ${item.type === 'videos' ? 'video-grid' : ''}`}
                  >
                    <img
                      alt="ig"
                      src={item.type === 'videos' ? item?.thumbnail : item?.link}
                      style={{ objectPosition: '1px 1%' }}
                    />
                  </div>
                ))}
              </ImageGrid>

              {/* <img
                src={singleBlogData?.blogImage ? singleBlogData?.blogImage : 'content/images/no-img.jpg'}
                alt="blog image"
                style={{
                  width: '100%',
                  borderRadius: '15px',
                  marginBottom: '2%',
                  height: '500px',
                  cursor: 'pointer',
                  objectFit: 'cover',
                  objectPosition: '1px 1%',
                }}
                onError={handleImageError}
                onClick={() => openLightbox(true)}
              /> */}

              {singleBlogData?.featured && (
                <div
                  style={{
                    position: 'absolute',
                    top: '0',
                    left: '0',
                    background: '#674BFF',
                    color: '#FFFF',
                    padding: '5px 10px',
                    borderRadius: '15px 0 15px 0',
                    zIndex: '999',
                    fontSize: '12px',
                  }}
                >
                  <span style={{ fontWeight: 'bold' }}>Featured</span>
                </div>
              )}
              <Lightbox
                carousel={{ finite: true }}
                open={index >= 0}
                close={handleLightBoxClose}
                slides={blogSlides}
                plugins={[Video]}
                index={index}
              />
            </div>
          </Grid>
          {/* </Grid> */}
          <Grid
            item
            container
            sx={{ color: theme.textColor.paragraph, fontSize: theme.p2.fontSize, textAlign: 'left', width: '100%', marginTop: '15px' }}
          >
            <Box sx={{ width: '100%', overflowWrap: 'break-word' }}>{HTMLReactParser(singleBlogData?.blogDescription)}</Box>
          </Grid>
          <Grid container direction="row" sx={{ paddingTop: '2%', paddingBottom: '2%' }} gap={2}>
            {singleBlogData?.ngoUser && (
              <Grid item container direction="column">
                <Typography
                  sx={{
                    fontSize: theme.typography.h5.fontSize,
                    color: theme.palette.secondary.main,
                    fontWeight: theme.typography.h5.fontWeight,
                    mb: 1,
                  }}
                >
                  NGO details
                </Typography>
                {singleBlogData?.ngoUserDetails !== null && (
                  <Grid container direction="row" alignItems={'center'} marginLeft={'3%'}>
                    <Grid container item xs={12} lg={6} direction="column" spacing={1.5}>
                      {singleBlogData?.ngoUserDetails?.ngoName && (
                        <Grid item container direction="row" spacing={0.5}>
                          <Typography fontWeight={500}>Name:</Typography>
                          <Typography marginLeft={'1%'}>{singleBlogData?.ngoUserDetails.ngoName}</Typography>
                        </Grid>
                      )}
                      {singleBlogData?.ngoUserDetails?.ngoAddress && (
                        <Grid item container direction="row" spacing={0.5}>
                          <Typography fontWeight={500}>Address:</Typography>
                          <Typography marginLeft={'1%'}>{singleBlogData?.ngoUserDetails?.ngoAddress}</Typography>
                        </Grid>
                      )}
                      {singleBlogData?.ngoUserDetails?.ngoContactPerson && (
                        <Grid item container direction="row" spacing={0.5}>
                          <Typography fontWeight={500}>Contact Person:</Typography>
                          <Typography marginLeft={'1%'}>{singleBlogData?.ngoUserDetails.ngoContactPerson}</Typography>
                        </Grid>
                      )}
                      {singleBlogData?.ngoUserDetails?.ngoEmailId && (
                        <Grid item container direction="row" spacing={0.5}>
                          <Typography fontWeight={500}>Email Id:</Typography>
                          <Typography marginLeft={'1%'}>{singleBlogData?.ngoUserDetails.ngoEmailId}</Typography>
                        </Grid>
                      )}
                      {singleBlogData?.ngoUserDetails?.ngoContactPhoneNumber && (
                        <Grid item container direction="row" spacing={0.5}>
                          <Typography fontWeight={500}>Phone Number:</Typography>
                          <Typography marginLeft={'1%'}>{singleBlogData?.ngoUserDetails.ngoContactPhoneNumber}</Typography>
                        </Grid>
                      )}
                      {singleBlogData?.ngoUserDetails?.ngoLocationLatitude && singleBlogData?.ngoUserDetails.ngoLocationLongitude && (
                        <Grid item container direction="row" spacing={0.5}>
                          <Typography fontWeight={500}>Location:</Typography>
                          <Link
                            className="text-decoration-none"
                            target="_blank"
                            rel="noopener noreferrer"
                            to={
                              singleBlogData?.ngoUserDetails?.ngoLocationLatitude && singleBlogData?.ngoUserDetails?.ngoLocationLongitude
                                ? generateGMapsLinkCords(
                                  singleBlogData?.ngoUserDetails?.ngoLocationLatitude,
                                  singleBlogData?.ngoUserDetails?.ngoLocationLongitude
                                )
                                : '#'
                            }
                          >
                            <Stack direction={'row'}>
                              <LocationOnOutlinedIcon sx={{ color: theme.palette.secondary.main, fontWeight: '400' }} />
                              <Typography fontWeight={500} sx={{ color: theme.palette.secondary.main, fontWeight: '400' }}>
                                View in Map
                              </Typography>
                            </Stack>
                          </Link>
                        </Grid>
                      )}
                    </Grid>
                  </Grid>
                )}
              </Grid>
            )}

            {singleBlogData?.activity && (
              <Box>
                <Button
                  variant="contained"
                  sx={{
                    background: theme.palette.primary.light,
                    borderRadius: '16px',
                    color: theme.palette.secondary.dark,
                    marginTop: '2%',
                    '&:hover': {
                      background: theme.palette.primary.light,
                    },
                  }}
                  onClick={() => handleCreateActivityClick(singleBlogData.blogType.id)}
                >
                  Create Activity
                </Button>
              </Box>
            )}
          </Grid>

          <Grid paddingTop={'2%'}>
            <Typography
              sx={{
                fontSize: theme.typography.h5.fontSize,
                color: theme.palette.secondary.main,
                fontWeight: theme.typography.h5.fontWeight,
              }}
            >
              Published By
            </Typography>
            <Grid item container sx={{ paddingTop: '2%', paddingBottom: '2%' }} direction={'row'} gap={3}>
              <Link to={`/profile/${singleBlogData?.publisherId}`}>
                <Avatar
                  src={singleBlogData.publisherImage}
                  alt={getInitials(singleBlogData?.publisherName)}
                  style={{ width: '100px', height: '100px', borderRadius: '50px' }}
                >
                  {getInitials(singleBlogData?.publisherName)}
                </Avatar>
              </Link>
              <Stack sx={{ overflowWrap: 'break-word' }}>
                <Typography
                  sx={{
                    fontSize: theme.p1.semiBold.fontSize,
                    color: theme.palette.secondary.main,
                    fontWeight: theme.p1.semiBold.fontWeight,
                  }}
                >
                  {singleBlogData?.publisherName}
                </Typography>

                <Typography>{singleBlogData?.publisherBio}</Typography>
              </Stack>
            </Grid>

            <Grid py={'2%'} marginTop={'2%'}>
              <Typography
                sx={{
                  fontSize: theme.typography.h5.fontSize,
                  color: theme.palette.secondary.main,
                  fontWeight: theme.typography.h5.fontWeight,
                }}
              >
                Comments
              </Typography>

              {isAuthenticated && (
                <form onSubmit={handleAddMainComment} style={{ marginTop: '20px' }}>
                  <TextField
                    fullWidth
                    multiline
                    rows={4}
                    variant="outlined"
                    label="Add a comment"
                    value={mainComment}
                    onChange={e => setMainComment(e.target.value)}
                    inputProps={{ maxLength: 400 }}
                    sx={{ marginBottom: '20px' }}
                    InputProps={{
                      endAdornment: (
                        <div style={{ display: 'flex', flexDirection: 'row-reverse', alignItems: 'center' }}>
                          <Typography variant="caption" color="textSecondary">
                            {mainComment?.length}/400
                          </Typography>
                        </div>
                      ),
                      sx: {
                        '&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                          borderColor: '#333',
                          borderWidth: '0.08rem', // Change the active border color
                        },
                      },
                    }}
                  />
                  <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Button type="submit" color="primary" variant="contained" disabled={isPostButtonDisabled}>
                      Post
                    </Button>
                  </div>
                </form>
              )}

              {/* Mapping through the commentData array */}
              {singleBlogData?.commentcount !== 0 ? (
                singleBlogData?.comments?.map(comment => (
                  <React.Fragment key={comment.id}>
                    <Grid container direction="column" gap={1} paddingTop="2%">
                      <Grid item container direction="row" gap={3} alignItems="center">
                        <Avatar
                          alt={`${comment.profileImage}` ? '' : getInitials(comment.name)}
                          src={comment.profileImage}
                          sx={{ width: 30, height: 30, borderRadius: '50px', cursor: 'pointer', fontSize: '12px' }}
                          onError={handleImageError}
                          onClick={() => {
                            navigate(`/profile/${comment?.userId}`);
                          }}
                        >
                          {getInitials(comment.name)}
                        </Avatar>
                        <Stack flexGrow={1} sx={{ overflowWrap: 'break-word', display: 'flex', alignItems: 'left' }}>
                          <Typography
                            sx={{
                              fontSize: '12px',
                              color: theme.palette.secondary.main,
                              fontWeight: theme.p1.semiBold.fontWeight,
                              ml: 1, // Adjust the margin-left to move the comment lines to the right
                            }}
                          >
                            <b>{comment?.name}</b> &ensp; {timestampToHoursAgo(comment?.commentPostedOn).replace('Posted', '')}
                          </Typography>
                        </Stack>
                      </Grid>
                      {comment?.reportedCount < 3 || viewableComment?.includes(comment?.commentId) ? (
                        <Typography
                          marginLeft={'7%'}
                          sx={{
                            fontSize: '12px',
                            color: theme.palette.secondary.main,
                          }}
                        >
                          {comment?.comment}
                        </Typography>
                      ) : (
                        <Typography
                          marginLeft={'7%'}
                          sx={{
                            fontSize: '12px',
                            color: theme.textColor.information,
                            cursor: 'pointer',
                            fontWeight: 700,
                          }}
                          onClick={() => addToOffensiveList(comment?.commentId)}
                        >
                          <VisibilityOffIcon sx={{ fontSize: 'medium', marginRight: '5px' }} />
                          This comment contain sensitive Information.
                        </Typography>
                      )}
                    </Grid>
                    <Grid item container justifyContent="flex-start" marginLeft={'6%'}>
                      {' '}
                      {/* Align the reply button to the right */}
                      {isAuthenticated && ( // Check if the user is authenticated
                        <>
                          <IconButton
                            sx={{
                              fontSize: '12px',
                              paddingLeft: '4px',
                            }}
                            aria-label="reply"
                            color="secondary"
                            onClick={() => handleReply(comment)} // Pass the repliedPersonName to the handleReply function
                          >
                            <Reply />
                            {/* Display the word "Reply" below the icon */}
                            <Typography sx={{ fontSize: theme.typography.caption.fontSize }}>Reply</Typography>
                          </IconButton>
                          <span className=" d-flex ms-0 mt-3 mb-3 me-4" onClick={e => handleClick(e, comment)}>
                            <MoreHorizOutlinedIcon
                              aria-describedby={menuItemId}
                              sx={{ cursor: 'pointer', fontSize: '19px', color: '#676E7B' }}
                            />
                          </span>
                          {selectedComment?.id === comment?.id && (
                            <Popover
                              id={blogId}
                              open={Boolean(anchorEl) && selectedComment?.id === comment?.id}
                              anchorEl={anchorEl}
                              onClose={handlePopClose}
                              anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                              }}
                            >
                              <List sx={{ cursor: 'pointer', textAlign: 'left' }}>
                                {comment?.editable && (
                                  <ListItem onClick={() => handleEdit(comment)}>
                                    <ListItemText primary="Edit" />
                                  </ListItem>
                                )}
                                {comment?.deletable && (
                                  <ListItem onClick={() => handleDelete(selectedComment)}>
                                    <ListItemText primary="Delete" />
                                  </ListItem>
                                )}

                                {!comment?.editable && !comment?.reported ? (
                                  <ListItem onClick={() => handleReport(selectedComment)}>
                                    <ListItemText primary="Report" />
                                  </ListItem>
                                ) : !comment?.editable && comment?.reported ? (
                                  <ListItem>
                                    <ListItemText primary="Reported" />
                                  </ListItem>
                                ) : null}
                              </List>
                            </Popover>
                          )}
                          <BlogComment
                            commentData={comment}
                            handleReply={repliedPersonName}
                            open={open && comment?.id === selectedPerson}
                            handleClose={handleClose}
                            onClose={handleDialogClose}
                          />
                          <EditCommentDialog
                            open={editDialogOpen && selectedComment?.id === comment?.id}
                            onClose={handleCloseEditDialog}
                            comment={comment}
                            handleEdit={editedComment}
                          />
                          <DeleteCommentDialog
                            open={deleteDialogOpen && selectedComment?.id === comment?.id}
                            onClose={() => {
                              setDeleteDialogOpen(false);
                              fetchNewData();
                            }}
                            onDelete={handleDeleteConfirmation}
                            comment={selectedCommentForDelete}
                          />
                          <ReportCommentDialog
                            open={reportDialogOpen && selectedComment?.id === comment?.id}
                            onClose={() => {
                              setReportDialogOpen(false);
                              fetchNewData();
                            }}
                            onReport={handleReportConfirmation}
                            comment={selectedCommentForReport}
                          />
                        </>
                      )}
                    </Grid>

                    {/* Mapping through the commentReply array */}
                    <Collapse in={expandedComments.includes(comment.id)}>
                      {comment?.commentReply?.length > 0 &&
                        comment?.commentReply?.map(reply => (
                          <Grid container direction="column" gap={1} paddingLeft="30px" key={reply.id}>
                            <Grid item container direction="row" gap={3} alignItems="center">
                              <Avatar
                                alt="profile image"
                                src={reply?.profileImage}
                                sx={{ width: 30, height: 30, borderRadius: '50px' }}
                                onError={handleImageError}
                                onClick={() => navigate(`/profile/${reply?.userId}`)}
                              >
                                {reply?.name?.split(' ')[0][0]}
                              </Avatar>
                              <Stack flexGrow={1} sx={{ overflowWrap: 'break-word', display: 'flex', alignItems: 'left' }}>
                                <Typography
                                  sx={{
                                    fontSize: '12px',
                                    color: theme.palette.secondary.main,
                                    fontWeight: theme.p1.semiBold.fontWeight,
                                    ml: 1, // Adjust the margin-left to move the comment lines to the right
                                  }}
                                >
                                  <b>{reply?.name}</b> &ensp; {timestampToHoursAgo(reply?.commentPostedOn).replace('Posted', '')}
                                </Typography>
                              </Stack>
                            </Grid>
                            <Typography
                              marginLeft={'7%'}
                              sx={{
                                fontSize: '12px',
                                color: theme.palette.secondary.main,
                              }}
                            >
                              {reply?.comment}
                            </Typography>
                            {isAuthenticated && (
                              <Grid item container justifyContent="flex-end" marginLeft={'7%'} marginTop={'-2%'} sx={{ display: 'block' }}>
                                <span className=" d-flex ms-0 mt-3 mb-3 me-4" onClick={e => handleClick(e, reply)}>
                                  <MoreHorizOutlinedIcon
                                    aria-describedby={blogId}
                                    sx={{ cursor: 'pointer', fontSize: '19px', color: '#676E7B' }}
                                  />
                                </span>
                                {/* ... (other reply options) ... */}
                                <Popover
                                  id={blogId}
                                  open={Boolean(anchorEl) && selectedComment === reply}
                                  anchorEl={anchorEl}
                                  onClose={handlePopClose}
                                  anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'left',
                                  }}
                                >
                                  <List sx={{ cursor: 'pointer' }}>
                                    {reply?.editable && (
                                      <ListItem onClick={() => handleEdit(reply)}>
                                        <ListItemText primary="Edit" />
                                      </ListItem>
                                    )}
                                    {reply?.deletable && (
                                      <ListItem onClick={() => handleDelete(reply)}>
                                        <ListItemText primary="Delete" />
                                      </ListItem>
                                    )}
                                    {!reply?.reported && !reply?.editable && (
                                      <ListItem onClick={() => handleReport(reply)}>
                                        <ListItemText primary="Report" />
                                      </ListItem>
                                    )}
                                    {!reply?.editable && !reply?.deletable && reply?.reported && (
                                      <ListItem>
                                        <ListItemText primary="No Action" />
                                      </ListItem>
                                    )}
                                  </List>
                                </Popover>
                                <EditCommentDialog
                                  open={editDialogOpen && selectedComment?.id === reply?.id}
                                  onClose={handleCloseEditDialog}
                                  comment={reply}
                                  handleEdit={editedComment}
                                />
                                <DeleteCommentDialog
                                  open={deleteDialogOpen && selectedComment?.id === reply?.id}
                                  onClose={() => {
                                    setDeleteDialogOpen(false);
                                    fetchNewData();
                                  }}
                                  onDelete={handleDeleteConfirmation}
                                  comment={selectedCommentForDelete}
                                />
                                <ReportCommentDialog
                                  open={reportDialogOpen && selectedComment?.id === reply?.id}
                                  onClose={() => {
                                    setReportDialogOpen(false);
                                    fetchNewData();
                                  }}
                                  onReport={handleReportConfirmation}
                                  comment={selectedCommentForReport}
                                />
                              </Grid>
                            )}
                          </Grid>
                        ))}
                    </Collapse>
                    {/* Render "Load More" button */}
                    {comment?.commentReply?.length > 0 && isAuthenticated && (
                      <Button
                        className="mt-1"
                        onClick={() => toggleCommentVisibility(comment.id)}
                        sx={{
                          color: '#674BFF',
                          fontSize: 'small',
                          marginTop: '-5%',
                          marginLeft: '6%',
                        }}
                      >
                        {expandedComments.includes(comment.id) ? 'Collapse' : 'Load More...'}
                      </Button>
                    )}
                  </React.Fragment>
                ))
              ) : (
                <Typography marginTop={'2%'} paddingBottom={'5%'} textAlign={'center'}>
                  No comments available.
                </Typography>
              )}
            </Grid>
          </Grid>
        </Grid>
      ) : (
        <Grid item container direction="column" xs={10} md={8} lg={8}>
          <Typography variant="h4">No data available</Typography>
        </Grid>
      )}
      {!loading && (
        <Grid
          item
          container
          direction="column"
          className="d-none d-md-flex"
          xs={1}
          md={2}
          lg={2}
          justifyContent="center"
          alignItems="flex-start"
          paddingLeft={'2%'}
          paddingRight={'2%'}
          gap={2}
          sx={{ height: '100%', position: 'sticky', top: '40%' }}
        >
          <Grid>
            {theme.palette.mode === 'dark' ? (
              <BlueTwitterIcon
                sx={{ cursor: 'pointer' }}
                onClick={() =>
                  getShareToken(
                    'twitter',
                    singleBlogData?.blogTitle.slice(0, 10),
                    singleBlogData?.id,
                    1,
                    singleBlogData?.eventURL,
                    appConstants.SocialShareMessage
                  )
                }
              />
            ) : (
              <GrayTwitterIcon
                sx={{ cursor: 'pointer' }}
                onClick={() =>
                  getShareToken(
                    'twitter',
                    singleBlogData?.blogTitle.slice(0, 10),
                    singleBlogData?.id,
                    1,
                    singleBlogData?.eventURL,
                    appConstants.SocialShareMessage
                  )
                }
              />
            )}
          </Grid>
          <Grid>
            <FacebookIcon
              sx={{ color: '#1D9BF0', cursor: 'pointer' }}
              onClick={() =>
                getShareToken(
                  'facebook',
                  singleBlogData?.blogTitle.slice(0, 10),
                  singleBlogData?.id,
                  2,
                  singleBlogData?.eventURL,
                  appConstants.SocialShareMessage
                )
              }
            />
          </Grid>
          <Grid>
            <LinkedInIcon
              sx={{ color: '#1D9BF0', cursor: 'pointer' }}
              onClick={() =>
                getShareToken(
                  'linkedIn',
                  singleBlogData?.blogTitle.slice(0, 10),
                  singleBlogData?.id,
                  3,
                  singleBlogData?.eventURL,
                  appConstants.SocialShareMessage
                )
              }
            />
          </Grid>
          <Grid>
            <InsertLinkIcon sx={{ color: theme.palette.secondary.main, cursor: 'pointer' }} onClick={() => shareLink('CutPaste')} />
            {copySuccess && <p>Link copied</p>}
          </Grid>
        </Grid>
      )}

      <Box className="d-md-none d-block" sx={{ position: 'inherit' }}>
        <SpeedDial
          ariaLabel="SpeedDial controlled open example"
          sx={{ position: 'fixed', bottom: 50, right: 16 }}
          icon={<SpeedDialIcon />}
          onClose={handleCloser}
          onOpen={handleOpener}
          open={opener}
        >
          {actions?.map(action => (
            <SpeedDialAction key={action.name} icon={action.icon} tooltipTitle={action.name} onClick={() => shareLink(action.name)} />
          ))}
        </SpeedDial>
      </Box>
      <Dialog fullScreen={fullScreen} open={authModalOpen} onClose={handleAuthModalClose}>
        <Stack sx={{ backgroundColor: theme.palette.secondary.dark }}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
              cursor: 'pointer',
              paddingTop: '1%',
              paddingRight: '2%',
              paddingBottom: '2%',
            }}
          >
            <Close onClick={handleAuthModalClose} />
          </Box>
          <AuthenticationModal
            setCurrentComponent={setCurrentComponent}
            currentComponent={currentComponent}
            handleAuthModalClose={handleAuthModalClose}
          />
        </Stack>
      </Dialog>
      <PhoneVerification
        handleClose={handlePhoneVerificationClose}
        open={phoneVerificationModal}
        fullScreen={fullScreen}
        phoneInputCompleted={phoneInputCompleted}
        setPhoneInputCompleted={setPhoneInputCompleted}
        otpVerificationCompleted={otpVerificationCompleted}
        setotpVerificationCompleted={setotpVerificationCompleted}
      />
    </Grid>
  );
};

export default BlogSingle;
