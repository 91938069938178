import React, { useEffect, useState } from 'react';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';

import CaretDown from 'app/components/icons/side-bar-icons/caret-down';
import FeaturedIcon from 'app/components/icons/side-bar-icons/featured-icon';
import NearmeIcon from 'app/components/icons/side-bar-icons/nearme-icon';
import StarIconOutlined from 'app/components/icons/side-bar-icons/star-icon-outlined';
import MyActivitiesIcon from 'app/components/icons/side-bar-icons/my-activities-icon';
import { Link, useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import GlobeIcon from 'app/components/icons/side-bar-icons/globe-icon';
import StarIcon from '@mui/icons-material/Star';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import './side-navigation.scss';
import OthersIcon from 'app/components/icons/side-bar-icons/others-icon';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { changeSortType } from 'app/shared/reducers/activity-home';
import RemoteventsIcon from 'app/components/icons/side-bar-icons/remote-events-icons';
import QueryStatsIcon from '@mui/icons-material/QueryStats';
import {
  fetchAllCategoriesSubcategories,
  fetchAllCategoriesSubcategoriesUnauthorized,
  makeFavoriteCategory,
} from 'app/shared/reducers/categories-subcategories';
import { sideBaricons } from 'app/utils/data/side-bar-nav/side-nav-icon-list';
import { clearExistingActivities, updateFilterProperties, updatePage } from 'app/shared/reducers/activity-filter';
import toast from 'react-hot-toast';
import AuthorisationModal from 'app/components/common/authorization-modal/authorization-modal';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { fetchCountries } from 'app/shared/reducers/region-filter/country-filter';
import { fetchStatesByCountry } from 'app/shared/reducers/region-filter/state-filter';
import { fetchCities } from 'app/shared/reducers/region-filter/city-filter';
import { FormControl, InputLabel, MenuItem, Select, ButtonGroup, useMediaQuery, useTheme } from '@mui/material';
import OrganizationIcon from 'app/components/icons/side-bar-icons/organization-icon';
import { fetchUserOrganizations } from 'app/shared/reducers/organization';
import { toggleModalLogin } from 'app/shared/reducers/authentication';
import { setSelectedOrgId } from 'app/shared/reducers/settings/settings-organization';
import { truncateString } from 'app/utils/common-utils/common-utils';
import MySelfIcon from 'app/components/icons/side-bar-icons/myself-icon';
import SocialIcons from 'app/components/ui/social-icons/social-icons';

const SideNavigation = ({
  category,
  subCategory,
  onSubCategoryClick,
  onhandleAccordionClick,
  onButtonClicked,
  onMapClicked,
  toggleMapValue,
  setToggleMapValue,
  onFeatureClicked,
}) => {
  const theme = useTheme();
  const isDarkMode = theme.palette.mode === 'dark';
  const location = useLocation();
  const remoteEvents = useAppSelector(state => state.filters.RequestBody.filter.remoteEvents);
  const selectedCateogory = useAppSelector(state => state.filters.RequestBody.filter.categoryId);

  const featured = useAppSelector(state => state.filters.RequestBody.filter.featured);
  const nearMe = useAppSelector(state => state.filters.RequestBody.filter.nearMe);
  const petition = useAppSelector(state => state.filters.RequestBody.filter.petition);
  const userOwnedOrganizations = useAppSelector(state => state.organizationReducer.userOwnedOrganization);
  const [selectedSubcategory, setSelectedSubcategory] = useState(0);
  const mySelf = useAppSelector(state => state.filters.RequestBody.filter.mySelf);
  const organizationId = useAppSelector(state => state.filters.RequestBody.filter.organizationId);
  const fontStyle = { color: isDarkMode ? '#BFBFBF' : '#676E7B', fontSize: '14px', cursor: 'pointer' };
  const iconStyle = { height: 'unset', width: 'unset' };
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState('');
  const navigate = useNavigate();
  const [activeAccordion, setActiveAccordion] = React.useState<any>();
  const [categoryData, setcategoryData] = useState([]);
  const dispatch = useAppDispatch();
  const isAuthenticated = useAppSelector(state => state.authentication.isAuthenticated);
  const [authModalOpen, setAuthModalOpen] = React.useState(false);
  const [currentComponent, setCurrentComponent] = React.useState(' ');
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [myfilter, setMyFilter] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState('');
  const [selectedState, setSelectedState] = useState('');
  const [selectedCity, setSelectedCity] = useState('');

  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [showMyActivities, setMyActivities] = useState(false);
  const [showOrganizations, setShowOrganizations] = useState(false);

  const isBlogListPage = location.pathname === '/blog-list';
  const isUserProfilePage =
    /^\/profile\/\d+/.test(location.pathname) || location.pathname === '/profile' || location.pathname === '/profile/';
  const isOrganizationPage = /^\/organization\/\d+/.test(location.pathname);

  const isDashboard =
    location.pathname === '/stats' ||
    location.pathname === '/stats/' ||
    location.pathname === '/my-activty/dashboard' ||
    location.pathname === '/my-activty/dashboard/' ||
    /^\/stats\/\d+/.test(location.pathname);

  const { id } = useParams();

  const myProfileId = useAppSelector(state => state.authentication.account.id);
  const filterData = useAppSelector(state => state.filters.RequestBody.filter);
  const [searchParams1, setSearchParams] = useSearchParams();
  const status = searchParams1.get('status');

  const activeMenu = location.state?.activeMenu;
  useEffect(() => {
    if (activeMenu === 'nearme') {
      localStorage.setItem('clickedOption', 'Near me');
      nearMeClickFunctions();
    }
  }, [activeMenu]);

  const navigateToOrganization = (e, id) => {
    e.preventDefault();
    dispatch(setSelectedOrgId(id));
    localStorage.setItem('organizationId', id);
    navigate(`/organization/${id}`);
  };

  const fetchUserConnectedOrganizations = async userId => {
    try {
      await dispatch(fetchUserOrganizations(userId));
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (!isDashboard && status !== 'success' && selectedOption === 'World') {
    } else if (status === 'success') {
      dispatch(clearExistingActivities());
      setSelectedOption('feature');
      handleFeatureClick();
    }
  }, [isDashboard, status]);

  useEffect(() => {
    if (isOrganizationPage) setShowOrganizations(true);
  }, [isOrganizationPage]);

  useEffect(() => {
    let currentURL = window.location.href;
    const hasBlogList = currentURL.includes('/blog-list');
    const hasProfile = currentURL.includes('/profile');
    if (hasBlogList) {
      setActiveAccordion('');
      const updatedProperties = {
        categoryId: null,
        subCategoryId: null,
        petition: false,
      };
      dispatch(updateFilterProperties(updatedProperties));
    }
    if (hasProfile) {
      setActiveAccordion('');
      const updatedProperties = {
        categoryId: null,
        subCategoryId: null,
        petition: false,
      };
      dispatch(updateFilterProperties(updatedProperties));
    }
  }, []);

  useEffect(() => {
    if (isAuthenticated) {
      if (isUserProfilePage && id) {
        fetchUserConnectedOrganizations(id);
      } else if (myProfileId) {
        fetchUserConnectedOrganizations(myProfileId);
      }
    }
  }, [myProfileId, isUserProfilePage, isAuthenticated]);

  const fetchMenuItems = async () => {
    let response;
    try {
      if (isAuthenticated) {
        response = await dispatch(fetchAllCategoriesSubcategories());
      } else {
        response = await dispatch(fetchAllCategoriesSubcategoriesUnauthorized());
      }
      if (response.meta.requestStatus === 'fulfilled') {
        setcategoryData(response.payload);
      } else {
        // toast.error('Error fetching side menu items', { id: 'menu-item-error' });
      }
    } catch (error) {
      console.error('Error fetching side menu items', error);
    }
  };

  useEffect(() => {
    fetchMenuItems();

    if (!remoteEvents && !petition && !mySelf && !featured && !localStorage.getItem('clickedOption') && status !== 'success')
      setSelectedOption('World');
  }, []);

  useEffect(() => {
    if (selectedCountry) {
      const countryId = selectedCountry;
      fetchState(countryId);
      handleFilteringRegion();
    }
  }, [selectedCountry]);

  useEffect(() => {
    if (selectedState) {
      const stateId = selectedState;
      fetchCity(stateId);
      handleFilteringRegion();
    }
  }, [selectedState]);

  useEffect(() => {
    if (selectedCity) handleFilteringRegion();
  }, [selectedCity]);

  useEffect(() => {
    if (!isAuthenticated) {
      setMyActivities(false);
    }
  }, [isAuthenticated]);

  const fetchCountry = async () => {
    const response = await dispatch(fetchCountries());
    setCountries(response.payload);
  };

  const fetchState = async (param: any) => {
    const response = await dispatch(fetchStatesByCountry(param));
    setStates(response.payload);
  };

  const fetchCity = async (param: any) => {
    const payload = {
      countryCode: selectedCountry,
      stateCode: selectedState,
    };
    const response = await dispatch(fetchCities(payload));
    setCities(response.payload);
  };
  const [performPostNavigateActions, setPerformPostNavigateActions] = useState(false);
  const [postNavigateAccordionId, setPostNavigateAccordionId] = useState('');
  const [postNavigateCategoryId, setPostNavigateCategoryId] = useState(null);

  // useEffect(() => {
  //   const categoryName = JSON.parse(localStorage.getItem('categoryName'));
  //   if (categoryName) {
  //     setActiveAccordion(categoryName);
  //     // localStorage.removeItem('categoryName');
  //   }
  // }, [localStorage.getItem('categoryName')]);

  const handleAccordionClick = (accordionId: string, categoryId: any) => {
    console.log('activeAccordion', accordionId, categoryId, activeAccordion);
    if (location.pathname === '/') {
      console.log('activeAccordion inside', accordionId, categoryId, activeAccordion);
      if (JSON.parse(localStorage.getItem('categoryAccordianId')) === categoryId) {
        console.log('activitycards inside side1');
        localStorage.removeItem('categoryAccordianId');
        setActiveAccordion('');
        onhandleAccordionClick(categoryId, true);
        setSelectedSubcategory(0);
        const updatedProperties = {
          categoryId: null,
          subCategoryId: null,
          petition: false,
        };
        dispatch(clearExistingActivities());
        dispatch(updateFilterProperties(updatedProperties));
      } else {
        localStorage.setItem('categoryAccordianId', JSON.stringify(categoryId));
        setActiveAccordion(categoryId);
        onhandleAccordionClick(categoryId, false);
        setSelectedSubcategory(0);
      }
    } else if (
      location.pathname.startsWith('/activity/') ||
      location.pathname.startsWith('/recurringactivity/') ||
      location.pathname.startsWith('/petition/')
    ) {
      localStorage.setItem('categoryAccordianId', JSON.stringify(categoryId));

      navigate('/');
    } else {
      if (JSON.parse(localStorage.getItem('categoryAccordianId')) === categoryId) {
        console.log('thumbnail sharing 1');
        localStorage.removeItem('categoryAccordianId');
        setActiveAccordion('');
        onhandleAccordionClick(categoryId, true);
        // when closing accordion set selected subcategory as null
        setSelectedSubcategory(0);
        const updatedProperties = {
          categoryId: null,
          subCategoryId: null,
          petition: false,
        };
        dispatch(updateFilterProperties(updatedProperties));
      } else {
        localStorage.setItem('categoryAccordianId', JSON.stringify(categoryId));
        setActiveAccordion(accordionId);
        onhandleAccordionClick(categoryId, false);
        setSelectedSubcategory(0);
      }
    }
  };

  useEffect(() => {
    if (performPostNavigateActions && location.pathname === '/' && categoryData) {
      setActiveAccordion('Urgent');
      onhandleAccordionClick(postNavigateCategoryId, false);
      setSelectedSubcategory(0);

      setPerformPostNavigateActions(false);
    }
  }, [location.pathname, performPostNavigateActions, postNavigateAccordionId, postNavigateCategoryId, categoryData]);

  // eslint-disable-next-line @typescript-eslint/no-shadow
  const handleSubCategoryClick = (category, subCategory) => {
    if (selectedSubcategory === subCategory) {
      // If the same subcategory is clicked again, toggle it off
      setSelectedSubcategory(0);
    } else {
      setSelectedSubcategory(subCategory);
    }
    onSubCategoryClick(category, subCategory);
  };

  const handleStarIconClick = async (event: React.MouseEvent<HTMLDivElement>, categoryId) => {
    event.stopPropagation();
    try {
      const response = await dispatch(makeFavoriteCategory({ favoriteCategories: categoryId }));
      if (response.meta.requestStatus === 'fulfilled') {
        toast.success(response.payload?.message, {
          id: 'favorite-toast',
        });
        fetchMenuItems();
      } else {
        toast.error('Error! setting favorite category', {
          id: 'favorite-error-toast',
        });
      }
    } catch (error) {
      console.error('Error setting favorite category', error);
    }
  };

  /**
   * handle close and open of the world filter
   */
  const handleRegionFilter = () => {
    if (
      location.pathname.startsWith('/activity/') ||
      location.pathname.startsWith('/recurringactivity/') ||
      location.pathname.startsWith('/petition/')
    ) {
      navigate('/');
    } else {
      setIsOpen(false);

      if (selectedOption === 'World') {
      } else {
        setSelectedOption('World');
        setMyFilter(false);
      }
      setToggleMapValue(false);
      setActiveAccordion('');

      const updatedProperties = {
        nearMe: null,
        country: null,
        state: null,
        city: null,
        organizationId: null,
        remoteEvents: false,
        petition: false,
        mySelf: false,
        featured: false,
        categoryId: null,
        subCategoryId: null,
      };
      dispatch(updateFilterProperties(updatedProperties));
    }
  };

  const handleCountryChange = event => {
    setSelectedCountry(event.target.value);
    setSelectedState('');
    setSelectedCity('');
  };

  const handleStateChange = event => {
    setSelectedState(event.target.value);
    setSelectedCity('');
  };

  const handleCityChange = event => {
    setSelectedCity(event.target.value);
  };

  // eslint-disable-next-line @typescript-eslint/ban-types
  const handleAuthModalClose: (event: {}, reason: 'backdropClick' | 'escapeKeyDown') => void = () => {
    setAuthModalOpen(false);
  };

  const nearMeClickFunctions = () => {
    setIsOpen(false);
    setActiveAccordion('');
    if (selectedOption === 'Near me') {
      setSelectedOption(null);
    } else {
      setSelectedOption('Near me');
      setMyFilter(false);
    }
    onMapClicked();
  };

  function handleNearMeClick() {
    if (
      location.pathname.startsWith('/activity/') ||
      location.pathname.startsWith('/recurringactivity/') ||
      location.pathname.startsWith('/petition/')
    ) {
      localStorage.setItem('clickedOption', 'Near me');
      navigate('/');
      nearMeClickFunctions();
    } else {
      nearMeClickFunctions();
    }
  }
  useEffect(() => {
    if (localStorage.getItem('clickedOption') === 'Near me') {
      nearMeClickFunctions();
      localStorage.removeItem('clickedOption');
    }
  }, [localStorage.getItem('clickedOption')]);
  const [featureSelected, setFeatureSelected] = useState(false);
  const [postFeature, setPostFeature] = useState('');

  const featureClickFunctions = () => {
    setIsOpen(false);
    if (selectedOption === 'feature') {
      setSelectedOption(null);
    } else {
      setSelectedOption('feature');

      setMyFilter(false);
    }
    setActiveAccordion('');

    const updatedProperties = {
      nearMe: null,
      country: null,
      state: null,
      city: null,
      organizationId: null,
      remoteEvents: false,
      petition: false,
      mySelf: false,
      featured: !featured,
      categoryId: null,
      subCategoryId: null,
    };

    dispatch(updateFilterProperties(updatedProperties));
  };
  const handleFeatureClick = () => {
    if (location.pathname === '/') {
      featureClickFunctions();
    } else if (
      location.pathname.startsWith('/activity/') ||
      location.pathname.startsWith('/recurringactivity/') ||
      location.pathname.startsWith('/petition/')
    ) {
      navigate('/');
      featureClickFunctions();
    }
  };

  // useEffect(() => {
  //   if (localStorage.getItem('clickedOption') === 'feature') {
  //     console.log('Feature clicked');
  //     setSelectedOption('feature');
  //     const updatedProperties = {
  //       nearMe: null,
  //       country: null,
  //       state: null,
  //       city: null,
  //       organizationId: null,
  //       remoteEvents: false,
  //       petition: false,
  //       mySelf: false,
  //       featured: true,
  //     };
  //     dispatch(updateFilterProperties(updatedProperties));

  //     localStorage.removeItem('clickedOption');
  //   }
  // }, [localStorage.getItem('clickedOption')]);

  useEffect(() => {
    if (featureSelected && location.pathname === '/') {
      dispatch(updateFilterProperties({ ...filterData, remoteEvents: false }));
      setIsOpen(false);

      setSelectedOption('feature');

      setMyFilter(false);

      onFeatureClicked();
      setFeatureSelected(false);
    }
  }, [location.pathname, featureSelected]);

  function handleClickActivities() {
    if (isAuthenticated) {
      setMyActivities(prev => !prev);

      setToggleMapValue(false);
      onButtonClicked();
    } else {
      setCurrentComponent('login');
      dispatch(toggleModalLogin());

      setAuthModalOpen(true);
    }
  }

  const filterMyactivitiesFunctions = (type, id) => {
    if (selectedOption === 'My activities') {
      setSelectedOption(null);
    } else {
      setSelectedOption('My activities');
      setMyFilter(false);
    }

    setActiveAccordion('');

    let updatedProperties = {
      nearMe: null,
      country: null,
      state: null,
      city: null,
      organizationId: type === 'Org' && organizationId !== id ? id : null,
      remoteEvents: false,
      mySelf: organizationId !== id ? true : type === 'MySelf' && organizationId ? true : !mySelf,
      petition: null,
      featured: false,
      categoryId: null,
      subCategoryId: null,
    };

    dispatch(updateFilterProperties(updatedProperties));
  };

  function filterMyActivities(type, id) {
    if (
      location.pathname.startsWith('/activity/') ||
      location.pathname.startsWith('/recurringactivity/') ||
      location.pathname.startsWith('/petition/')
    ) {
      navigate('/');
      filterMyactivitiesFunctions(type, id);
    } else {
      filterMyactivitiesFunctions(type, id);
    }
  }

  //filter activities when clicking remote events
  const remoteActivitiesFunctions = () => {
    dispatch(updateFilterProperties({ ...filterData, featured: false }));
    if (selectedOption === 'Remote Events') {
      setSelectedOption(null);
    } else {
      setSelectedOption('Remote Events');
      setMyFilter(false);
    }
    setToggleMapValue(false);
    setActiveAccordion('');

    const updatedProperties = {
      nearMe: null,
      country: null,
      state: null,
      city: null,
      organizationId: null,
      remoteEvents: !remoteEvents,
      petition: false,
      mySelf: false,
      featured: false,
      categoryId: null,
      subCategoryId: null,
    };
    dispatch(updateFilterProperties(updatedProperties));
  };

  function handleRemoteActivities() {
    if (
      location.pathname.startsWith('/activity/') ||
      location.pathname.startsWith('/recurringactivity/') ||
      location.pathname.startsWith('/petition/')
    ) {
      navigate('/');
      remoteActivitiesFunctions();
    } else {
      remoteActivitiesFunctions();
    }
  }

  //filter activities accoring to category clicked
  const updateFilterCategory = (categoryId: any, category: any) => {
    console.log('click', categoryId, category);
    setSelectedOption('');
    setMyActivities(false);
    setActiveAccordion(category);
    const updatedProperties = {
      categoryId: categoryId,
      subCategoryId: null,
      petition: null,
      nearMe: null,
      remoteEvents: false,
      mySelf: false,
      organizationId: null,
      featured: false,
    };
    dispatch(updateFilterProperties(updatedProperties));
    handleAccordionClick(category, categoryId);
  };
  //filter activities accoring to Subcategory clicked
  const updateFilterSubcategory = (categoryId, subcategoryId) => {
    // console.log(selectedSubcategory, subcategoryId);
    if (selectedSubcategory === subcategoryId) {
      const updatedProperties = {
        categoryId: categoryId,
        subCategoryId: null,
        petition: null,
      };
      dispatch(updateFilterProperties(updatedProperties));
    } else {
      const updatedProperties = {
        categoryId: categoryId,
        subCategoryId: subcategoryId,
        petition: null,
      };
      dispatch(updateFilterProperties(updatedProperties));
    }
  };

  //filter petitions from activities
  function handleFilteringPetitions(categoryId) {
    setToggleMapValue(false);
    // setSelectedSubcategory(null);
    const updatedProperties = {
      nearMe: null,
      categoryId: categoryId,
      subCategoryId: null,
      country: null,
      state: null,
      city: null,
      organizationId: null,
      remoteEvents: false,
      petition: !petition,
      mySelf: false,
      featured: false,
    };
    dispatch(updateFilterProperties(updatedProperties));
  }

  //filter petitions from using country state and city
  function handleFilteringRegion() {
    const payload = {
      countryCode: selectedCountry ? selectedCountry['name'] : null,
      stateCode: selectedState ? selectedState['name'] : null,
      cityCode: selectedCity ? selectedCity['name'] : null,
    };
    setToggleMapValue(false);
    setSelectedSubcategory(0);
    const updatedProperties = {
      nearMe: null,
      categoryId: null,
      subCategoryId: null,
      country: payload?.countryCode,
      state: payload?.stateCode,
      city: payload?.cityCode,
      organizationId: null,
      remoteEvents: false,
      petition: false,
      mySelf: false,
    };
    dispatch(updateFilterProperties(updatedProperties));
  }

  return (
    <Stack
      direction={'column'}
      className="pl-3 "
      sx={{
        paddingTop: '10%',
        marginLeft: { xs: 0, md: '17%' },
        height: '100vh',
        overflowY: 'scroll',
        overflowX: 'hidden',
        background: isDarkMode
          ? '#1F1F26'
          : 'transparent linear- gradient(109deg, #D0CEC3 25%, #F2F2FA 0 %, #EFEFF5 80 %) 0% 0 % no - repeat padding- box',
        '&::-webkit-scrollbar': {
          display: 'none',
        },
        '&::-webkit-scrollbar-thumb': {
          background: 'transparent linear- gradient(109deg, #D0CEC3 25%, #F2F2FA 0 %, #EFEFF5 80 %) 0% 0 % no - repeat padding- box',
          height: '10px',
        },
        '&::-webkit-scrollbar-track': {
          background: 'transparent linear- gradient(109deg, #D0CEC3 25%, #F2F2FA 0 %, #EFEFF5 80 %) 0% 0 % no - repeat padding- box',
        },
      }}
    >
      <div className="defined pb-4">
        <Stack direction={'column'} gap={1.8} sx={{ mb: '36.5px', display: location.pathname.includes('/blog-list') && 'none' }}>
          <div className="displayBlog">
            <Stack px={2} direction="row" alignItems={'center'} justifyContent={'flex-start'}>
              <OthersIcon sx={{ width: '20px', height: '20px' }} />
              <Button sx={{ fontStyle }} onClick={() => navigate('/blog-list')}>
                Blog
              </Button>
            </Stack>
          </div>
          <div className="displayBlog">
            <Stack px={2} direction="row" alignItems={'center'} justifyContent={'flex-start'}>
              <QueryStatsIcon sx={{ color: '#676e7b' }} />
              <Button sx={{ fontStyle }} onClick={() => navigate('/stats')}>
                Stats
              </Button>
            </Stack>
          </div>
          {/* {!isUserProfilePage && !isOrganizationPage ? ( */}
          {!isUserProfilePage && !isOrganizationPage ? (
            <>
              {!isDashboard && (
                <>
                  <Stack
                    px={2}
                    direction="row"
                    gap={2}
                    alignItems={'center'}
                    justifyContent={'flex-start'}
                    sx={{
                      cursor:
                        location.pathname.includes('/blog-list') || location.pathname.includes('/profile') ? 'not-allowed' : 'pointer',
                      pointerEvents: location.pathname.includes('/blog-list') || location.pathname.includes('/profile') ? 'none' : 'auto',
                    }}
                    onClick={() => {
                      if (!(location.pathname.includes('/blog-list') || location.pathname.includes('/profile'))) {
                        // dispatch(changeSortType('Featured'));
                        // if (selectedOption === 'Featured') {
                        //   setSelectedOption(null);
                        // } else {
                        //   setSelectedOption('Featured');
                        //   setMyFilter(false);
                        // }
                        handleFeatureClick();
                      }
                    }}
                  >
                    <FeaturedIcon sx={iconStyle} />
                    <Typography
                      sx={{
                        fontSize: '14px',
                        cursor: 'pointer',
                        color: !nearMe ? ((selectedOption === 'feature' || featured) && !mySelf && !organizationId ? '#674BFF' : '') : '',
                      }}
                    >
                      Featured
                    </Typography>
                  </Stack>

                  <Stack
                    px={2}
                    direction="row"
                    gap={2}
                    alignItems={'center'}
                    justifyContent={'flex-start'}
                    sx={{
                      cursor:
                        location.pathname.includes('/blog-list') || location.pathname.includes('/profile') ? 'not-allowed' : 'pointer',
                      pointerEvents: location.pathname.includes('/blog-list') || location.pathname.includes('/profile') ? 'none' : 'auto',
                    }}
                    onClick={() => {
                      if (!(location.pathname.includes('/blog-list') || location.pathname.includes('/profile'))) {
                        dispatch(changeSortType('World'));
                        handleRegionFilter();
                      }
                    }}
                  >
                    <GlobeIcon sx={iconStyle} />
                    <Typography
                      sx={{ fontSize: '14px', cursor: 'pointer', color: selectedOption === 'World' && !featured ? '#674BFF' : '' }}
                    >
                      World
                    </Typography>
                  </Stack>

                  <Stack
                    direction="row"
                    gap={2}
                    alignItems={'center'}
                    justifyContent={'flex-start'}
                    px={2}
                    sx={{
                      cursor:
                        location.pathname.includes('/blog-list') || location.pathname.includes('/profile') ? 'not-allowed' : 'pointer',
                      pointerEvents: location.pathname.includes('/blog-list') || location.pathname.includes('/profile') ? 'none' : 'auto',
                    }}
                    onClick={() => {
                      if (!(location.pathname.includes('/blog-list') || location.pathname.includes('/profile'))) {
                        handleNearMeClick();
                      }
                    }}
                  >
                    <NearmeIcon sx={iconStyle} />
                    <Typography sx={{ fontSize: '14px', cursor: 'pointer', color: selectedOption === 'Near me' ? '#674BFF' : '' }}>
                      Near Me
                    </Typography>
                  </Stack>

                  <Stack
                    direction="row"
                    gap={2}
                    alignItems={'center'}
                    justifyContent={'flex-start'}
                    px={2}
                    sx={{
                      cursor:
                        location.pathname.includes('/blog-list') || location.pathname.includes('/profile') ? 'not-allowed' : 'pointer',
                      pointerEvents: location.pathname.includes('/blog-list') || location.pathname.includes('/profile') ? 'none' : 'auto',
                    }}
                    onClick={() => {
                      if (!(location.pathname.includes('/blog-list') || location.pathname.includes('/profile'))) {
                        handleRemoteActivities();
                      }
                    }}
                  >
                    <RemoteventsIcon sx={iconStyle} />
                    <Typography
                      sx={{
                        fontSize: '14px',
                        cursor: 'pointer',
                        color: selectedOption === 'Remote Events' || remoteEvents ? '#674BFF' : '',
                      }}
                    >
                      Remote Events
                    </Typography>
                  </Stack>

                  <Stack
                    direction="row"
                    gap={2}
                    alignItems={'center'}
                    justifyContent={'flex-start'}
                    px={2}
                    sx={{
                      cursor:
                        location.pathname.includes('/blog-list') || location.pathname.includes('/profile') ? 'not-allowed' : 'pointer',
                      pointerEvents: location.pathname.includes('/blog-list') || location.pathname.includes('/profile') ? 'none' : 'auto',
                    }}
                    onClick={() => {
                      if (!(location.pathname.includes('/blog-list') || location.pathname.includes('/profile'))) {
                        handleClickActivities();
                      }
                    }}
                  >
                    <MyActivitiesIcon sx={iconStyle} />
                    <Typography sx={{ fontSize: '14px', cursor: 'pointer' }}>
                      My Activities <ArrowDropDownIcon style={{ color: '#bfbfbf', fontSize: '32px' }} />
                    </Typography>
                  </Stack>
                </>
              )}

              {(showMyActivities || mySelf) && (
                <div className="activitiesMenu" style={{ position: 'relative', bottom: '10px', marginBottom: '0px' }}>
                  <Stack
                    onClick={() => filterMyActivities('MySelf', null)}
                    px={2}
                    py={0.8}
                    direction="row"
                    gap={1.6}
                    alignItems={'center'}
                    justifyContent={'flex-start'}
                  >
                    <MySelfIcon sx={iconStyle} />
                    <Link style={{ color: mySelf && !organizationId ? '#674BFF' : '' }} to="/">
                      <Typography sx={{ fontSize: '14px', cursor: 'pointer' }}>Myself</Typography>
                    </Link>
                  </Stack>
                  {userOwnedOrganizations?.length > 0 &&
                    userOwnedOrganizations.map(org => (
                      <Stack
                        onClick={() => filterMyActivities('Org', org?.id)}
                        px={2}
                        py={0.8}
                        direction="row"
                        gap={1.6}
                        alignItems={'center'}
                        justifyContent={'flex-start'}
                      >
                        <OrganizationIcon sx={iconStyle} />

                        <Typography
                          title={org.label}
                          sx={{
                            color: organizationId === org.id ? '#674bff' : isDarkMode ? '#f6f4f3' : '#000000de',
                            fontSize: '14px',
                            cursor: 'pointer',
                            wordBreak: 'break-word',
                            width: '90px',
                          }}
                        >
                          {truncateString(org.label, 25)}
                        </Typography>
                      </Stack>
                    ))}
                </div>
              )}
            </>
          ) : null}

          {(isUserProfilePage || isOrganizationPage) && userOwnedOrganizations?.filter(item => !item.deleted).length > 0 && (
            <>
              <Stack
                direction="row"
                gap={2}
                alignItems={'center'}
                justifyContent={'flex-start'}
                px={2}
                sx={{
                  cursor: location.pathname.includes('/blog-list') || location.pathname.includes('/activity/') ? 'not-allowed' : 'pointer',
                  pointerEvents: location.pathname.includes('/blog-list') || location.pathname.includes('/activity/') ? 'none' : 'auto',
                }}
                onClick={() => {
                  if (isAuthenticated) {
                    setShowOrganizations(prev => !prev);
                  } else {
                    setCurrentComponent('login');
                    setAuthModalOpen(true);
                  }
                }}
              >
                <OrganizationIcon sx={iconStyle} />
                <Typography sx={{ fontSize: '14px', cursor: 'pointer', color: selectedOption === 'Organizations' ? '#674BFF' : '' }}>
                  Organizations <ArrowDropDownIcon style={{ color: '#bfbfbf', fontSize: '32px' }} />
                </Typography>
              </Stack>
              {(showOrganizations || isUserProfilePage) && (
                <div className="activitiesMenu " style={{ position: 'relative', bottom: '10px', marginBottom: '0px' }}>
                  {userOwnedOrganizations?.length > 0 &&
                    userOwnedOrganizations
                      ?.filter(org => !org?.deleted)
                      .map(org => (
                        <Stack
                          key={org?.id}
                          px={2}
                          pl={3}
                          py={0.8}
                          direction="row"
                          gap={1.6}
                          alignItems={'center'}
                          justifyContent={'flex-start'}
                        >
                          <Link to={`/organization/${org.id}`} onClick={e => navigateToOrganization(e, org.id)}>
                            <Typography
                              title={org.label}
                              sx={{
                                fontSize: '14px',
                                cursor: 'pointer',
                                // color: isOrganizationPage && storedOrgId && storedOrgId === org.id ? '#7268e2' : '#000',
                                color: parseInt(id, 10) === org.id ? '#674bff' : isDarkMode ? '#f6f4f3' : '#000000de',
                              }}
                            >
                              {truncateString(org.label, 25)
                                .toLowerCase()
                                .replace(/\b\w/g, char => char.toUpperCase())}
                            </Typography>
                          </Link>
                        </Stack>
                      ))}
                </div>
              )}
            </>
          )}
        </Stack>

        {isDashboard ? (
          <Stack
            mb={8}
            mt={'-28px'}
            sx={{
              maxHeight: '100vh',
              overflowY: 'scroll',
              overflowX: 'hidden',
              border: 'none',
              gap: '18px',
              '&::-webkit-scrollbar': {
                display: 'none',
              },
              '&::-webkit-scrollbar-thumb': {
                background: 'transparent linear- gradient(109deg, #D0CEC3 25%, #F2F2FA 0 %, #EFEFF5 80 %) 0% 0 % no - repeat padding- box',
                height: '10px',
              },
              '&::-webkit-scrollbar-track': {
                background: 'transparent linear- gradient(109deg, #D0CEC3 25%, #F2F2FA 0 %, #EFEFF5 80 %) 0% 0 % no - repeat padding- box',
              },
            }}
          >
            <Stack
              onClick={() => {
                setSelectedOption('Dashboard');
                navigate('/my-activty/dashboard');
              }}
              direction="row"
              gap={2}
              alignItems={'center'}
              justifyContent={'flex-start'}
              px={2}
            >
              <MyActivitiesIcon sx={iconStyle} />
              <Typography
                sx={{
                  fontSize: '14px',
                  cursor: 'pointer',
                  color: location.pathname === '/my-activty/dashboard' || location.pathname === '/my-activty/dashboard/' ? '#674BFF' : '',
                }}
              >
                My activities
              </Typography>
            </Stack>
            {!isUserProfilePage && (
              <>
                {categoryData.map(cat => {
                  const matchingIcon = sideBaricons.find(iconData => iconData.id === cat.id);
                  if (matchingIcon)
                    return (
                      <Stack
                        key={cat.id} // Add a unique key here
                        direction="row"
                        gap={2}
                        alignItems={'center'}
                        justifyContent={'flex-start'}
                        px={2}
                        onClick={() => navigate(`/stats/${cat.id}`)}
                      >
                        {matchingIcon.icon}
                        <Typography
                          sx={{
                            fontSize: '14px',
                            cursor: 'pointer',
                            color: parseInt(id, 10) === cat.id ? '#674BFF' : '',
                          }}
                        >
                          {cat.category.toLowerCase().replace(/\b\w/g, char => char.toUpperCase())}
                        </Typography>
                      </Stack>
                    );
                })}
              </>
            )}

          </Stack>
        ) : (
          <Stack
            mb={8}
            sx={{
              maxHeight: '100vh',
              overflowY: 'scroll',
              overflowX: 'hidden',
              border: 'none',
              // maxWidth: 265,
              '&::-webkit-scrollbar': {
                display: 'none',
              },
              '&::-webkit-scrollbar-thumb': {
                background: 'transparent linear- gradient(109deg, #D0CEC3 25%, #F2F2FA 0 %, #EFEFF5 80 %) 0% 0 % no - repeat padding- box',
                height: '10px',
              },
              '&::-webkit-scrollbar-track': {
                background: 'transparent linear- gradient(109deg, #D0CEC3 25%, #F2F2FA 0 %, #EFEFF5 80 %) 0% 0 % no - repeat padding- box',
              },
            }}
            className={`MuiStack-root css-iazx4v-MuiStack-root ${isBlogListPage || isUserProfilePage || isOrganizationPage ? 'blog-list' : ''
              }`}
          >
            {!isUserProfilePage && !isOrganizationPage && (
              <>
                {categoryData?.map(data => {
                  const matchingIcon = sideBaricons.find(iconData => iconData.id === data.id);

                  if (matchingIcon) {
                    return (
                      <div key={data?.id}>
                        <Accordion
                          expanded={activeAccordion === data.category || selectedCateogory === data.id}
                          onChange={() => {
                            updateFilterCategory(data.id, data?.category);
                            // handleAccordionClick(data.category, data.id);
                          }}
                          sx={{
                            boxShadow: 'none',
                            border: 'none',
                            backgroundColor:
                              activeAccordion === data.category || selectedCateogory === data.id ? 'transparent' : 'transparent',
                          }}
                        >
                          <AccordionSummary
                            expandIcon={
                              location.pathname.includes('/profile') ||
                                location.pathname.includes('/blog-list') ||
                                location.pathname.includes('/activity/') ? null : (
                                <CaretDown sx={iconStyle} />
                              )
                            }
                            aria-controls={data.category}
                            id={data.id}
                            sx={{
                              px: 2,
                              height: '30px',
                              borderRadius: '30px',
                              transition: 'background-color 0s ease',
                              backgroundColor:
                                activeAccordion === data?.category || selectedCateogory === data.id
                                  ? 'secondary.dark'
                                  : 'background.default',
                            }}
                          >
                            <Stack direction="row" gap={2} alignItems={'center'} justifyContent={'space-between'}>
                              {matchingIcon.icon} {/* Render the matching icon here */}
                              {/* <Tooltip title={data.fullForm} arrow placement="top-start"> */}
                              <Typography
                                data-toggle="tooltip"
                                data-placement="top"
                                // title={data.fullForm}
                                sx={{ fontSize: '14px', cursor: 'pointer' }}
                                style={{ width: '114px' }}
                              >
                                {data.category.toLowerCase().replace(/\b\w/g, char => char.toUpperCase())}
                              </Typography>
                              {/* </Tooltip> */}
                              {!isBlogListPage &&
                                (isAuthenticated ? (
                                  <Box onClick={event => handleStarIconClick(event, data.id)}>
                                    {data.favorite ? (
                                      <StarIcon color="primary" sx={{ fontSize: '16px', marginBottom: '0.2rem' }} />
                                    ) : (
                                      <StarIconOutlined className="mt-0 mb-1" sx={iconStyle} />
                                    )}
                                  </Box>
                                ) : null)}
                            </Stack>
                          </AccordionSummary>
                          <AccordionDetails
                            sx={{
                              pl: 6.375,
                              backgroundColor: 'background.default',
                              display: (isBlogListPage || isUserProfilePage) && 'none',
                            }}
                          >
                            <Stack gap={3} direction="column">
                              {data.subcategories.map(subcategory => (
                                <Typography
                                  key={subcategory?.id}
                                  sx={{
                                    fontSize: '14px',
                                    cursor: 'pointer',
                                    color: subcategory.id === selectedSubcategory ? '#674BFF' : '',
                                  }}
                                  onClick={() => {
                                    if (subcategory.id === null) {
                                      setSelectedSubcategory(subcategory.id);
                                      handleFilteringPetitions(data.id);
                                    } else {
                                      updateFilterSubcategory(data.id, subcategory.id);
                                      handleSubCategoryClick(data.id, subcategory.id);
                                    }
                                  }}
                                >
                                  {subcategory.label.toLowerCase().replace(/\b\w/g, char => char.toUpperCase())}
                                </Typography>
                              ))}
                            </Stack>
                          </AccordionDetails>
                        </Accordion>
                      </div>
                    );
                  }

                  return null;
                })}
              </>
            )}
          </Stack>
        )}
      </div>

      <ButtonGroup
        variant="contained"
        className="mx-3"
        sx={{ width: 'fit-content', marginBottom: '10px' }}
        aria-label="outlined primary button group"
      >
        <Button
          className="b-color"
          sx={{
            textTransform: 'capitalize',
            backgroundColor: 'secondary.dark',
            transition: 'background-color 0s ease',
            color: 'secondary.light',
            borderColor: 'white',

            '&:hover': {
              backgroundColor: 'secondary.dark',
              color: 'secondary.light',
              textTransform: 'capitalize',
              boxShadow: 'none',
            },
          }}
          variant="contained"
          size="small"
          onClick={() => navigate('/about-us')}
        >
          {' '}
          About
        </Button>
        <Button
          className="b-color"
          sx={{
            textTransform: 'capitalize',
            backgroundColor: 'secondary.dark',
            borderColor: 'secondary.dark',
            transition: 'background-color 0s ease',
            color: 'secondary.light',
            '&:hover': {
              backgroundColor: 'secondary.dark',
              color: 'secondary.light',
              textTransform: 'capitalize',
              boxShadow: 'none',
            },
          }}
          variant="contained"
          size="small"
          onClick={() => navigate('/privacy-policy')}
        >
          Privacy
        </Button>
        <Button
          className="b-color"
          sx={{
            backgroundColor: 'secondary.dark',
            color: 'secondary.light',
            transition: 'background-color 0s ease',
            '&:hover': {
              backgroundColor: 'secondary.dark',
              color: 'secondary.light',
              boxShadow: 'none',
            },
          }}
          variant="contained"
          size="small"
          onClick={() => navigate('/how-it-works')}
        >
          How it works
        </Button>
      </ButtonGroup>
      <div className="mx-3">
        <SocialIcons />
      </div>

      <AuthorisationModal
        onClose={handleAuthModalClose}
        authModalOpen={authModalOpen}
        fullScreen={fullScreen}
        handleAuthModalClose={handleAuthModalClose}
        currentComponent={currentComponent}
        setCurrentComponent={setCurrentComponent}
      />
    </Stack>
  );
};

export default SideNavigation;
