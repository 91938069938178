import React from 'react';
import { createSvgIcon } from '@mui/material/utils';

const CreateIcon = createSvgIcon(
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
    <g id="Group_2387" data-name="Group 2387" transform="translate(-1255 -564)">
      <rect 
        id="Rectangle_816" 
        data-name="Rectangle 816" 
        width="24" 
        height="24" 
        rx="10" 
        transform="translate(1255 564)" 
        fill="#fff" 
      />
      <g 
        id="calendar-delete-4_24x24_1x" 
        data-name="calendar-delete-4(24x24)@1x" 
        transform="translate(1262.78 571.921)"
      >
        <path
          id="Path_96"
          data-name="Path 96"
          d="M9.172,10.172,17.15,18.15"
          transform="translate(-9.172 -10.172)"
          fill="none"
          stroke="#bfbfbf"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
        <path
          id="Path_97"
          data-name="Path 97"
          d="M17.15,10.172,9.172,18.15"
          transform="translate(-9.172 -10.172)"
          fill="none"
          stroke="#bfbfbf"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
      </g>
    </g>
  </svg>,
  'CreateIcon'
);

export default CreateIcon;