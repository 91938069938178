import React, { useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { getSettingsAccount, sendSettingsAccount } from 'app/shared/reducers/settings/settings';
import SettingsChangeProfilePic from './settings-change-profile-pic';
import SettingsChangeEmail from './settings-change-email';
import SettingsChangeLocation from './settings-change-location';
import SettingsSocialAccounts from './settings-social-accounts';
import SettingsChangePhone from './settings-change-phone';
import { Button, Divider, useMediaQuery, useTheme } from '@mui/material';
import { toast } from 'react-toastify';
import UpdateButton from '../../../../../common/buttons/update-button';
import _ from 'lodash';
import DeactivateModal from 'app/components/common/modals/deactivate-modal';
import { getAccount } from 'app/shared/reducers/authentication';

const SettingsAccount = () => {
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const id = useAppSelector(state => state.authentication.account.id);
  const userSettings = useAppSelector(state => state.settingsReducer.userSettings);
  const settingsSlice = useAppSelector(state => state.settingsReducer);
  const [locationManualTyped, setLocationManualyTyped] = useState(false);
  const isDarkMode = theme.palette.mode === 'dark';
  const [updatedSettings, setUpdatedSettings] = useState({});

  useEffect(() => {
    if (id) {
      dispatch(getSettingsAccount(id));
    }
  }, [id]);

  const handleSubmitSetttings = () => {
    dispatch(sendSettingsAccount({ settings: updatedSettings, id })).then(() => {
      dispatch(getSettingsAccount(id));
    });
    dispatch(getAccount());
  };

  useEffect(() => {
    if (userSettings !== null) {
      // const settingsWithoutProfilePic = Object.assign({}, userSettings);
      // delete settingsWithoutProfilePic.profilePic;
      setUpdatedSettings(userSettings);
    }
  }, [userSettings]);

  const isEqual = _.isEqual(updatedSettings, userSettings);

  console.log(updatedSettings, userSettings, isEqual, 'isEqual');
  const [openModal, setOpenModal] = useState(false);
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  useEffect(() => {
    if (settingsSlice?.sendSettingsSuccess) {
      toast(settingsSlice?.sendSettingsSuccess || 'Account details updated successfully');
    } else if (settingsSlice?.sendSettingsError) {
      toast('Something went wrong');
    }
  }, [settingsSlice?.sendSettingsSuccess]);

  const handleDeleteAccount = () => {
    setOpenModal(true);
  };

  const handleModalClose = () => {
    setOpenModal(false);
  };
  return (
    <Grid container direction="column" gap={1.7}>
      <Grid container>
        <Grid item xs={12} md={6}>
          <Grid container direction="column" spacing={3}>
            <Grid item height={'75px'}>
              <SettingsChangeProfilePic
                handleSubmitSetttings={handleSubmitSetttings}
                setUpdatedSettings={setUpdatedSettings}
              />
            </Grid>

            <Grid item>
              <Divider
                sx={{
                  borderColor: theme.palette.mode === 'dark' ? '#F6F7F8' : '#000000'
                }}
              />
            </Grid>

            <Grid item>
              <SettingsChangeEmail
                setUpdatedSettings={setUpdatedSettings}
              />
            </Grid>

            <Grid item>
              <SettingsChangePhone
                setUpdatedSettings={setUpdatedSettings}
              />
            </Grid>

            <Grid item>
              <SettingsChangeLocation
                setLocationManualyTyped={setLocationManualyTyped}
                setUpdatedSettings={setUpdatedSettings}
              />
            </Grid>

            <Grid item>
              <SettingsSocialAccounts
                setUpdatedSettings={setUpdatedSettings}
              />
            </Grid>

            <Grid item>
              <Button
                variant="text"
                color="secondary"
                onClick={handleDeleteAccount}
                className="me-2"
                sx={{
                  borderRadius: 1.875,
                  py: 0.5,
                  px: 1,
                  backgroundColor: isDarkMode ? '#FFCE00' : '#674BFF',
                  color: isDarkMode ? 'black' : 'white',
                  '&:hover': {
                    backgroundColor: isDarkMode ? '#FFCE00' : '#674BFF',
                    color: isDarkMode ? 'black' : 'white',
                  },
                }}
              >
                Delete my account
              </Button>
            </Grid>

            <Grid item sx={{ textAlign: 'end' }}>
              <UpdateButton
                isEqual={isEqual}
                locationManualTyped={locationManualTyped}
                handleSubmit={handleSubmitSetttings}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <DeactivateModal handleClose={handleModalClose} fullScreen={fullScreen} open={openModal} />
    </Grid>
  );
};

export default SettingsAccount;
