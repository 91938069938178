import { initializeApp } from 'firebase/app';
import { getToken, getMessaging, onMessage } from 'firebase/messaging';

// Firebase Configuration
const firebaseConfig = {
  apiKey: 'AIzaSyBKA3beA6m0EVHP9xskAcfCpCGWDGRZt6E',
  authDomain: 'reachamy-269ac.firebaseapp.com',
  projectId: 'reachamy-269ac',
  storageBucket: 'reachamy-269ac.appspot.com',
  messagingSenderId: '27596888111',
  appId: '1:27596888111:web:75c2f2e7fde07ed82d8eda',
  measurementId: 'G-0CLTTBY4XJ',
};


const firebaseApp = initializeApp(firebaseConfig);
const messaging = getMessaging(firebaseApp);


const isInAppBrowser = () => {
  const userAgent = navigator.userAgent || navigator.vendor || window.opera;
  return (
    /FBAN|FBAV|Instagram|Twitter/.test(userAgent) ||
    (navigator.userAgent.includes('wv') && !navigator.userAgent.includes('Chrome'))
  );
};


const getFirebaseToken = async () => {
  try {
    if (isInAppBrowser()) {
      console.warn('In-app browsers do not support Firebase Messaging.');
      return null;
    }

    if (!('serviceWorker' in navigator)) {
      console.warn('Service Workers are not supported in this browser.');
      return null;
    }

    const serviceWorkerRegistration = await navigator.serviceWorker.register(
      '/content/firebase-messaging-sw.js',
      { scope: '/content/firebase-messaging-sw.js' }
    );

    const token = await getToken(messaging, {
      vapidKey: 'BJwwbJafS9ilwlJILin3v_ATFOqd4cIR-eoX5fmloZYjrksoLeZOJxEJsAQ1r00TKVxUxI452d3M7Ym931cyRrU',
      serviceWorkerRegistration,
    });

    if (token) {
      console.log('Firebase token retrieved:', token);
      return token;
    } else {
      console.warn('No Firebase token available.');
      return null;
    }
  } catch (error) {
    console.error('Error getting Firebase token:', error);
    return null;
  }
};


const onForegroundMessage = () =>
  new Promise((resolve, reject) => {
    try {
      onMessage(messaging, payload => {
        console.log('Foreground message received:', payload);
        localStorage.setItem('ForegroundChat', JSON.stringify(payload));
        resolve(payload);
      });
    } catch (error) {
      console.error('Error handling foreground message:', error);
      reject(error);
    }
  });

export { getFirebaseToken, onForegroundMessage };
