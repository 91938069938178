import React, { useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import SettingsProfileFirstName from './settings-profile-first-name/settings-profile-first-name';
import SettingsProfileLastName from './settings-profile-last-name/settings-profile-last-name';
import SettingsProfileBio from './settings-profile-bio/settings-profile-bio';
import SettingsProfileAvailability from './settings-profile-availability/settings-profile-availability';
import SettingsProfileEvents from './settings-profile-events/settings-profile-events';
import SettingsProfilePreferredTime from './settings-profile-preferred-time/settings-profile-preferred-time';
import SettingsProfileBloodGroup from './settings-profile-blood-group/settings-profile-blood-group';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { getSettingsProfile, sendSettingsProfile } from 'app/shared/reducers/settings/settings-profile';
import UpdateButton from 'app/components/common/buttons/update-button';
import { SettingsProfileType } from 'app/utils/types/settings/settings-profile-types';
import SettingsProfileIntrests from './settings-profile-intrests/settings-profile-intrests';
import _ from 'lodash';

const SettingsProfile = () => {
  const dispatch = useAppDispatch();

  const id = useAppSelector(state => state.authentication.account.id);
  const profileSettings = useAppSelector(state => state.settingsProfile);
  const [updatedProfile, setUpdatedProfile] = useState<SettingsProfileType>(null);
  const [isEqual, setIsEqual] = useState(_.isEqual(updatedProfile, profileSettings.settingsProfile));

  useEffect(() => {
    setIsEqual(_.isEqual(updatedProfile, profileSettings.settingsProfile));
  }, [updatedProfile, profileSettings.settingsProfile]);

  useEffect(() => {
    if (id) {
      dispatch(getSettingsProfile(id));
    }
  }, [id, profileSettings?.sendSettingsSuccess]);

  useEffect(() => {
    if (profileSettings?.settingsProfile !== null) {
      setUpdatedProfile(profileSettings?.settingsProfile);
    }
  }, [profileSettings?.settingsProfile]);

  const handleSubmit = () => {
    if (updatedProfile && updatedProfile?.firstName !== '' && updatedProfile?.lastName !== '') {
      const filteredWeekDays = updatedProfile?.weekDays?.filter(day => day !== null);

      dispatch(
        sendSettingsProfile({
          settings: {
            ...updatedProfile,
            weekDays: filteredWeekDays,
          },
          id,
        })
      );
    }
  };

  return (
    <Grid container direction="column" gap={3}>
      <Grid container>
        <Grid item xs={12} md={6}>
          <Grid container direction="column" spacing={3}>
            <Grid item>
              <SettingsProfileFirstName setUpdatedProfile={setUpdatedProfile} />
            </Grid>
            <Grid item>
              <SettingsProfileLastName setUpdatedProfile={setUpdatedProfile} />
            </Grid>
            <Grid item>
              <SettingsProfileBio setUpdatedProfile={setUpdatedProfile} />
            </Grid>
            <Grid item>
              <SettingsProfileEvents setUpdatedProfile={setUpdatedProfile} />
            </Grid>
            <Grid item>
              <SettingsProfileAvailability setUpdatedProfile={setUpdatedProfile} />
            </Grid>
            <Grid item>
              <SettingsProfilePreferredTime setUpdatedProfile={setUpdatedProfile} />
            </Grid>
            <Grid item>
              <SettingsProfileIntrests setUpdatedProfile={setUpdatedProfile} />
            </Grid>
            <Grid item>
              <SettingsProfileBloodGroup setUpdatedProfile={setUpdatedProfile} />
            </Grid>
            <Grid item>
              <UpdateButton handleSubmit={handleSubmit} isEqual={isEqual} />
            </Grid>
          </Grid>
        </Grid>
      </Grid>

    </Grid>
  );
};

export default SettingsProfile;
