import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

function updateCanonicalTag(url: string) {
  let canonicalTag = document.querySelector("link[rel='canonical']") as HTMLLinkElement | null; 
  if (canonicalTag) {
    canonicalTag.href = url; 
  } else {
    canonicalTag = document.createElement('link');
    canonicalTag.rel = 'canonical';
    canonicalTag.href = url;
    document.head.appendChild(canonicalTag);
  }
  console.log('Canonical tag updated:', canonicalTag.href);
}

const CanonicalUpdater = () => {
  const location = useLocation();

  useEffect(() => {
    const currentUrl = `${window.location.protocol}//${window.location.host}${location.pathname}`;
    updateCanonicalTag(currentUrl);
  }, [location]);

  return null; 
};

export default CanonicalUpdater;
